/**
 * Oppdaterer et object i swr sin cashe
 * @param newValue - deler av et objekt som finnes i cashe
 * @param state - listen som er i cashe nå
 * @param comperator - Hvordan skal vi finne det riktige elementet å oppdatere?
 */
export const updateObjectInArray = <T>(
  newValue: Partial<T>,
  state: Array<T>,
  comperator: (item: T) => boolean,
): T[] => {
  return state.map((element) =>
    comperator(element) ? { ...element, ...newValue } : element,
  );
};

/**
 * Legg til objekt til en liste i swr cashe
 * @param newValue - objektet som skal legges til
 * @param state - listen som er i cashe nå
 */
export const addToArray = <T>(newValue: T, state: T[]): T[] => {
  return [...state, newValue];
};
/**
 * Fjern et element fra listen som ligger i minne
 * @param state - listen som er i cashe nå
 * @param comperator - Hvordan skal vi finne det riktige elementet å oppdatere?
 */
export const deleteInArray = <T>(
  state: Array<T>,
  comperator: (item: T) => boolean,
): T[] => {
  return state.filter((element) => !comperator(element));
};
/**
 * Oppdater et objekt som ligger i swr sin cashe
 * @param newValue - deler av et objekt som finnes i cashe
 * @param state - objektet som er i cashe nå
 */
export const updateObject = <T>(newValue: Partial<T>, state: T): T => {
  return { ...state, ...newValue };
};
/**
 * Returnrerer unike elementer basert på predicate
 * @param comparer - en funksjon for å sammenligne to elementer i array'et for å se om de er like.
 */
export const unique: <T>(
  array: T[],
  comparer: (currentElement: T, otherElement: T) => boolean,
) => T[] = (array, comparer) => {
  return array.filter(
    (current, i, self) =>
      self.findIndex((other) => comparer(current, other)) === i,
  );
};
