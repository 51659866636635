import React from "react";
import styled from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";
import { formatDate } from "SharedComponents/utils/date";
import { Icon } from "@utdanningsdirektoratet/icon";
import { getUrls } from "globalFunctions";
import { Box } from "components/Box";
import { getText } from "SharedComponents/tekster/Overskrifts/Overskrifts";
import { Heading2 } from "SharedComponents/components/Headings/Headings";
import { accessLevelLowerThan } from "utils/tilgang";
import { resetHoursForDate } from "utils/dateUtils";
import {
  AccessToTilsynType,
  PubliseringInfoResponse,
  PubliseringMalType,
  PubliseringStatusoppdatering,
  PublisertTilsynResponse,
} from "../../../ApiClients";

const Item = styled.div`
  border-left: 4px solid ${colors.green7};
  padding: 0.25rem 1rem;
  & + & {
    margin-top: 2rem;
  }
`;

const Dato = styled.div`
  font-size: 0.85rem;
`;

const Tittel = styled.div`
  margin-top: 0.25rem;
  font-size: 1.25rem;
`;

const Link = styled.a`
  color: rgb(0, 70, 142);
`;

const LinkIcon = styled(Icon)`
  display: inline-block;
  margin-right: 0.5rem;
`;

const StyledBox = styled(Box)`
  padding: 2rem 1.25rem;
`;

const Dokument: React.FC<{
  publisering: PubliseringInfoResponse;
  children: React.ReactNode;
}> = ({ publisering, children }) => (
  <div className="u--marginTop0-25">
    <Link
      href={`${getUrls().downloadRapport}/${publisering.externalId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <LinkIcon icon="text document" color="darkblue" />
      {children}
    </Link>
  </div>
);

const getStatusoppdateringTittel = (
  statusoppdatering: PubliseringStatusoppdatering,
  malform?: string,
) => {
  switch (statusoppdatering) {
    case PubliseringStatusoppdatering.KlageMottatt:
      return getText(malform, (t) => t.statusoppdateringKlageMottatt);
    case PubliseringStatusoppdatering.KlageOversendtKd:
      return getText(malform, (t) => t.statusoppdateringKlageOversendtKd);
    case PubliseringStatusoppdatering.VedtakOpphevetKd:
      return getText(malform, (t) => t.statusoppdateringVedtakOpphevetKd);
    case PubliseringStatusoppdatering.VedtakOmgjortUdir:
      return getText(malform, (t) => t.statusoppdateringVedtakOmgjortUdir);
    case PubliseringStatusoppdatering.VedtakStadfestetKd:
      return getText(malform, (t) => t.statusoppdateringVedtakStadfestetKd);
    case PubliseringStatusoppdatering.VedtakOmgjortKd:
      return getText(malform, (t) => t.statusoppdateringVedtakOmgjortKd);
    case PubliseringStatusoppdatering.Avslutningsbrev:
      return getText(malform, (t) => t.statusoppdateringAvslutningsbrev);
    case PubliseringStatusoppdatering.VedtakDelvisStadfestetKd:
      return getText(
        malform,
        (t) => t.statusoppdateringVedtakDelvisStadfestetKd,
      );
    default:
      return null;
  }
};

const getTittel = (publisering: PubliseringInfoResponse, malform?: string) => {
  switch (publisering.publiseringMalType) {
    case PubliseringMalType.InnsendingAvDokumentasjon:
      return getText(malform, (t) => t.innsendingAvDokumentasjon);
    case PubliseringMalType.ForelopigTilsynsrapport:
      return getText(malform, (t) => t.tittelForelopigRapport);
    case PubliseringMalType.Tilsynsrapport:
      return getText(malform, (t) => t.tittelEndeligRapport);
    case PubliseringMalType.Statusoppdatering:
      return getStatusoppdateringTittel(
        publisering.statusoppdatering!,
        malform,
      );
    default:
      return null;
  }
};

const visDokument = (
  publisering: PubliseringInfoResponse,
  tilsyn: PublisertTilsynResponse,
) => {
  const publiseringAapentTilgjengeligDato = resetHoursForDate(
    tilsyn.aapentTilgjengeligFraDatoUtc,
  );

  if (
    accessLevelLowerThan(
      tilsyn.tilgangType,
      AccessToTilsynType.ReadUnObfuscated,
    ) &&
    (new Date() < publiseringAapentTilgjengeligDato ||
      tilsyn.publiseringVedtakIsEndret)
  ) {
    return false;
  }
  return publisering.externalId === tilsyn.publiseringId;
};

type TidslinjeItem = {
  displayDate: Date;
  hendelse: React.ReactNode;
};

const getSortedTidslinjeItems = (
  tilsyn: PublisertTilsynResponse,
): TidslinjeItem[] => {
  const items: TidslinjeItem[] = [];

  // Add Tilsyn startet event
  if (tilsyn.tilsynStartetDatoUtc) {
    items.push({
      displayDate: tilsyn.tilsynStartetDatoUtc,
      hendelse: (
        <Item key="tidslinje-event-startet">
          <Dato>{formatDate(tilsyn.tilsynStartetDatoUtc)}</Dato>
          <Tittel>{getText(tilsyn.malform, (t) => t.tilsynApnet)}</Tittel>
        </Item>
      ),
    });
  }

  // Add Tilsyn avsluttet event
  if (tilsyn.tilsynAvsluttetDatoUtc) {
    items.push({
      displayDate: tilsyn.tilsynAvsluttetDatoUtc,
      hendelse: (
        <Item key="tidslinje-event-avsluttet">
          <Dato>{formatDate(tilsyn.tilsynAvsluttetDatoUtc)}</Dato>
          <Tittel>{getText(tilsyn.malform, (t) => t.tilsynAvsluttet)}</Tittel>
        </Item>
      ),
    });
  }

  // Add Statusoppdateringer
  tilsyn.publiseringer
    .filter(
      (p) => p.publiseringMalType === PubliseringMalType.Statusoppdatering,
    )
    .map((p) =>
      items.push({
        displayDate: p.statusoppdateringDatoUtc!,
        hendelse: (
          <Item key={p.externalId}>
            <Dato>{formatDate(p.statusoppdateringDatoUtc!)}</Dato>{" "}
            <Tittel>{getTittel(p, tilsyn.malform)}</Tittel>
          </Item>
        ),
      }),
    );

  // Add other publiseringer
  tilsyn.publiseringer
    .filter(
      (p) => p.publiseringMalType !== PubliseringMalType.Statusoppdatering,
    )
    .map((p) =>
      items.push({
        displayDate: p.publisertDatoUtc,
        hendelse: (
          <Item key={p.externalId}>
            <Dato>{formatDate(p.publisertDatoUtc)}</Dato>
            <Tittel>{getTittel(p, tilsyn.malform)}</Tittel>
            {p.publiseringMalType ===
              PubliseringMalType.ForelopigTilsynsrapport &&
              !accessLevelLowerThan(
                tilsyn.tilgangType,
                AccessToTilsynType.ReadUnObfuscated,
              ) &&
              visDokument(p, tilsyn) && (
                <Dokument publisering={p}>
                  {getText(tilsyn.malform, (t) => t.lastNedForelopigRapport)}
                </Dokument>
              )}
            {p.publiseringMalType === PubliseringMalType.Tilsynsrapport &&
              visDokument(p, tilsyn) && (
                <Dokument publisering={p}>
                  {getText(tilsyn.malform, (t) => t.lastNedEndeligRapport)}
                </Dokument>
              )}
          </Item>
        ),
      }),
    );

  return items.sort(
    (a, b) =>
      new Date(a.displayDate).getTime() - new Date(b.displayDate).getTime(),
  );
};

export type TilsynTidslinjeProps = {
  tilsyn: PublisertTilsynResponse;
};

export const TilsynTidslinje: React.FC<TilsynTidslinjeProps> = ({ tilsyn }) => {
  const tidslinje = getSortedTidslinjeItems(tilsyn);
  return (
    <StyledBox $background="green5">
      <Heading2>Tidslinje</Heading2>
      {tidslinje.map((i) => i.hendelse)}
    </StyledBox>
  );
};
