import React from "react";
import styled from "styled-components";
import { LovEnumIdentifier, SnapshotResponse } from "../../../Types/Snapshot";
import { Barnehageloven } from "./Barnehageloven";
import { Heading4 } from "../../../../components/Headings/Headings";
import ReaksjonPalegg from "../../Common/ReaksjonPalegg/ReaksjonPalegg";
import { getText } from "../../../Overskrifts/Overskrifts";
import { Privatskoleloven } from "./Privatskoleloven";
import { Folkehoyskoleloven } from "../../ForelopigRapport/HoldeTilbakeTilskudd/Folkehoyskoleloven";

const Tittel = styled(Heading4)`
  font-family: Montserrat;
  margin-bottom: 1rem;
`;

type IProps = {
  snapshot: SnapshotResponse;
};

export const EndeligRapportRetteForholdene: React.FC<IProps> = ({
  snapshot,
}) => {
  const heading = getText(
    snapshot.omTilsynet?.malform ?? "Bokmål",
    (t) => t.reaksjonRetteForholdeneVedtak,
  );
  if (!snapshot.reaksjoner?.paleggOmRetting?.isValgt) {
    return null;
  }
  return (
    <>
      {snapshot.omTilsynet?.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven && (
        <Barnehageloven snapshot={snapshot} heading={heading} />
      )}
      {snapshot.omTilsynet?.lovEnumIdentifier ===
        LovEnumIdentifier.Folkehoyskoleloven && (
        <Folkehoyskoleloven snapshot={snapshot} heading={heading} />
      )}
      {snapshot.omTilsynet?.lovEnumIdentifier ===
        LovEnumIdentifier.Privatskoleloven && (
        <Privatskoleloven snapshot={snapshot} heading={heading} />
      )}
      {snapshot.reaksjoner?.paleggOmRetting?.tilsynstemas.map((tilsynstema) => (
        <div key={tilsynstema.tittel}>
          <Tittel>{tilsynstema.tittel}</Tittel>
          {tilsynstema.paleggs.map((p, pi) => (
            <ReaksjonPalegg
              key={`reaksjonPalegg-${pi}`}
              palegg={p}
              malform={snapshot.omTilsynet?.malform}
            />
          ))}
        </div>
      ))}
    </>
  );
};
