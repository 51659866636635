import { GetInnsendingClient, InnsendingResponse } from "ApiClients";
import { IResponseWithReset } from "@utdanningsdirektoratet/hooks";
import useSwrWithErrorHandling from "hooks/useSwrWithErrorHandling";

const getInnsendingClient = new GetInnsendingClient();

export const innsendingsRefreshKey = (id?: string): string =>
  `publiseringInnsendelse/${id}`;
export const useGetInnsendings = (
  id?: string,
): IResponseWithReset<InnsendingResponse[]> => {
  return useSwrWithErrorHandling(id ? innsendingsRefreshKey(id) : null, () =>
    getInnsendingClient.get(id ?? ""),
  );
};
