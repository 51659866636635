export const tilsynFilterKeys = {
  barnehage: "barnehage-tilsyn-filter",
  skole: "skole-tilsyn-filter",
};

export const tilsynstemaKeys = {
  barnehage: "barnehage-tilsynstema",
  skole: "skole-tilsynstema",
};

export const scrollKeys = {
  barnehage: "barnehage-scroll",
  skole: "skole-scroll",
  minOversikt: "min-oversikt-scroll",
};
