import { colors } from "@utdanningsdirektoratet/styles";
import styled from "styled-components";

export const Box = styled.div<{ $background: keyof typeof colors }>`
  padding: 2rem;
  border-radius: var(--borderradius1);
  background-color: var(--${(p) => p.$background});
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;
