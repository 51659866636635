import React from "react";
import styled from "styled-components";
import { SnapshotResponse } from "../tekster/Types/Snapshot";
import { ExpandableBox } from "../components/ExpandableBox";
import RapportChapter from "./RapportChapter";
import { Heading2 } from "../components/Headings/Headings";
import { getText } from "../tekster/Overskrifts/Overskrifts";
import { hasBrudd } from "../utils/bruddUtils";
import { RapportReaksjonerInnhold } from "./RapportReaksjonerInnhold";

const HeadingWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`;

type RapportReaksjonerProps = {
  snapshot: SnapshotResponse;
  withoutObfuscationBackground?: boolean;
  isForelopigRapport: boolean;
  withExpandable: boolean;
  openByDefault?: boolean;
};

const RapportReaksjoner: React.FC<RapportReaksjonerProps> = ({
  snapshot,
  withoutObfuscationBackground = false,
  isForelopigRapport,
  withExpandable,
  openByDefault = true,
}) => {
  const rapportHasNoBrudd = !hasBrudd(snapshot.brudd?.kontrollsporsmals ?? []);
  const Body = rapportHasNoBrudd ? (
    <p>
      {getText(snapshot.omTilsynet?.malform, (t) => t.brukerIngenReaksjoner)}
    </p>
  ) : (
    <RapportReaksjonerInnhold
      snapshot={snapshot}
      isForelopigRapport={isForelopigRapport}
      withoutObfuscationBackground={withoutObfuscationBackground}
    />
  );
  return withExpandable ? (
    <ExpandableBox
      id="rapport-reaksjoner"
      title={getText(snapshot.omTilsynet?.malform, (t) =>
        isForelopigRapport ? t.reaksjonerForelopigRapport : t.reaksjoner,
      )}
      initialState={openByDefault ? "expanded" : "collapsed"}
    >
      {Body}
    </ExpandableBox>
  ) : (
    <RapportChapter>
      <HeadingWrapper>
        <Heading2>
          {getText(snapshot.omTilsynet?.malform, (t) =>
            isForelopigRapport ? t.reaksjonerForelopigRapport : t.reaksjoner,
          )}
        </Heading2>
      </HeadingWrapper>
      {Body}
    </RapportChapter>
  );
};

export default RapportReaksjoner;
