import React from "react";
import {
  AccessToTilsynType,
  PubliseringMalType,
  PublisertTilsynResponse,
} from "ApiClients";
import { ReadAccessTilsynsrapport } from "./Tilsynsrapport/ReadAccessTilsynsrapport";
import { WriteAccessTilsynsrapport } from "./Tilsynsrapport/WriteAccessTilsynsrapport";

type TilsynProps = {
  tilsyn: PublisertTilsynResponse;
};

export const Rapport: React.FC<TilsynProps> = ({ tilsyn }) => {
  if (!tilsyn) return null;

  const publiseringMalComponent = () => {
    switch (tilsyn.tilgangType) {
      case AccessToTilsynType.ReadAndWriteUnObfuscated:
        return (
          <WriteAccessTilsynsrapport
            tilsyn={tilsyn}
            isForelopigRapport={
              tilsyn.publiseringMalType ===
              PubliseringMalType.ForelopigTilsynsrapport
            }
          />
        );
      case AccessToTilsynType.ReadObfuscatedOnly:
      case AccessToTilsynType.ReadUnObfuscated:
        return (
          <ReadAccessTilsynsrapport
            tilsyn={tilsyn}
            isForelopigRapport={
              tilsyn.publiseringMalType ===
              PubliseringMalType.ForelopigTilsynsrapport
            }
          />
        );
      default:
        return null;
    }
  };

  return <>{publiseringMalComponent()}</>;
};
