import React from "react";
import { BeskrivelseAvTilsynsObjekt } from "./BeskrivelseAvTilsynsObjekt";
import { SnapshotResponse } from "../../tekster/Types/Snapshot";
import { ExpandableBox } from "../../components/ExpandableBox";
import { Vurdering } from "./Vurdering";
import { getText } from "../../tekster/Overskrifts/Overskrifts";

type IProps = {
  snapshot: SnapshotResponse;
  withoutObfuscationBackground?: boolean;
  openByDefault?: boolean;
  isForelopigRapport: boolean;
};

const RapportRegelverkOgVurdering: React.FC<IProps> = ({
  snapshot,
  withoutObfuscationBackground,
  isForelopigRapport,
  openByDefault = true,
}) => {
  return (
    <ExpandableBox
      id="rapport-regelverk"
      title={getText(
        snapshot.omTilsynet?.malform,
        (t) => t.regelverkOgVurdering,
      )}
      initialState={openByDefault ? "expanded" : "collapsed"}
      noContentMargin
    >
      <BeskrivelseAvTilsynsObjekt
        beskrivelseAvTilsynsObjekt={snapshot.beskrivelseAvTilsynsObjektet}
      />
      <Vurdering
        vurdering={snapshot.regelverkOgVurdering}
        withoutObfuscationBackground={withoutObfuscationBackground}
        isForelopigRapport={isForelopigRapport}
        snapshot={snapshot}
      />
    </ExpandableBox>
  );
};

export default RapportRegelverkOgVurdering;
