import React from "react";
import {
  DetailedList,
  DetailedListCell,
  DetailedListRow,
} from "@utdanningsdirektoratet/detailedlist";
import styled from "styled-components";
import { Select } from "@utdanningsdirektoratet/select";
import {
  KontrollsporsmalSvarType,
  SnapshotResponse,
} from "../tekster/Types/Snapshot";
import { BruddPaRegelverket } from "../tekster/BruddPaRegelverket/BruddPaRegelverket";
import { ExpandableBox } from "../components/ExpandableBox";
import RapportChapter from "./RapportChapter";
import { Heading2 } from "../components/Headings/Headings";
import { getText, TextType } from "../tekster/Overskrifts/Overskrifts";
import { hasBrudd } from "../utils/bruddUtils";

const DivWithSelect = styled.div`
  width: fit-content;
  margin-top: 1rem;
`;

const StyledSelect = styled(Select)`
  font-weight: bold;
  border-bottom: none;
`;

const NoBreak = styled.div`
  break-inside: avoid-page;
`;

type RapportBruddProps = {
  snapshot: SnapshotResponse;
  withExpandable: boolean;
  isForelopigRapport: boolean;
  alwaysShowAllKontrollsporsmals?: boolean;
  openByDefault?: boolean;
};
const RapportBrudd: React.FC<RapportBruddProps> = ({
  snapshot,
  withExpandable,
  isForelopigRapport,
  alwaysShowAllKontrollsporsmals,
  openByDefault = true,
}) => {
  const rapportHasBrudd = hasBrudd(snapshot.brudd?.kontrollsporsmals ?? []);
  const [kontrollSporsmalToShow, setKontrollSporsmalToShow] = React.useState(
    rapportHasBrudd && !alwaysShowAllKontrollsporsmals ? "Brudd" : "Alle",
  );

  const kontrollsporsmal =
    snapshot.regelverkOgVurdering?.tilsynstemas.flatMap((t) =>
      t.paleggs.flatMap((p) => p.kontrollsporsmals),
    ) ?? [];
  const filteredKontrollSporsmal = kontrollsporsmal.filter(
    (k) =>
      kontrollSporsmalToShow !== "Brudd" ||
      (kontrollSporsmalToShow === "Brudd" &&
        k.svar === KontrollsporsmalSvarType.Nei),
  );

  const KontrollsporsmalsHeader = (
    <DetailedListRow header>
      <DetailedListCell size="80%" left>
        Kontrollspørsmål
      </DetailedListCell>
      <DetailedListCell size="20%" left>
        {getText(snapshot.omTilsynet?.malform ?? "Bokmål", (t) =>
          isForelopigRapport
            ? t.kontrollsporsmalHeaderSvarForelopigRapport
            : t.kontrollsporsmalHeaderSvar,
        )}
      </DetailedListCell>
    </DetailedListRow>
  );

  const calculateTitleTextType = (t: TextType) => {
    if (isForelopigRapport && rapportHasBrudd) return t.muligBrudd;
    if (rapportHasBrudd) return t.brudd;
    return t.ingenBrudd;
  };
  const title = getText(snapshot.omTilsynet?.malform ?? "Bokmål", (t) =>
    calculateTitleTextType(t),
  );

  const Body = (
    <>
      <BruddPaRegelverket
        snapshot={snapshot}
        isForelopigRapport={isForelopigRapport}
      />
      <NoBreak>
        {!alwaysShowAllKontrollsporsmals && (
          <DivWithSelect>
            <StyledSelect
              value={kontrollSporsmalToShow}
              onChange={(e) => setKontrollSporsmalToShow(e.currentTarget.value)}
              options={[
                {
                  text: getText(
                    snapshot.omTilsynet?.malform ?? "Bokmål",
                    (t) =>
                      isForelopigRapport
                        ? t.toggleKontrollsporsmalWithBruddForelopigRapport
                        : t.toggleKontrollsporsmalWithBrudd,
                  ),
                  value: "Brudd",
                },
                {
                  text: getText(
                    snapshot.omTilsynet?.malform ?? "Bokmål",
                    (t) => t.toggleKontrollsporsmalWithoutBrudd,
                  ),
                  value: "Alle",
                },
              ]}
            />
          </DivWithSelect>
        )}

        <DetailedList title={KontrollsporsmalsHeader}>
          {filteredKontrollSporsmal?.map((k, i) => (
            <DetailedListRow color="grey" key={i}>
              <DetailedListCell
                size="80%"
                style={{ textOverflow: "unset", whiteSpace: "unset" }}
              >
                {k.sporsmalTekst}
              </DetailedListCell>
              <DetailedListCell size="20%">
                {k.svar === KontrollsporsmalSvarType.IkkeVurdert
                  ? "Ikke vurdert"
                  : k.svar}
              </DetailedListCell>
            </DetailedListRow>
          ))}
        </DetailedList>
      </NoBreak>
    </>
  );

  return withExpandable ? (
    <ExpandableBox
      id="rapport-brudd"
      title={title}
      initialState={openByDefault ? "expanded" : "collapsed"}
    >
      {Body}
    </ExpandableBox>
  ) : (
    <RapportChapter>
      <Heading2>{title}</Heading2>
      {Body}
    </RapportChapter>
  );
};
export default RapportBrudd;
