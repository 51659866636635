import React from "react";
import { Icon } from "@utdanningsdirektoratet/icon";

import styled from "styled-components";

const IconStyled = styled(Icon)`
  flex-shrink: 0;
  margin-right: 0.5rem;
`;

export const ReadAccessObfuscatedLoggedInContent: React.FC = () => {
  return (
    <>
      <IconStyled icon="circle cross" />
      Du mangler tilgang til denne organisasjonen. Kontakt daglig leder hvis du
      trenger tilgang.
    </>
  );
};
