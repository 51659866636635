import React from "react";
import { Barnehageloven } from "./Barnehageloven";
import { getText } from "../../../Overskrifts/Overskrifts";
import RichTextViewWithSladd from "../../../../components/RichTextWithSladd/RichTextViewWithSladd";
import { IReaksjonProps } from "../../Common/reaksjonInterfaces";

export const EndeligRapportPermanentStenging: React.FC<IReaksjonProps> = ({
  snapshot,
  withoutObfuscationBackground,
}) => {
  const heading = getText(
    snapshot.omTilsynet?.malform ?? "Bokmål",
    (t) => t.reaksjonStengePermanentVedtak,
  );
  if (!snapshot.reaksjoner?.permanentStengingAvVirksomhet?.isValgt) {
    return null;
  }
  const comment = snapshot.reaksjoner?.permanentStengingAvVirksomhet?.kommentar;

  return (
    <>
      <Barnehageloven snapshot={snapshot} heading={heading} />
      {comment && (
        <RichTextViewWithSladd
          html={comment}
          withoutObfuscationBackground={withoutObfuscationBackground}
        />
      )}
      {snapshot.omTilsynet?.lovTittel !== "Barnehageloven" && (
        <b>Reaksjonen permanent stenging gjelder kun for barnehageloven</b>
      )}
    </>
  );
};
