import React from "react";
import { SnapshotResponse } from "../../Types/Snapshot";
import { DereKanGiTilbakemelding } from "./DereKanGiTilbakemelding/DereKanGiTilbakemelding";
import { getText } from "../../Overskrifts/Overskrifts";

type IProps = {
  snapshot: SnapshotResponse;
  innsendingFristDatoUtc?: Date;
};

export const ForelopigRapportHvaSkjerNa: React.FC<IProps> = ({
  snapshot,
  innsendingFristDatoUtc,
}) => {
  const heading = getText(
    snapshot.omTilsynet?.malform,
    (t) => t.tilbakemelding,
  );
  return (
    <DereKanGiTilbakemelding
      snapshot={snapshot}
      innsendingFristDatoUtc={innsendingFristDatoUtc}
      heading={heading}
    />
  );
};
