import React, { createContext } from "react";

export type RenderRedigerbarTekstFunction = (
  navn: string,
  standardtekst: React.ReactNode,
  noFormatting: boolean,
  wrapper: React.ComponentType<{ children: React.ReactNode }>,
) => React.ReactElement;

export const RedigerbarTekstContext = createContext<
  RenderRedigerbarTekstFunction | undefined
>(undefined);
