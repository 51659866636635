import React from "react";
import { Vedlegg } from "@utdanningsdirektoratet/vedlegg/index";
import { FileInput } from "@utdanningsdirektoratet/fileinput/index";
import {
  DeletePubliseringDokumentClient,
  InnsendingResponse,
  UploadPubliseringDokumentClient,
} from "ApiClients";
import { innsendingsRefreshKey } from "hooks/getters/publiseringInnsendelse";
import { handleError } from "utils/errorhandler";
import { getUrls } from "globalFunctions";
import { hide, show } from "@utdanningsdirektoratet/slowaction";
import styled from "styled-components";
import { useContextState } from "StateProvider";
import { mutate } from "swr";

const VedleggWithDeleteButtonClose = styled(Vedlegg)`
  width: fit-content + 1rem;
  margin-top: 1rem;
`;

const FileInputWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

type IProps = {
  innsending?: InnsendingResponse;
  error: boolean;
  publiseringId: string;
};

const uploadPubliseringDokumentClient = new UploadPubliseringDokumentClient();
const deletePubliseringDokumentClient = new DeletePubliseringDokumentClient();

export const InnsendingDokumenter: React.FC<IProps> = ({
  innsending,
  error,
  publiseringId,
}) => {
  const dokumenter = innsending?.dokumentList ?? [];

  const { dispatch } = useContextState();
  const urls = getUrls();

  const onUpload = async (files: Array<File>) => {
    const filesToUpload = files.map((file) => ({
      fileName: file.name,
      data: file,
    }));

    try {
      await show();
      await uploadPubliseringDokumentClient.uploadDokument(
        publiseringId,
        filesToUpload,
      );
      await mutate(innsendingsRefreshKey(publiseringId));
    } catch (e) {
      handleError(e, dispatch);
    } finally {
      await hide();
    }
  };

  const onDelete = async (dokumentId: string) => {
    try {
      await show();
      await deletePubliseringDokumentClient.deletePubliseringDokument(
        publiseringId,
        dokumentId,
      );
      await mutate(innsendingsRefreshKey(publiseringId));
    } catch (e) {
      handleError(e, dispatch);
    } finally {
      await hide();
    }
  };

  return (
    <FileInputWrapper>
      <FileInput
        label={
          dokumenter && dokumenter.length > 0
            ? "Last opp flere dokument"
            : "Last opp dokument"
        }
        multiple
        error={error}
        onChange={onUpload}
        accept="
            text/plain,
            text/xml,
            application/pdf,
            image/jpeg,
            text/richtext,
            application/msword,
            application/mspowerpoint,
            application/vnd.ms-excel,
            image/svg+xml,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.openxmlformats-officedocument.wordprocessingml.template,
            application/vnd.openxmlformats-officedocument.presentationml.presentation,
            application/vnd.openxmlformats-officedocument.presentationml.template,
            application/vnd.openxmlformats-officedocument.presentationml.slideshow,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.openxmlformats-officedocument.spreadsheetml.template,
            application/vnd.oasis.opendocument.text,
            application/vnd.oasis.opendocument.spreadsheet"
      />
      {dokumenter?.map((dokument) => (
        <VedleggWithDeleteButtonClose
          key={`uttalelseVedlegg-${dokument.id}`}
          title={dokument.filnavn}
          href={`${urls.downloadDokument}/${dokument.id}`}
          onDelete={async () => {
            await onDelete(dokument.id);
          }}
        />
      ))}
    </FileInputWrapper>
  );
};
