import React from "react";
import { SnapshotResponse } from "../../Types/Snapshot";
import { DereHarRettTilAKlage } from "./DereHarRettTilAKlage/DereHarRettTilAKlage";
import { ErklaeringOmRetting } from "./ErklaeringOmRetting/ErklaeringOmRetting";
import { hasBrudd } from "../../../utils/hasBrudd";

type IProps = {
  snapshot: SnapshotResponse;
  innsendingFristDatoUtc?: Date;
};

export const EndeligRapportHvaSkjerNa: React.FC<IProps> = ({
  snapshot,
  innsendingFristDatoUtc,
}) => {
  return (
    <>
      {hasBrudd(snapshot) && (
        <ErklaeringOmRetting
          snapshot={snapshot}
          innsendingFristDatoUtc={innsendingFristDatoUtc}
        />
      )}
      <DereHarRettTilAKlage snapshot={snapshot} />
    </>
  );
};
