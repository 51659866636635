import { LinkButton } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { AccessToTilsynType, PublisertTilsynResponse } from "ApiClients";
import { Box } from "components/Box";
import { getUrls } from "globalFunctions";
import { Bold } from "pages/Rapport/components/components/Bold";
import * as React from "react";
import { generatePath } from "react-router-dom";
import routes from "routes";
import { DATE_FORMAT, formatDate } from "SharedComponents/utils/date";
import styled from "styled-components";
import { InnsendingAvDokumentasjonInfoBoxTexts } from "./InnsendingAvDokumentasjonInfoBoxTexts";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const IconStyled = styled(Icon)`
  flex-shrink: 0;
`;

const getButtonText: (tilsyn: PublisertTilsynResponse) => string = (tilsyn) => {
  return tilsyn.tilgangType === AccessToTilsynType.ReadAndWriteUnObfuscated ||
    tilsyn.tilgangType === AccessToTilsynType.ReadUnObfuscated
    ? "Send inn dokumentasjon"
    : "Logg inn for å sende inn dokumentasjon";
};

const getButtonAriaLabel: (tilsyn: PublisertTilsynResponse) => string = (
  tilsyn,
) => {
  return tilsyn.tilgangType === AccessToTilsynType.ReadAndWriteUnObfuscated ||
    tilsyn.tilgangType === AccessToTilsynType.ReadUnObfuscated
    ? "Gå til siden for å sende inn dokumentasjon"
    : "Logg inn for å sende inn dokumentasjon";
};

const getButtonHref: (tilsyn: PublisertTilsynResponse) => string = (tilsyn) => {
  const targetRoute = generatePath(routes.innsending, {
    tilsynId: tilsyn.tilsynId,
  });
  if (tilsyn.tilgangType === AccessToTilsynType.ReadAndWriteUnObfuscated) {
    return targetRoute;
  }

  return `${getUrls().loggInn}?returnUrl=${targetRoute}`;
};

const getBoxColor = (
  publiseringFristUtloptUtenInnsending: boolean,
  isInnsendingSide: boolean,
) => {
  if (publiseringFristUtloptUtenInnsending) return "peach5";
  if (isInnsendingSide) return "white";
  return "slate5";
};

type InnsendingAvDokumentasjonInfoBoxProps = {
  tilsyn: PublisertTilsynResponse;
  isInnsendingSide?: boolean;
};

export const InnsendingAvDokumentasjonInfoBox: React.FC<
  InnsendingAvDokumentasjonInfoBoxProps
> = ({ tilsyn, isInnsendingSide }) => {
  return (
    <Box
      $background={getBoxColor(
        tilsyn.publiseringFristUtloptUtenInnsending,
        !!isInnsendingSide,
      )}
      className="u--marginBottom2"
    >
      <InnsendingAvDokumentasjonInfoBoxTexts
        tilsyn={tilsyn}
        isInnsendingSide={!!isInnsendingSide}
      />
      {tilsyn.publiseringFristUtloptUtenInnsending ? (
        <Container>
          <IconStyled icon="circle cross" />
          <Bold>
            Frist utløp{" "}
            {formatDate(tilsyn.innsendingFristDatoUtc!, DATE_FORMAT)}, og det er
            ikke lenger mulig å sende inn dokumentasjon.
          </Bold>
        </Container>
      ) : (
        <>
          {!isInnsendingSide && tilsyn.canSendInn && (
            <LinkButton
              aria-label={getButtonAriaLabel(tilsyn)}
              primary
              href={getButtonHref(tilsyn)}
              disabled={
                tilsyn.tilgangType === AccessToTilsynType.ReadUnObfuscated
              }
            >
              {getButtonText(tilsyn)}
              <Icon
                type="wide"
                icon="arrow right long"
                className="u--marginLeft1"
              />
            </LinkButton>
          )}
          {!(isInnsendingSide || tilsyn.canSendInn) && (
            <Container>
              <IconStyled icon="circle check" />{" "}
              <Bold>Dokumentasjon er sendt inn</Bold>
            </Container>
          )}
        </>
      )}
    </Box>
  );
};
