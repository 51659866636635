import * as React from "react";
import { show, hide } from "@utdanningsdirektoratet/slowaction";

export const Loader: React.FC = () => {
  React.useEffect(() => {
    show();
    return () => {
      hide();
    };
  }, []);
  return null;
};
