import React from "react";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { Heading3 } from "../../../../components/Headings/Headings";
import { getText } from "../../../Overskrifts/Overskrifts";

type IProps = {
  snapshot: SnapshotResponse;
};

export const Barnehageloven: React.FC<IProps> = ({ snapshot }) => {
  const heading = getText(
    snapshot.omTilsynet?.malform ?? "Bokmål",
    (t) => t.reaksjonStengePermanentVarsel,
  );
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <>
        <Heading3>{heading}</Heading3>
        <p>
          Vi varslar at vi vil stenge barnehagen permanent, jf. barnehagelova §
          56.
        </p>
      </>
    );
  }
  return (
    <>
      <Heading3>{heading}</Heading3>
      <p>
        Vi varsler at vi vil stenge barnehagen permanent, jf. barnehageloven §
        56.
      </p>
    </>
  );
};
