import React from "react";
import styled from "styled-components";
import { colors } from "@utdanningsdirektoratet/styles";
import { Palegg } from "./Palegg";
import {
  RegelverkOgVurderingTilsynstemaSnapshotResponse,
  SnapshotResponse,
} from "../../tekster/Types/Snapshot";
import { Heading3 } from "../../components/Headings/Headings";

type IProps = {
  tilsynstema: RegelverkOgVurderingTilsynstemaSnapshotResponse;
  withoutObfuscationBackground?: boolean;
  isForelopigRapport: boolean;
  snapshot: SnapshotResponse;
};

const HeadingBox = styled.div`
  padding: 1.5rem;
  background: ${colors.green4};
  margin-bottom: 1.5rem;
`;

const PaleggsBox = styled.div`
  margin: 0 1.5rem 2rem 1.5rem;
`;

export const Tilsynstema: React.FC<IProps> = ({
  tilsynstema,
  withoutObfuscationBackground,
  isForelopigRapport,
  snapshot,
}) => {
  const { paleggs } = tilsynstema;

  return (
    <>
      <HeadingBox>
        <Heading3>{`${tilsynstema.tittel}`}</Heading3>
      </HeadingBox>
      <PaleggsBox>
        {paleggs.map((palegg, i) => (
          <Palegg
            palegg={palegg}
            key={`${palegg.tittel}-${i}`}
            withoutObfuscationBackground={withoutObfuscationBackground}
            isForelopigRapport={isForelopigRapport}
            snapshot={snapshot}
          />
        ))}
      </PaleggsBox>
    </>
  );
};
