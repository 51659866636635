import React from "react";
import { SnapshotResponse } from "../../Types/Snapshot";
import { DATE_FORMAT, formatDate } from "../../../utils/date";
import { RedigerbarTekst } from "../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
};

export const Folkehoyskoleloven: React.FC<IProps> = ({ snapshot }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <RedigerbarTekst navn="ForelopigRapportInnledning" snapshot={snapshot}>
        <p>
          Vi fører tilsyn med skolar som er godkjende etter folkehøgskolelova,
          jf. folkehøgskoleforskrifta § 13. I tilsyn kontrollerer vi om skolane
          oppfyller folkehøgskolelova med forskrifter, føresetnader i
          godkjenninga av skolen og anna regelverk som folkehøgskolelova med
          forskrifter eller godkjenninga av skolen viser til.
        </p>
        <p>
          Våre tilsyn er offentleg myndigheitsutøving, noko som inneber at
          tilsynet skal gjennomførast i samsvar med reglane i forvaltningsretten
          og offentleglova. I tilsynet behandlar vi personopplysningar. Les meir
          om vår behandling av personopplysningar på{" "}
          <a
            target="__blank"
            href="https://www.udir.no/regelverk-og-tilsyn/personvern-for-barnehage-og-skole/behandling-av-personopplysninger-i-tilsyn/"
          >
            https://www.udir.no/regelverk-og-tilsyn/personvern-for-barnehage-og-skole/behandling-av-personopplysninger-i-tilsyn/
          </a>
        </p>
        <p>
          Vi opna tilsyn med {snapshot.omTilsynet.tilsynForesMedNavn}{" "}
          {snapshot.omTilsynet.startetDatoUtc
            ? formatDate(
                snapshot.omTilsynet.startetDatoUtc.toString(),
                DATE_FORMAT,
              )
            : ""}
          . Vi har fått tilstrekkeleg informasjon til å gjennomføre tilsynet.
        </p>
      </RedigerbarTekst>
    );
  }
  return (
    <RedigerbarTekst navn="ForelopigRapportInnledning" snapshot={snapshot}>
      <p>
        Vi fører tilsyn med skoler som er godkjent etter folkehøyskoleloven, jf.
        folkehøyskoleforskriften § 13. I tilsyn kontrollerer vi om skolene
        oppfyller folkehøyskoleloven med forskrifter, forutsetninger i skolens
        godkjenning og annet regelverk som folkehøyskoleloven med forskrifter
        eller skolens godkjenning viser til.
      </p>
      <p>
        Våre tilsyn er offentlig myndighetsutøvelse, noe som innebærer at
        tilsynet skal gjennomføres i samsvar med reglene i forvaltningsretten og
        offentlighetsloven. I tilsynet behandler vi personopplysninger. Les mer
        om vår behandling av opplysninger på{" "}
        <a
          target="__blank"
          href="https://www.udir.no/regelverk-og-tilsyn/personvern-for-barnehage-og-skole/behandling-av-personopplysninger-i-tilsyn/"
        >
          https://www.udir.no/regelverk-og-tilsyn/personvern-for-barnehage-og-skole/behandling-av-personopplysninger-i-tilsyn/
        </a>
      </p>
      <p>
        Vi åpnet tilsyn med {snapshot.omTilsynet?.tilsynForesMedNavn}{" "}
        {snapshot.omTilsynet?.startetDatoUtc
          ? formatDate(
              snapshot.omTilsynet?.startetDatoUtc.toString(),
              DATE_FORMAT,
            )
          : ""}
        . Vi har fått tilstrekkelig informasjon til å gjennomføre tilsynet.
      </p>
    </RedigerbarTekst>
  );
};
