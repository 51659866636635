import React from "react";
import { useGetPublisertTilsyn } from "hooks/getters/tilsyn";
import { useParams } from "react-router-dom";
import { SidePanel } from "@utdanningsdirektoratet/layout";
import {
  BackgroundContainer,
  MainPanel,
  SidePanelContainer,
} from "components/Layout";
import { AccessToTilsynType, PubliseringMalType } from "../../ApiClients";
import { InnsendingAvDokumentasjon } from "./InnsendingAvDokumentasjon/InnsendingAvDokumentasjon";
import { Rapport } from "../Rapport/Rapport";
import { TilsynHeader } from "./components/TilsynHeader";
import { LenkeTilTidligereInnsendinger } from "./InnsendingAvDokumentasjon/LenkeTilTidligereInnsendinger";
import { TilsynTidslinje } from "./components/TilsynTidslinje";
import { TilgangMessage } from "../../components/TilgangMessage";
import { TilsynStatusoppdatering } from "./components/TilsynStatusoppdatering";
import { getStatusoppdateringLastPublisering } from "./utils";
import { usePageViewTrackingWhenTilsynLoaded } from "../../utils/analyticsUtils";

export const Tilsyn: React.FC = () => {
  const { tilsynId } = useParams<"tilsynId">() as { tilsynId: string };
  const { data: tilsyn } = useGetPublisertTilsyn(tilsynId);

  usePageViewTrackingWhenTilsynLoaded(tilsyn);

  if (!tilsyn) return null;

  const content = () => {
    switch (tilsyn.publiseringMalType) {
      case PubliseringMalType.InnsendingAvDokumentasjon:
        return <InnsendingAvDokumentasjon tilsyn={tilsyn} />;
      case PubliseringMalType.ForelopigTilsynsrapport:
      case PubliseringMalType.Tilsynsrapport:
        if (tilsyn.publiseringVedtakIsEndret) {
          return null;
        }
        return <Rapport tilsyn={tilsyn} />;
      default:
        return null;
    }
  };

  return (
    <BackgroundContainer>
      <TilsynHeader tilsyn={tilsyn} isInnsendingSide={false} />
      <MainPanel>
        {getStatusoppdateringLastPublisering(tilsyn.publiseringer) && (
          <TilsynStatusoppdatering tilsyn={tilsyn} />
        )}
        {content()}
        {tilsyn.tilgangType !== AccessToTilsynType.ReadAndWriteUnObfuscated && (
          <TilgangMessage tilgangType={tilsyn.tilgangType} />
        )}
      </MainPanel>
      <SidePanel>
        <SidePanelContainer>
          <TilsynTidslinje tilsyn={tilsyn} />
          {tilsyn.publiseringHasInnsendteInnsendinger && (
            <LenkeTilTidligereInnsendinger tilsyn={tilsyn} />
          )}
        </SidePanelContainer>
      </SidePanel>
    </BackgroundContainer>
  );
};
