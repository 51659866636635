import React from "react";
import styled from "styled-components";
import { RichTextView, ConfigBuilder } from "@utdanningsdirektoratet/richtext";
import { SladdStyle } from "./styles";

type RichTextViewProps = React.ComponentProps<typeof RichTextView> & {
  withoutObfuscationBackground?: boolean;
};

const StyledRichTextView = styled(RichTextView)<{
  withoutObfuscationBackground?: boolean;
}>`
  ${(props) => SladdStyle(props.withoutObfuscationBackground)};
`;

const config = new ConfigBuilder()
  .withAllowedTags(["s", "span", "annotations"])
  .withImages()
  .build();

const RichTextViewWithSladd: React.FC<RichTextViewProps> = (props) => {
  return <StyledRichTextView {...props} config={config} />;
};

export default RichTextViewWithSladd;
