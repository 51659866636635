import React from "react";
import { SnapshotResponse } from "../tekster/Types/Snapshot";
import { ForelopigRapportReaksjonerIngress } from "../tekster/VareReaksjoner/ForelopigRapport/Ingress/ForelopigRapportReaksjonerIngress";
import { EndeligRapportReaksjonerIngress } from "../tekster/VareReaksjoner/EndeligRapport/Ingress/EndeligRapportReaksjonerIngress";
import { ForelopigRapportRetteForholdene } from "../tekster/VareReaksjoner/ForelopigRapport/RetteForholdene/ForelopigRapportRetteForholdene";
import { EndeligRapportRetteForholdene } from "../tekster/VareReaksjoner/EndeligRapport/RetteForholdene/EndeligRapportRetteForholdene";
import { ForelopigRapportKravOmTilbakebetaling } from "../tekster/VareReaksjoner/ForelopigRapport/KravOmTilbakebetaling/ForelopigRapportKravOmTilbakebetaling";
import { EndeligRapportKravOmTilbakebetaling } from "../tekster/VareReaksjoner/EndeligRapport/KravOmTilbakebetaling/EndeligRapportKravOmTilbakebetaling";
import { ForelopigRapportHoldeTilbakeTilskudd } from "../tekster/VareReaksjoner/ForelopigRapport/HoldeTilbakeTilskudd/ForelopigRapportHoldeTilbakeTilskudd";
import { EndeligRapportHoldeTilbakeTilskudd } from "../tekster/VareReaksjoner/EndeligRapport/HoldeTilbakeTilskudd/EndeligRapportHoldeTilbakeTilskudd";
import { ForelopigRapportPermanentStenging } from "../tekster/VareReaksjoner/ForelopigRapport/PermanentStenging/ForelopigRapportPermanentStenging";
import { EndeligRapportPermanentStenging } from "../tekster/VareReaksjoner/EndeligRapport/PermanentStenging/EndeligRapportPermanentStenging";
import { ForelopigRapportTidsbegrensetStenging } from "../tekster/VareReaksjoner/ForelopigRapport/TidsbegrensetStenging/ForelopigRapportTidsbegrensetStenging";
import { EndeligRapportTidsbegrensetStenging } from "../tekster/VareReaksjoner/EndeligRapport/TidsbegrensetStenging/EndeligRapportTidsbegrensetStenging";
import { ForelopigRapportTrekkeTilbakeGodkjenning } from "../tekster/VareReaksjoner/ForelopigRapport/TrekkeTilbakeGodkjenning/ForelopigRapportTrekkeTilbakeGodkjenning";
import { EndeligRapportTrekkeTilbakeGodkjenning } from "../tekster/VareReaksjoner/EndeligRapport/TrekkeTilbakeGodkjenning/EndeligRapportTrekkeTilbakeGodkjenning";

type Props = {
  snapshot: SnapshotResponse;
  isForelopigRapport: boolean;
  withoutObfuscationBackground?: boolean;
};

export const RapportReaksjonerInnhold: React.FC<Props> = ({
  snapshot,
  isForelopigRapport,
  withoutObfuscationBackground = false,
}) => {
  if (isForelopigRapport) {
    return (
      <>
        <ForelopigRapportReaksjonerIngress snapshot={snapshot} />
        <ForelopigRapportRetteForholdene snapshot={snapshot} />
        <ForelopigRapportKravOmTilbakebetaling
          snapshot={snapshot}
          withoutObfuscationBackground={withoutObfuscationBackground}
        />
        <ForelopigRapportHoldeTilbakeTilskudd
          snapshot={snapshot}
          withoutObfuscationBackground={withoutObfuscationBackground}
        />
        <ForelopigRapportPermanentStenging
          snapshot={snapshot}
          withoutObfuscationBackground={withoutObfuscationBackground}
        />
        <ForelopigRapportTidsbegrensetStenging
          snapshot={snapshot}
          withoutObfuscationBackground={withoutObfuscationBackground}
        />
        <ForelopigRapportTrekkeTilbakeGodkjenning
          snapshot={snapshot}
          withoutObfuscationBackground={withoutObfuscationBackground}
        />
      </>
    );
  }

  return (
    <>
      <EndeligRapportReaksjonerIngress snapshot={snapshot} />
      <EndeligRapportRetteForholdene snapshot={snapshot} />
      <EndeligRapportKravOmTilbakebetaling
        snapshot={snapshot}
        withoutObfuscationBackground={withoutObfuscationBackground}
      />
      <EndeligRapportHoldeTilbakeTilskudd
        snapshot={snapshot}
        withoutObfuscationBackground={withoutObfuscationBackground}
      />
      <EndeligRapportPermanentStenging
        snapshot={snapshot}
        withoutObfuscationBackground={withoutObfuscationBackground}
      />
      <EndeligRapportTidsbegrensetStenging
        snapshot={snapshot}
        withoutObfuscationBackground={withoutObfuscationBackground}
      />
      <EndeligRapportTrekkeTilbakeGodkjenning
        snapshot={snapshot}
        withoutObfuscationBackground={withoutObfuscationBackground}
      />
    </>
  );
};
