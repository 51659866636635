import React from "react";
import { Autocomplete } from "@utdanningsdirektoratet/autocomplete";
import styled from "styled-components";
import { mediaqueries } from "@utdanningsdirektoratet/styles";

type TilsynstemaSearchProps = {
  options: Array<{ text: string; value: string }>;
  value: string | undefined;
  onChange: (value: string) => void;
  sessionKey: string;
};

const StyledAutocomplete = styled(Autocomplete)`
  width: 15rem;

  @media ${mediaqueries.small} {
    width: 100%;
    max-width: 35rem;
  }
`;
export const TilsynstemaSearch: React.FC<TilsynstemaSearchProps> = ({
  options,
  value,
  onChange,
  sessionKey,
}) => {
  const onValueChange = (newValue: string) => {
    onChange(newValue);
    sessionStorage.setItem(sessionKey, newValue);
  };

  return (
    <StyledAutocomplete
      options={options}
      value={value}
      onChange={onValueChange}
      placeholder="Søk på tilsynstema"
      label="Tilsynstema"
    />
  );
};
