import {
  RegelverkOgVurderingTilsynstemaSnapshotResponse,
  SnapshotResponse,
} from "../tekster/Types/Snapshot";
import { CreateCommaSeparatedList, deCapitalize } from "./stringUtils";

const getTemaTextWithHjemmel: (
  tema: RegelverkOgVurderingTilsynstemaSnapshotResponse,
  withHjemmel?: boolean,
) => string = (tema, withHjemmel = true) => {
  return tema.hjemmel && withHjemmel
    ? `${tema.tittel}, jf. ${tema.hjemmel}`
    : tema.tittel ?? "";
};

export const tilsynstemaCombiner = (
  snapshot: SnapshotResponse,
  withHjemmel?: boolean,
): string => {
  const texts = snapshot.regelverkOgVurdering
    ? snapshot.regelverkOgVurdering.tilsynstemas.map((tema) =>
        deCapitalize(getTemaTextWithHjemmel(tema, withHjemmel ?? true)),
      )
    : [];

  return CreateCommaSeparatedList(texts);
};
