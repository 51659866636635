import React from "react";
import { SidePanel } from "@utdanningsdirektoratet/layout/index";
import { newNotification } from "@utdanningsdirektoratet/notifications/index";
import { mutate } from "swr";
import { Button } from "@utdanningsdirektoratet/button/index";
import { animateScroll } from "react-scroll";
import { TilsynHeader } from "pages/Tilsyn/components/TilsynHeader";
import { Box } from "components/Box";
import {
  BackgroundContainer,
  MainPanel,
  SidePanelContainer,
} from "components/Layout";
import { InnsendingAvDokumentasjonInfoBox } from "pages/Tilsyn/InnsendingAvDokumentasjon/InnsendingAvDokumentasjonInfoBox";
import { RettTilAKlage } from "pages/Tilsyn/InnsendingAvDokumentasjon/RettTilaKlage";
import { accessLevelLowerThan } from "utils/tilgang";
import styled from "styled-components";
import { TilsynTidslinje } from "pages/Tilsyn/components/TilsynTidslinje";
import { Spacer } from "components/Spacer";
import { Checkbox, CheckboxGroup } from "@utdanningsdirektoratet/checkbox";
import {
  AccessToTilsynType,
  InnsendingStatus,
  PublisertTilsynResponse,
  SaveDokumentasjonClient,
  SaveInnsendingDokumentasjonskravSvarInput,
  SendInnDokumentasjonClient,
} from "../../../ApiClients";
import {
  innsendingsRefreshKey,
  useGetInnsendings,
} from "../../../hooks/getters/publiseringInnsendelse";
import {
  publiseringDokumentasjonskravRefreshKey,
  useGetPubliseringDokumentasjonskravs,
} from "../../../hooks/getters/publiseringDokumentasjonkravs";
import { getConfig } from "../../../globalFunctions";
import { TidligereInnsendteDokumentasjonskravs } from "./TidligereInnsendteDokumentasjonskravs";
import Dokumentasjonskravs from "./Dokumentasjonskravs";
import { useSurroundWithErrorHandling } from "../../../utils/errorhandler";
import { publiseringTilsynRefreshKey } from "../../../hooks/getters/tilsyn";
import { validateDokumentasjonskrav } from "./Dokumentasjonskrav";
import { GenerellKommentar } from "./GenerellKommentar";
import { getTidligereKommentarer } from "./getTidligereKommentarer";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 2rem;
`;

type Props = {
  tilsyn: PublisertTilsynResponse;
};

const saveDokumentasjonClient = new SaveDokumentasjonClient();
const sendInnDokumentasjonClient = new SendInnDokumentasjonClient();

export const SendInnDokumentasjon: React.FC<Props> = ({ tilsyn }) => {
  const license = getConfig().froalaLicense;
  const { data: dokumentasjonskravs } = useGetPubliseringDokumentasjonskravs(
    tilsyn.publiseringId,
  );
  const { data: innsendings } = useGetInnsendings(tilsyn.publiseringId);
  const innsending = (innsendings ?? []).find(
    (i) => i.status === InnsendingStatus.UnderArbeid,
  );
  const innsendteInnsendinger = innsendings
    ?.filter((i) => i.status === InnsendingStatus.Innsendt)
    .filter((i) => i.innsendtDateUtc)
    .sort(
      (a, b) =>
        new Date(b.innsendtDateUtc!).getTime() -
        new Date(a.innsendtDateUtc!).getTime(),
    );

  const [showError, setShowError] = React.useState(false);
  const [innsendingKommentar, setInnsendingKommentar] =
    React.useState<string>("");
  const [dokumentasjonskravSvars, setDokumentasjonskravSvars] = React.useState<
    SaveInnsendingDokumentasjonskravSvarInput[] | undefined
  >();
  const [bekreftelseIsChecked, setBekreftelseIsChecked] = React.useState(false);

  React.useEffect(() => {
    if (
      !dokumentasjonskravSvars &&
      dokumentasjonskravs &&
      innsendings &&
      !innsending
    ) {
      setDokumentasjonskravSvars(
        dokumentasjonskravs
          .filter((krav) => krav.isLocked === false)
          .map((dk) => ({
            dokumentasjonskravId: dk.dokumentasjonskravExternalId,
            kommentar: "",
            svartIkkeAktuelt: false,
          })),
      );
    }

    if (
      !dokumentasjonskravSvars &&
      dokumentasjonskravs &&
      innsendings &&
      innsending
    ) {
      const result = dokumentasjonskravs
        .filter((krav) => krav.isLocked === false)
        .map((krav) => {
          const svar = innsending.dokumentasjonskravSvarList.find(
            (dkSvar) =>
              dkSvar.dokumentasjonskravId === krav.dokumentasjonskravId,
          );

          if (svar) {
            return {
              dokumentasjonskravId: krav.dokumentasjonskravExternalId,
              svartIkkeAktuelt: svar.svartIkkeAktuelt,
              kommentar: svar.kommentar,
            };
          }
          return {
            dokumentasjonskravId: krav.dokumentasjonskravExternalId,
            kommentar: "",
            svartIkkeAktuelt: false,
          };
        });

      setDokumentasjonskravSvars(result);
      setInnsendingKommentar(innsending.kommentar ?? "");
    }
  }, [dokumentasjonskravSvars, dokumentasjonskravs, innsending, innsendings]);

  const validateAllDokumentasjonskrav = () => {
    if (dokumentasjonskravSvars) {
      for (let i = 0; i < dokumentasjonskravSvars.length; i += 1) {
        if (
          !validateDokumentasjonskrav(
            dokumentasjonskravSvars[i].svartIkkeAktuelt,
            dokumentasjonskravSvars[i].kommentar ?? "",
            dokumentasjonskravSvars[i].dokumentasjonskravId,
            innsending,
          )
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const onSave = useSurroundWithErrorHandling(async () => {
    await saveDokumentasjonClient.save({
      tilsynId: tilsyn.tilsynId,
      publiseringId: tilsyn.publiseringId,
      innsendingKommentar,
      dokumentasjonskravSvar: dokumentasjonskravSvars ?? [],
    });
    await newNotification(
      "success",
      "Innsending er lagret, men ikke sendt inn.",
    );
    await mutate(innsendingsRefreshKey(tilsyn.publiseringId));
    await mutate(publiseringTilsynRefreshKey(tilsyn.tilsynId));
  });

  const onSendInn = useSurroundWithErrorHandling(async () => {
    if (!validateAllDokumentasjonskrav() || bekreftelseIsChecked === false) {
      setShowError(true);
      return;
    }
    await sendInnDokumentasjonClient.sendInn({
      tilsynId: tilsyn.tilsynId,
      innsendingKommentar,
      publiseringId: tilsyn.publiseringId,
      dokumentasjonskravSvar: dokumentasjonskravSvars ?? [],
    });
    await newNotification("success", "Innsending er sendt inn.");
    await mutate(innsendingsRefreshKey(tilsyn.publiseringId));
    await mutate(publiseringTilsynRefreshKey(tilsyn.tilsynId));
    await mutate(publiseringDokumentasjonskravRefreshKey(tilsyn.publiseringId));
    animateScroll.scrollToTop();
  });
  const areTidligereKommentarer = React.useMemo(() => {
    return getTidligereKommentarer(innsendteInnsendinger ?? []).length > 0;
  }, [innsendteInnsendinger]);

  return (
    <BackgroundContainer>
      <TilsynHeader tilsyn={tilsyn} isInnsendingSide />
      <MainPanel>
        <InnsendingAvDokumentasjonInfoBox tilsyn={tilsyn} isInnsendingSide />
        <Dokumentasjonskravs
          tilsyn={tilsyn}
          dokumentasjonskravSvars={dokumentasjonskravSvars}
          setDokumentasjonskravSvars={setDokumentasjonskravSvars}
          showError={showError}
        />
        <TidligereInnsendteDokumentasjonskravs
          dokumentasjonskravs={dokumentasjonskravs}
          innsendteInnsendinger={innsendteInnsendinger}
        />
        {(tilsyn.canSendInn || areTidligereKommentarer) && (
          <Box $background="white" className="u--marginBottom2">
            <GenerellKommentar
              tilsyn={tilsyn}
              license={license}
              onChange={setInnsendingKommentar}
              value={innsendingKommentar}
              innsendinger={innsendteInnsendinger}
            />
            {tilsyn.canSendInn && (
              <>
                <Spacer color="slate5" />
                <p>
                  Dokumentasjonen kan ikke endres eller slettes etter
                  innsending. Kontroller at dokumentasjon og kommentarer er som
                  ønsket før dere sender inn.
                </p>
                <p>
                  Bekreft at dokumentasjon er kontrollert før dere sender inn.
                </p>
                <CheckboxGroup
                  message="Må bekreftes før innsending"
                  error={showError && bekreftelseIsChecked === false}
                >
                  <Checkbox
                    label="Vi bekrefter at opplastet dokumentasjon og kommentarer er det vi ønsker å sende inn."
                    checked={bekreftelseIsChecked}
                    onChange={() =>
                      setBekreftelseIsChecked(!bekreftelseIsChecked)
                    }
                  ></Checkbox>
                </CheckboxGroup>
                <ButtonContainer>
                  <Button onClick={onSave}>Lagre uten å sende inn</Button>
                  <Button primary onClick={onSendInn}>
                    Lagre og send inn
                  </Button>
                </ButtonContainer>
              </>
            )}
          </Box>
        )}
        {!accessLevelLowerThan(
          tilsyn.tilgangType,
          AccessToTilsynType.ReadAndWriteUnObfuscated,
        ) && <RettTilAKlage snapshot={tilsyn.snapshot} />}
      </MainPanel>
      <SidePanel>
        <SidePanelContainer>
          <TilsynTidslinje tilsyn={tilsyn} />
        </SidePanelContainer>
      </SidePanel>
    </BackgroundContainer>
  );
};
