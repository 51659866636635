import * as React from "react";
import { FilterType } from "@utdanningsdirektoratet/searchwithsuggestions";
import { PublisertTilsynInfoResponse } from "ApiClients";
import { useGetAllPubliserteTilsyn } from "hooks/getters/tilsyn";

export const useFilteredTilsyns = (sessionKey: string) => {
  const filterData = (
    data: PublisertTilsynInfoResponse[],
    filter: FilterType<PublisertTilsynInfoResponse>,
  ): PublisertTilsynInfoResponse[] => {
    if (!(filter.filters && filter.filters.length > 0)) {
      return data;
    }

    const filtered = filter.filters.reduce<Array<PublisertTilsynInfoResponse>>(
      (prev, f) => {
        if (f?.isAnd) {
          return f.values.reduce<Array<PublisertTilsynInfoResponse>>(
            (innerprev, fv) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return innerprev.filter((d) =>
                Array.isArray(d[f.key])
                  ? (d[f.key] as any).includes(fv)
                  : false,
              );
            },
            prev,
          );
        }
        return prev.filter((d) => f?.values.includes(`${d[f.key]}`));
      },
      data,
    );
    return filtered;
  };

  const initialFilter = {
    search: "",
    filters: [],
  } as FilterType<PublisertTilsynInfoResponse>;
  const { data: tilsyns } = useGetAllPubliserteTilsyn();
  const [filteredTilsyns, setFilteredTilsyns] = React.useState(
    new Array<PublisertTilsynInfoResponse>(),
  );
  const [filter, setFilter] = React.useState(initialFilter);
  const [shouldInitialize, setShouldInitialize] = React.useState(true);

  React.useEffect(() => {
    if (!shouldInitialize) {
      return;
    }

    const sessionFilter = sessionStorage.getItem(sessionKey);
    if (!sessionFilter) {
      setShouldInitialize(false);
      return;
    }

    setFilter(JSON.parse(sessionFilter));
    setShouldInitialize(false);
  }, [sessionKey, shouldInitialize]);

  React.useEffect(() => {
    if (!tilsyns) {
      return;
    }
    const data = filterData(tilsyns, filter);
    setFilteredTilsyns(data);
    sessionStorage.setItem(sessionKey, JSON.stringify(filter));
  }, [tilsyns, filter]);

  return { tilsyns, filteredTilsyns, setFilteredTilsyns, filter, setFilter };
};
