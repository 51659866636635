import { RichTextView } from "@utdanningsdirektoratet/richtext/index";
import { formatDate } from "@utdanningsdirektoratet/utils/date/index";
import React from "react";
import styled from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";
import { DATE_FORMAT } from "SharedComponents/utils/date";
import ParagraphSmall from "components/ParagraphSmall";
import { InnsendingResponse, PubliseringMalType } from "ApiClients";
import { Vedlegg } from "@utdanningsdirektoratet/vedlegg/index";
import { Heading2 } from "SharedComponents/components/Headings/Headings";
import InnsendteDokumenter from "./InnsendteDokumenter";
import { useGetPubliseringDokumentasjonskravs } from "../../../hooks/getters/publiseringDokumentasjonkravs";
import { getUrls } from "../../../globalFunctions";

const Kommentar = styled.div`
  margin-top: 1rem;
  border-left: 2px solid ${colors.steelblue4};
  padding-left: 1rem;
`;

const Bold = styled.div`
  font-weight: 500;
`;

type IProps = {
  innsending: InnsendingResponse;
  publiseringMalType: PubliseringMalType;
  publiseringId: string;
  className?: string;
};

export const Innsending: React.FC<IProps> = ({
  innsending,
  publiseringMalType,
  publiseringId,
  className,
}) => {
  const { data: dokumentasjonskravs } =
    useGetPubliseringDokumentasjonskravs(publiseringId);
  const urls = getUrls();
  const dokumentasjonskravsWithSvar =
    dokumentasjonskravs &&
    dokumentasjonskravs.filter((krav) =>
      innsending.dokumentasjonskravSvarList.some(
        (svar) => svar.dokumentasjonskravId === krav.dokumentasjonskravId,
      ),
    );
  switch (publiseringMalType) {
    case PubliseringMalType.InnsendingAvDokumentasjon:
      return (
        <div className={className}>
          {dokumentasjonskravsWithSvar &&
            dokumentasjonskravsWithSvar.map((krav) => (
              <div key={krav.dokumentasjonskravExternalId}>
                <Heading2 className="u--marginBottom1">{krav.tittel}</Heading2>
                <RichTextView
                  className="u--marginBottom1"
                  html={krav.beskrivelse}
                />
                {innsending.dokumentasjonskravDokumentList
                  .filter(
                    (dokument) =>
                      dokument.dokumentasjonskravExternalId ===
                      krav.dokumentasjonskravExternalId,
                  )
                  .map((dokument) => (
                    <Vedlegg
                      key={`tidligeredokument-${dokument.externalId}`}
                      title={dokument.filnavn}
                      href={`${urls.downloadDokument}/${dokument.externalId}`}
                      disabled
                    />
                  ))}
                {innsending.dokumentasjonskravSvarList.find(
                  (svar) =>
                    svar.dokumentasjonskravId === krav.dokumentasjonskravId,
                )?.svartIkkeAktuelt && (
                  <div className="u--marginTop1">
                    <Bold>
                      Dere mener at dokumentasjonskravet er irrelevant
                    </Bold>
                    <ParagraphSmall>Redegjørelse:</ParagraphSmall>
                    <Kommentar className="u--marginBottom2">
                      <RichTextView
                        html={
                          innsending.dokumentasjonskravSvarList.find(
                            (svar) =>
                              svar.dokumentasjonskravId ===
                              krav.dokumentasjonskravId,
                          )?.kommentar ?? ""
                        }
                      />
                    </Kommentar>
                  </div>
                )}
              </div>
            ))}
          {innsending.kommentar && (
            <>
              <ParagraphSmall>Kommentar:</ParagraphSmall>
              <Kommentar>
                <RichTextView html={innsending.kommentar} />
              </Kommentar>
            </>
          )}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <ParagraphSmall>
            Sendt inn {formatDate(innsending.innsendtDateUtc!, DATE_FORMAT)}, av{" "}
            {innsending.innsendtAv}
          </ParagraphSmall>
        </div>
      );
    default:
      return (
        <div className={className}>
          {innsending.ingenMerknader ? (
            <Bold>Dere hadde ikke merknader</Bold>
          ) : (
            <div>
              <Bold>Dere hadde merknader</Bold>
              <InnsendteDokumenter innsending={innsending} />
              {innsending.kommentar && (
                <>
                  <ParagraphSmall>Kommentar:</ParagraphSmall>
                  <Kommentar>
                    <RichTextView html={innsending.kommentar} />
                  </Kommentar>
                </>
              )}
            </div>
          )}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <ParagraphSmall>
            Sendt inn {formatDate(innsending.innsendtDateUtc!, DATE_FORMAT)}, av{" "}
            {innsending.innsendtAv}
          </ParagraphSmall>
        </div>
      );
  }
};
