import React from "react";
import {
  RegelverkOgVurderingSnapshotResponse,
  SnapshotResponse,
} from "../../tekster/Types/Snapshot";
import { Tilsynstema } from "./Tilsynstema";

type IProps = {
  vurdering?: RegelverkOgVurderingSnapshotResponse;
  withoutObfuscationBackground?: boolean;
  isForelopigRapport: boolean;
  snapshot: SnapshotResponse;
};

export const Vurdering: React.FC<IProps> = ({
  vurdering,
  withoutObfuscationBackground,
  isForelopigRapport,
  snapshot,
}) => {
  if (!vurdering) return null;
  return (
    <>
      {vurdering.tilsynstemas.map((tema, i) => (
        <Tilsynstema
          tilsynstema={tema}
          key={`${tema.tittel}-${i}`}
          withoutObfuscationBackground={withoutObfuscationBackground}
          isForelopigRapport={isForelopigRapport}
          snapshot={snapshot}
        />
      ))}
    </>
  );
};
