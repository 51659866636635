import { MainPanel as MP } from "@utdanningsdirektoratet/layout";
import styled from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";

export const BackgroundContainer = styled.div`
  background-color: ${colors.offwhite};

  padding-bottom: 4rem;
  @media (max-width: 979px) {
    padding-bottom: 2rem;
  }
`;

export const MainPanel = styled(MP)`
  background-color: inherit;
  max-width: 80rem;
  margin: 0 auto;
  > * {
    margin-bottom: 32px;
  }
  > *:first-of-type {
    margin-top: 0;
  }
  > *:last-of-type {
    margin-bottom: 0;
  }
`;

export const SidePanelContainer = styled.div`
  background-color: ${colors.offwhite};
`;
