import React from "react";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { RedigerbarTekst } from "../../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
};

export const Folkehoyskoleloven: React.FC<IProps> = ({ snapshot }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <RedigerbarTekst
        navn="ForelopigRapportReaksjonerIngress"
        snapshot={snapshot}
      >
        <p>
          Styret er skolen sitt øvste organ, jf. folkehøgskolelova § 2 bokstav
          f. Vi gir derfor eventuelle pålegg til styret som har ansvar for at
          skolen rettar opp brot på regelverket.
        </p>
      </RedigerbarTekst>
    );
  }

  return (
    <RedigerbarTekst
      navn="ForelopigRapportReaksjonerIngress"
      snapshot={snapshot}
    >
      <p>
        Styret er skolens øverste organ, jf. folkehøyskoleloven § 2 bokstav f.
        Vi gir derfor eventuelle pålegg til styret som har ansvaret for at
        skolen retter opp brudd på regelverket.
      </p>
    </RedigerbarTekst>
  );
};
