import * as React from "react";
import { Link as UrcLink } from "@utdanningsdirektoratet/link";
import { generatePath, Link } from "react-router-dom";
import routes from "routes";
import {
  AccessToTilsynType,
  PubliseringMalType,
  PublisertTilsynResponse,
} from "ApiClients";
import { InnsendingAvDokumentasjonOverordnetIngress } from "SharedComponents/tekster/OverordnetIngress/InnsendingAvDokumentasjon/InnsendingAvDokumentasjonOverordnetIngress";
import { TilsynTittel } from "SharedComponents/TilsynTittel";
import { ForelopigRapportOverordnetIngress } from "SharedComponents/tekster/OverordnetIngress/ForelopigRapport/ForelopigRapportOverordnetIngress";
import { EndeligRapportOverordnetIngress } from "SharedComponents/tekster/OverordnetIngress/EndeligRapport/EndeligRapportOverordnetIngress";
import { resetHoursForDate } from "utils/dateUtils";
import { accessLevelLowerThan } from "utils/tilgang";
import { TitleHeaderBox } from "components/TitleHeaderBox";

type TilsynHeaderProps = {
  tilsyn: PublisertTilsynResponse;
  isInnsendingSide: boolean;
};

const getIngressForPubliseringstype = (tilsyn: PublisertTilsynResponse) => {
  const publisertDate = resetHoursForDate(tilsyn.aapentTilgjengeligFraDatoUtc);
  const noAccess =
    accessLevelLowerThan(
      tilsyn.tilgangType,
      AccessToTilsynType.ReadUnObfuscated,
    ) &&
    (new Date() < publisertDate ||
      tilsyn.publiseringMalType === PubliseringMalType.ForelopigTilsynsrapport);

  if (noAccess) return null;

  switch (tilsyn.publiseringMalType) {
    case PubliseringMalType.InnsendingAvDokumentasjon:
      return (
        <InnsendingAvDokumentasjonOverordnetIngress
          snapshot={tilsyn.snapshot}
        />
      );
    case PubliseringMalType.ForelopigTilsynsrapport:
      return <ForelopigRapportOverordnetIngress snapshot={tilsyn.snapshot} />;
    case PubliseringMalType.Tilsynsrapport:
      return (
        <EndeligRapportOverordnetIngress
          snapshot={tilsyn.snapshot}
          visBrudd={!tilsyn.publiseringVedtakIsEndret}
        />
      );
    default:
      return null;
  }
};

export const TilsynHeader: React.FC<TilsynHeaderProps> = ({
  tilsyn,
  isInnsendingSide,
}) => {
  const path = isInnsendingSide
    ? generatePath(routes.tilsyn, { tilsynId: tilsyn.tilsynId })
    : generatePath(routes.home);
  return (
    <TitleHeaderBox>
      <UrcLink
        iconLeft="angle left"
        text={isInnsendingSide ? "Tilbake til tilsynet" : "Alle tilsyn"}
      >
        {(props) => (
          <Link replace to={path} className={props.className}>
            {props.children}
          </Link>
        )}
      </UrcLink>
      <TilsynTittel snapshot={tilsyn.snapshot} />
      {getIngressForPubliseringstype(tilsyn)}
    </TitleHeaderBox>
  );
};
