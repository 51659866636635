import React from "react";
import { tilsynstemaCombiner } from "../../../utils/tilsynstemaCombiner";
import { SnapshotResponse } from "../../Types/Snapshot";

type IProps = {
  snapshot: SnapshotResponse;
  isForelopigRapport?: boolean;
  wrapInParagraph?: boolean;
};

export const RapportIngressInnledning: React.FC<IProps> = ({
  snapshot,
  isForelopigRapport,
  wrapInParagraph,
}) => {
  const tilsyntemas = tilsynstemaCombiner(snapshot, false);
  let content: string;
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    content = `${isForelopigRapport ? "Dette er ein førebels tilsynsrapport. " : ""}I denne rapporten presenterer vi våre ${
      isForelopigRapport ? "førebelse " : ""
    }vurderingar og konklusjonar i tilsynet med ${snapshot.omTilsynet?.tilsynForesMedNavn}. Tema for tilsynet er ${tilsyntemas}.`;
  } else {
    content = `${isForelopigRapport ? "Dette er en foreløpig rapport. " : ""}I denne rapporten presenterer vi våre ${
      isForelopigRapport ? "foreløpige " : ""
    }vurderinger og konklusjoner i tilsynet med ${snapshot.omTilsynet?.tilsynForesMedNavn}. Tema for tilsynet er ${tilsyntemas}.`;
  }
  return wrapInParagraph ? <p>{content}</p> : <span>{content}</span>;
};
