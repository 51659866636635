import React from "react";
import {
  LovEnumIdentifier,
  SnapshotResponse,
  TilsynsRapportComputedMetadata,
} from "../../Types/Snapshot";
import { Barnehageloven } from "./Barnehageloven";
import { Privatskoleloven } from "./Privatskoleloven";
import { Folkehoyskoleloven } from "./Folkehoyskoleloven";

type IProps = {
  snapshot: SnapshotResponse;
  tilsynsRapportMetadata?: TilsynsRapportComputedMetadata;
};

export const EndeligRapportInnledning: React.FC<IProps> = ({
  snapshot,
  tilsynsRapportMetadata,
}) => {
  if (
    snapshot.omTilsynet?.lovEnumIdentifier === LovEnumIdentifier.Barnehageloven
  ) {
    return (
      <Barnehageloven
        snapshot={snapshot}
        tilsynsRapportMetadata={tilsynsRapportMetadata}
      />
    );
  }
  if (
    snapshot.omTilsynet?.lovEnumIdentifier ===
    LovEnumIdentifier.Folkehoyskoleloven
  ) {
    return (
      <Folkehoyskoleloven
        snapshot={snapshot}
        tilsynsRapportMetadata={tilsynsRapportMetadata}
      />
    );
  }
  return (
    <Privatskoleloven
      snapshot={snapshot}
      tilsynsRapportMetadata={tilsynsRapportMetadata}
    />
  );
};
