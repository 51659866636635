import React from "react";
import styled from "styled-components";

const Chapter = styled.div`
  margin-bottom: 3rem;
`;

const RapportChapter: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return <Chapter>{children}</Chapter>;
};

export default RapportChapter;
