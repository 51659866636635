import React, { Dispatch, SetStateAction } from "react";
import { updateObjectInArray } from "SharedComponents/utils/arrayHelpers";
import {
  InnsendingStatus,
  PublisertTilsynResponse,
  SaveInnsendingDokumentasjonskravSvarInput,
} from "../../../ApiClients";
import { Dokumentasjonskrav } from "./Dokumentasjonskrav";
import { useGetPubliseringDokumentasjonskravs } from "../../../hooks/getters/publiseringDokumentasjonkravs";
import { useGetInnsendings } from "../../../hooks/getters/publiseringInnsendelse";

type IProps = {
  tilsyn: PublisertTilsynResponse;
  dokumentasjonskravSvars?: SaveInnsendingDokumentasjonskravSvarInput[];
  setDokumentasjonskravSvars: Dispatch<
    SetStateAction<SaveInnsendingDokumentasjonskravSvarInput[] | undefined>
  >;
  showError: boolean;
};

const Dokumentasjonskravs: React.FC<IProps> = ({
  tilsyn,
  dokumentasjonskravSvars,
  setDokumentasjonskravSvars,
  showError,
}) => {
  const { data: dokumentasjonskravs } = useGetPubliseringDokumentasjonskravs(
    tilsyn.publiseringId,
  );
  const { data: innsendings } = useGetInnsendings(tilsyn.publiseringId);
  const innsending = (innsendings ?? []).find(
    (i) => i.status === InnsendingStatus.UnderArbeid,
  );
  const innsendteInnsendinger = innsendings
    ?.filter((i) => i.status === InnsendingStatus.Innsendt)
    .filter((i) => i.innsendtDateUtc)
    .sort(
      (a, b) =>
        new Date(b.innsendtDateUtc!).getTime() -
        new Date(a.innsendtDateUtc!).getTime(),
    );

  const updateKommentarOnDokumentasjonskravSvar = (kravId: string) => {
    return (kommentar: string) => {
      if (dokumentasjonskravSvars) {
        const previousSvar = dokumentasjonskravSvars.find(
          (svar) => svar.dokumentasjonskravId === kravId,
        )!;
        const updatedArray = updateObjectInArray(
          { ...previousSvar, kommentar },
          dokumentasjonskravSvars,
          (item) => item.dokumentasjonskravId === kravId,
        );
        setDokumentasjonskravSvars(updatedArray);
      }
    };
  };

  const updateSvartIkkeAktueltOnDokumentasjonskravSvar = (kravId: string) => {
    return (svartIkkeAktuelt: boolean) => {
      if (dokumentasjonskravSvars) {
        const previousSvar = dokumentasjonskravSvars.find(
          (svar) => svar.dokumentasjonskravId === kravId,
        )!;
        const updatedArray = updateObjectInArray(
          { ...previousSvar, svartIkkeAktuelt },
          dokumentasjonskravSvars,
          (item) => item.dokumentasjonskravId === kravId,
        );
        setDokumentasjonskravSvars(updatedArray);
      }
    };
  };

  if (!tilsyn.canSendInn) {
    return null;
  }

  return (
    <div>
      {dokumentasjonskravs &&
        dokumentasjonskravSvars &&
        dokumentasjonskravs
          .filter((krav) => krav.isLocked === false)
          .map((krav) => {
            const svar = dokumentasjonskravSvars.find(
              (stateKrav) =>
                stateKrav.dokumentasjonskravId ===
                krav.dokumentasjonskravExternalId,
            );
            if (svar === undefined || svar.kommentar === undefined) return null;
            return (
              <Dokumentasjonskrav
                key={krav.dokumentasjonskravExternalId}
                krav={krav}
                innsending={innsending}
                innsendteInnsendinger={innsendteInnsendinger}
                kommentar={svar.kommentar}
                updateKommentar={updateKommentarOnDokumentasjonskravSvar(
                  krav.dokumentasjonskravExternalId,
                )}
                svartIkkeAktuelt={svar.svartIkkeAktuelt}
                updateSvartIkkeAktuelt={updateSvartIkkeAktueltOnDokumentasjonskravSvar(
                  krav.dokumentasjonskravExternalId,
                )}
                showError={showError}
                tilsyn={tilsyn}
              />
            );
          })}
    </div>
  );
};
export default Dokumentasjonskravs;
