import React from "react";
import { generatePath, Link } from "react-router-dom";
import { getText } from "SharedComponents/tekster/Overskrifts/Overskrifts";
import { Card } from "@utdanningsdirektoratet/card";
import {
  PubliseringMalType,
  PublisertTilsynResponse,
} from "../../../../ApiClients";
import routes from "../../../../routes";

type IProps = {
  tilsyn: PublisertTilsynResponse;
};

export const LenkeTilRapport: React.FC<IProps> = ({ tilsyn }) => {
  const headingPrefix = `Les ${
    tilsyn.publiseringMalType === PubliseringMalType.ForelopigTilsynsrapport
      ? getText(tilsyn.malform, (t) => t.tittelForelopigRapport).toLowerCase()
      : getText(tilsyn.malform, (t) => t.tittelEndeligRapport).toLowerCase()
  }`;

  return (
    <Card color="lightazure" title={headingPrefix}>
      {(props) => (
        <Link
          {...props}
          to={generatePath(routes.rapport, { tilsynId: tilsyn.tilsynId })}
        />
      )}
    </Card>
  );
};
