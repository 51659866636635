import React from "react";
import { SnapshotResponse } from "../../Types/Snapshot";
import { RedigerbarTekst } from "../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
};
export const IngenBrudd: React.FC<IProps> = ({ snapshot }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <RedigerbarTekst navn="HvaSkjerNaIngenBrudd" snapshot={snapshot}>
        <p>
          Vi har ikkje avdekka brot på regelverket og avsluttar med dette
          tilsynet.
        </p>

        <p>
          De har likevel høve til å uttale dykk om rapporten, både vår
          beskriving av fakta, vår forståing av lova, våre vurderingar og våre
          reaksjonar. Dersom de meiner rapporten inneheld feil, bør de grunngje
          kvifor. Vi vil vurdere om det er grunnlag for å endre innhaldet i
          rapporten etter ei eventuell utsegn frå dykk.
        </p>

        <p>
          De har rett til innsyn i saksdokumenta, jf. forvaltningslova § 18.
        </p>
      </RedigerbarTekst>
    );
  }

  return (
    <RedigerbarTekst navn="HvaSkjerNaIngenBrudd" snapshot={snapshot}>
      <p>
        Vi har ikke avdekket brudd på regelverket og avslutter derfor tilsynet.
      </p>

      <p>
        Dere har likevel anledning til å uttale dere om rapporten, både vår
        beskrivelse av fakta, vår forståelse av loven, våre vurderinger og våre
        reaksjoner. Dersom dere mener rapporten inneholder feil, bør dere
        begrunne hvorfor. Vi vil vurdere om det er grunnlag for å endre
        innholdet i rapporten etter en eventuell uttalelse fra dere.
      </p>

      <p>
        Dere har rett til innsyn i sakens dokumenter, jf. forvaltningsloven §
        18.
      </p>
    </RedigerbarTekst>
  );
};
