import React from "react";
import RapportBrudd from "SharedComponents/Rapport/RapportBrudd";
import { formatDate } from "SharedComponents/utils/date";
import { resetHoursForDate } from "utils/dateUtils";
import { Box } from "components/Box";
import {
  AccessToTilsynType,
  PublisertTilsynResponse,
} from "../../../ApiClients";
import { LenkeTilRapport } from "../components/LenkeTilRapport/LenkeTilRapport";
import { accessLevelLowerThan } from "../../../utils/tilgang";

type IProps = {
  tilsyn: PublisertTilsynResponse;
  isForelopigRapport: boolean;
};

export const ReadAccessTilsynsrapport: React.FC<IProps> = ({
  tilsyn,
  isForelopigRapport,
}) => {
  const publisertDate = resetHoursForDate(tilsyn.aapentTilgjengeligFraDatoUtc);
  if (
    accessLevelLowerThan(
      tilsyn.tilgangType,
      AccessToTilsynType.ReadUnObfuscated,
    ) &&
    isForelopigRapport
  ) {
    return (
      <p>Fra den 01.07.2024 blir ikke lenger foreløpige rapporter publisert.</p>
    );
  }
  if (
    accessLevelLowerThan(
      tilsyn.tilgangType,
      AccessToTilsynType.ReadUnObfuscated,
    ) &&
    new Date() < publisertDate
  ) {
    return (
      <p>
        Denne tilsynsrapporten blir tilgjengelig{" "}
        {formatDate(tilsyn.aapentTilgjengeligFraDatoUtc)}.
      </p>
    );
  }
  return (
    <>
      <Box $background="white" className="u--marginBottom2">
        <RapportBrudd
          snapshot={tilsyn.snapshot}
          withExpandable={false}
          isForelopigRapport={isForelopigRapport}
        />
      </Box>
      <LenkeTilRapport tilsyn={tilsyn} />
    </>
  );
};
