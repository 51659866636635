/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { formatDate, DATE_FORMAT } from "../../../utils/date";
import {
  SnapshotResponse,
  TilsynsRapportComputedMetadata,
} from "../../Types/Snapshot";

type IProps = {
  snapshot: SnapshotResponse;
  tilsynsRapportMetadata?: TilsynsRapportComputedMetadata;
};

export const OmUttalelsePaForelopigRapport: React.FC<IProps> = ({
  snapshot,
  tilsynsRapportMetadata,
}) => {
  if (!tilsynsRapportMetadata?.forelopigRapportSendtTilTilsynsobjektDatoUtc) {
    return null;
  }
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    const mottokMeldingTekst = tilsynsRapportMetadata.latestInnsendigHadMerknad
      ? ` Vi mottok tilbakemelding på den førebelse tilsynsrapporten frå dykk ${formatDate(
          tilsynsRapportMetadata.latestInnsendingInnsendtDateUtc!,
          DATE_FORMAT,
        )}.`
      : ` Vi mottok uttale frå dykk ${formatDate(
          tilsynsRapportMetadata.latestInnsendingInnsendtDateUtc!,
          DATE_FORMAT,
        )} om at dykk ikkje hadde nokre kommentarar til innhaldet i den førebelse tilsynsrapporten.`;

    const latestInnsendingTekst =
      tilsynsRapportMetadata?.latestInnsendingInnsendtDateUtc &&
      mottokMeldingTekst;

    const noInnsendingTekst =
      !tilsynsRapportMetadata?.latestInnsendingInnsendtDateUtc &&
      ` De hadde høve til å kommentere den førebelse rapporten med frist ${formatDate(
        tilsynsRapportMetadata?.latestForelopigRapportFristDateUtc ?? "",
        DATE_FORMAT,
      )}. Vi mottok ingen kommentarar frå dykk. Vurderingane og konklusjonane er derfor uendra frå den førebelse rapporten.`;

    return (
      <p>
        Vi sendte førebels rapport til dykk{" "}
        {formatDate(
          tilsynsRapportMetadata.forelopigRapportSendtTilTilsynsobjektDatoUtc,
          DATE_FORMAT,
        )}
        . I den presenterte vi våre førebelse vurderingar og konklusjonar.
        {latestInnsendingTekst}
        {noInnsendingTekst}
      </p>
    );
  }

  const mottokMeldingTekst = tilsynsRapportMetadata.latestInnsendigHadMerknad
    ? ` Vi mottok tilbakemelding på den foreløpige tilsynsrapporten fra dere ${formatDate(
        tilsynsRapportMetadata.latestInnsendingInnsendtDateUtc!,
        DATE_FORMAT,
      )}.`
    : ` Vi mottok melding fra dere ${formatDate(
        tilsynsRapportMetadata.latestInnsendingInnsendtDateUtc!,
        DATE_FORMAT,
      )} om at dere ikke hadde noen kommentarer til innholdet i den foreløpige tilsynsrapporten.`;

  const latestInnsendingTekst =
    tilsynsRapportMetadata?.latestInnsendingInnsendtDateUtc &&
    mottokMeldingTekst;

  const noInnsendingTekst =
    !tilsynsRapportMetadata?.latestInnsendingInnsendtDateUtc &&
    ` Dere hadde anledning til å kommentere den foreløpige rapporten med frist ${formatDate(
      tilsynsRapportMetadata.latestForelopigRapportFristDateUtc!,
      DATE_FORMAT,
    )}. Vi mottok ingen kommentarer fra dere. Vurderingene og konklusjonene er derfor uendret fra den foreløpige rapporten.`;

  return (
    <p>
      Vi sendte foreløpig rapport til dere{" "}
      {formatDate(
        tilsynsRapportMetadata?.forelopigRapportSendtTilTilsynsobjektDatoUtc,
        DATE_FORMAT,
      )}
      . I den presenterte vi våre foreløpige vurderinger og konklusjoner.
      {latestInnsendingTekst}
      {noInnsendingTekst}
    </p>
  );
};
