import { AccessToTilsynType, PublisertTilsynResponse } from "ApiClients";
import { Box } from "components/Box";
import React from "react";
import { formatDate } from "SharedComponents/utils/date";
import { accessLevelLowerThan } from "../../../utils/tilgang";
import { InnsendingAvDokumentasjonInfoBox } from "./InnsendingAvDokumentasjonInfoBox";

type InnsendingAvDokumentasjonProps = {
  tilsyn: PublisertTilsynResponse;
};

export const InnsendingAvDokumentasjon: React.FC<
  InnsendingAvDokumentasjonProps
> = ({ tilsyn }) => {
  const aapentTilgjengeligFraDato = new Date(
    new Date(tilsyn.aapentTilgjengeligFraDatoUtc).setHours(0, 0, 0, 0),
  );
  if (
    accessLevelLowerThan(
      tilsyn.tilgangType,
      AccessToTilsynType.ReadUnObfuscated,
    ) &&
    new Date() < aapentTilgjengeligFraDato
  ) {
    return (
      <Box $background="white">
        Dette tilsynet blir tilgjengelig{" "}
        {formatDate(tilsyn.aapentTilgjengeligFraDatoUtc)}.
      </Box>
    );
  }

  return <InnsendingAvDokumentasjonInfoBox tilsyn={tilsyn} />;
};
