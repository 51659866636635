import React from "react";
import { LovEnumIdentifier, SnapshotResponse } from "../../Types/Snapshot";
import { Barnehageloven } from "./Barnehageloven";
import { Privatskoleloven } from "./Privatskoleloven";
import { Folkehoyskoleloven } from "./Folkehoyskoleloven";

type IProps = {
  snapshot: SnapshotResponse;
};

export const ForelopigRapportInnledning: React.FC<IProps> = ({ snapshot }) => {
  if (
    snapshot.omTilsynet?.lovEnumIdentifier === LovEnumIdentifier.Barnehageloven
  ) {
    return <Barnehageloven snapshot={snapshot} />;
  }
  if (
    snapshot.omTilsynet?.lovEnumIdentifier ===
    LovEnumIdentifier.Folkehoyskoleloven
  ) {
    return <Folkehoyskoleloven snapshot={snapshot} />;
  }
  return <Privatskoleloven snapshot={snapshot} />;
};
