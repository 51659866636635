import React from "react";
import styled from "styled-components";
import { Vedlegg } from "@utdanningsdirektoratet/vedlegg/index";
import { getUrls } from "globalFunctions";
import { InnsendingResponse } from "ApiClients";

const VedleggWithDeleteButtonClose = styled(Vedlegg)`
  width: fit-content + 1rem;
`;

const DokumenterItem = styled.div`
  margin: 1rem 0;
`;

const Container = styled.div`
  margin: 1rem 0;
`;

type IProps = {
  innsending: InnsendingResponse;
};

const InnsendteDokumenter: React.FC<IProps> = ({ innsending }) => {
  const urls = getUrls();
  const dokumenter = innsending.dokumentList.map((d) => {
    return (
      <DokumenterItem key={`DokumentItem-${d.id}`}>
        <VedleggWithDeleteButtonClose
          key={`dokument-${d.id}`}
          title={d.filnavn}
          href={`${urls.downloadDokument}/${d.id}`}
          disabled
        />
      </DokumenterItem>
    );
  });

  return dokumenter.length > 0 ? <Container>{dokumenter}</Container> : null;
};

export default InnsendteDokumenter;
