import React from "react";
import { ContentPanel, MainPanel } from "@utdanningsdirektoratet/layout";
import { Heading1 } from "SharedComponents/components/Headings/Headings";
import styled from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";
import { unique } from "SharedComponents/utils/arrayHelpers";
import {
  FilterType,
  SearchDefinitionProps,
  SearchWithSuggestions,
  filterData,
} from "@utdanningsdirektoratet/searchwithsuggestions";
import { Column } from "@utdanningsdirektoratet/infinitytable";
import { useGetAllHistoricTilsynsrapports } from "../../hooks/getters/tilsyn";
import { HistoricRapportCard } from "./HistoricRapportCard";
import { HistoricTilsynsrapportsViewModel } from "../../ApiClients";

const ContentPanelStyled = styled(ContentPanel)`
  max-width: 50rem;
`;

const Header = styled.div`
  padding: 2rem;
  background: ${colors.green4};
`;

const TitleAndIngress = styled.div`
  max-width: 50rem;
  margin: 0 auto;
`;

const UList = styled.ul`
  padding: 0;
`;

const List = styled.li`
  list-style: none;
  margin-top: 1rem;
`;

const createSearchDefinition = (
  data: Array<HistoricTilsynsrapportsViewModel> | undefined,
): Array<SearchDefinitionProps<HistoricTilsynsrapportsViewModel>> => [
  {
    key: "aktornavn",
    title: "Navn",
    filterFunction: (filter: string, value: string) =>
      value.toLowerCase().includes(filter.toLowerCase()),
    maxResults: 8,
    options: unique(
      (data ?? []).map((d) => d.aktornavn),
      (curr, other) => curr === other,
    ).map((navn) => ({ id: navn, value: navn })),
  },
  {
    key: "tilsynstema",
    title: "Tilsynstema",
    filterFunction: (filter: string, value: string) =>
      value.toLowerCase().includes(filter.toLowerCase()),
    maxResults: 8,
    options: unique(
      (data ?? []).map((rapport) => rapport.tilsynstema),
      (curr, other) => curr === other,
    )
      .filter((r) => !!r)
      .map((navn) => ({ id: navn!, value: navn! })),
  },
  {
    key: "fylke",
    title: "Fylke",
    filterFunction: (filter: string, value: string) =>
      value.toLowerCase().includes(filter.toLowerCase()),
    maxResults: 8,
    options: unique(
      (data ?? []).map((d) => d.fylke),
      (curr, other) => curr === other,
    )
      .filter((r) => !!r)
      .map((navn) => ({ id: navn!, value: navn! })),
  },
];

const columns: Array<Column<HistoricTilsynsrapportsViewModel>> = [
  {
    key: "aktornavn",
  },
  {
    key: "fylke",
  },
  {
    key: "tilsynstema",
  },
];

export const HistoricTilsynsrapports: React.FC = () => {
  const { data: tilsynsrapports } = useGetAllHistoricTilsynsrapports();
  const [filter, setFilter] = React.useState<
    FilterType<HistoricTilsynsrapportsViewModel>
  >({ filters: [], search: "" });
  const filteredRapports = filter
    ? filterData(tilsynsrapports ?? [], filter, columns)
    : tilsynsrapports;

  return (
    <MainPanel color="white">
      <Header>
        <TitleAndIngress>
          <Heading1>Tilsynsrapporter for private skoler</Heading1>
          <p>Her finner du våre tilsynsrapporter som ble startet før 2022.</p>
        </TitleAndIngress>
      </Header>
      <ContentPanelStyled>
        <SearchWithSuggestions
          searchDefinition={createSearchDefinition(tilsynsrapports) ?? []}
          onFilterChange={setFilter}
          filter={filter}
          escapeWithEnter
          showFilterRow
        />
        <UList>
          {filteredRapports?.map((rapport) => (
            <List>
              <HistoricRapportCard rapport={rapport} />
            </List>
          ))}
        </UList>
      </ContentPanelStyled>
    </MainPanel>
  );
};
