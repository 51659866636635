import React from "react";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { Heading3 } from "../../../../components/Headings/Headings";

type IProps = {
  snapshot: SnapshotResponse;
  heading: string;
};

export const Folkehoyskoleloven: React.FC<IProps> = ({ snapshot, heading }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <>
        <Heading3>{heading}</Heading3>
        <p>
          Vi pålegg dykk å betale tilbake tilskot, jf. folkehøgskolelova § 3
          tredje ledd. De kan ikkje bruke tilskot og skolepengar til å dekke
          kravet fordi det vil innebere at midlane ikkje kjem elevane til gode,
          jf. folkehøgskolelova § 4 a.
        </p>
      </>
    );
  }
  return (
    <>
      <Heading3>{heading}</Heading3>
      <p>
        Vi pålegger dere å betale tilbake tilskudd, jf. folkehøyskoleloven § 3
        tredje ledd. Dere kan ikke bruke tilskudd og skolepenger til å dekke
        kravet fordi det vil innebære at midlene ikke kommer elevene til gode,
        jf. folkehøyskoleloven § 4 a.
      </p>
    </>
  );
};
