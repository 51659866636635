import colors from "@utdanningsdirektoratet/styles/colors";
import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: ${colors.white};
`;

const HeaderBox = styled.div`
  max-width: 80rem;
  padding: 1rem 2rem 2rem;
  margin: 0 auto;

  h1 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
`;

type TitleHeaderBoxProps = {
  children: React.ReactNode[];
};

export const TitleHeaderBox: React.FC<TitleHeaderBoxProps> = ({ children }) => {
  return (
    <Container>
      <HeaderBox>{children}</HeaderBox>
    </Container>
  );
};
