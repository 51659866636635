import React from "react";
import styled from "styled-components";
import { MainPanel as MP } from "@utdanningsdirektoratet/layout";
import colors from "@utdanningsdirektoratet/styles/colors";
import { RichText as RT } from "@utdanningsdirektoratet/richtext";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { mutate } from "swr";
import { Link as UrcLink } from "@utdanningsdirektoratet/link";
import { generatePath, Link } from "react-router-dom";
import { PageTitle } from "SharedComponents/components/Headings/Headings";
import { Button } from "@utdanningsdirektoratet/button";
import routes from "routes";
import { useSurroundWithErrorHandling } from "utils/errorhandler";
import { getConfig } from "globalFunctions";
import { innsendingsRefreshKey } from "hooks/getters/publiseringInnsendelse";
import {
  InnsendingResponse,
  PublisertTilsynResponse,
  SaveInnsendingClient,
  SendInnInnsendingClient,
} from "ApiClients";
import { hide, show } from "@utdanningsdirektoratet/slowaction";
import { ErklaeringOgRedegjoreInnsendingIngress } from "SharedComponents/tekster/InnsendingIngress/ErklaeringOgRedegjorelse/ErklaeringOgRedegjoreInnsendingIngress";
import { usePrompt } from "@utdanningsdirektoratet/prompt";
import { InnsendingDokumenter } from "../Innsendings/InnsendingDokumenter";
import { publiseringTilsynRefreshKey } from "../../../hooks/getters/tilsyn";

type IProps = {
  tilsyn: PublisertTilsynResponse;
  erkleringOmRetting?: InnsendingResponse;
};

const MainPanel = styled(MP)`
  background: ${colors.white};
`;

const RichText = styled(RT)`
  margin-bottom: 2rem;
  min-height: 15rem;
`;

const saveInnsendingClient = new SaveInnsendingClient();
const sendInnClient = new SendInnInnsendingClient();
export const SendInnErkleringOmRetting: React.FC<IProps> = ({
  tilsyn,
  erkleringOmRetting,
}) => {
  const license = getConfig().froalaLicense;
  const [state, setState] = React.useState({
    kommentar: erkleringOmRetting?.kommentar ?? "",
    hasChange: false,
  });
  const [error, setError] = React.useState(false);

  usePrompt(
    state.hasChange,
    "Du har ulagrede endringer, er du sikker på at du vil lukke siden?",
    true,
  );

  React.useEffect(() => {
    setError(false);
  }, [erkleringOmRetting]);

  const storeErkleringOmRettingWithSendingIn = useSurroundWithErrorHandling(
    async () => {
      if (
        state.kommentar ||
        (erkleringOmRetting && erkleringOmRetting.dokumentList.length > 0)
      ) {
        await saveInnsendingClient.save({
          publiseringId: tilsyn.publiseringId,
          kommentar: state.kommentar,
        });
        setState((prevState) => ({ ...prevState, hasChange: false }));
        await show();
        await mutate(innsendingsRefreshKey(tilsyn.publiseringId));
        await mutate(publiseringTilsynRefreshKey(tilsyn.tilsynId));
        await hide();
        await newNotification(
          "success",
          "Erklæring om retting er lagret, men ikke sendt inn",
        );
      } else {
        setError(true);
      }
    },
  );

  const sendInnErkleringOmRetting = useSurroundWithErrorHandling(async () => {
    if (
      state.kommentar ||
      (erkleringOmRetting && erkleringOmRetting.dokumentList.length > 0)
    ) {
      await sendInnClient.sendInn({
        publiseringId: tilsyn.publiseringId,
        kommentar: state.kommentar,
      });
      setState((prevState) => ({ ...prevState, hasChange: false }));
      await show();
      await mutate(publiseringTilsynRefreshKey(tilsyn.tilsynId));
      await mutate(innsendingsRefreshKey(tilsyn.publiseringId));
      await hide();
      await newNotification("success", "Erklæring om retting er sendt inn");
    } else {
      setError(true);
    }
  });

  React.useEffect(() => {
    if (
      erkleringOmRetting &&
      erkleringOmRetting.kommentar &&
      state.kommentar === "" &&
      !state.hasChange
    ) {
      setState({
        kommentar: erkleringOmRetting.kommentar,
        hasChange: false,
      });
    }
  }, [erkleringOmRetting, state.kommentar, state.hasChange]);

  return (
    <MainPanel>
      {tilsyn.canSendInn ? (
        <>
          <div className="u--marginBottom2">
            <UrcLink iconLeft="angle left" text="Tilbake til rapporten">
              {(props) => (
                <Link
                  to={generatePath(routes.rapport, {
                    tilsynId: tilsyn.tilsynId,
                  })}
                  className={props.className}
                >
                  {props.children}
                </Link>
              )}
            </UrcLink>
          </div>
          <PageTitle className="u--marginBottom2">Innsending</PageTitle>
          <ErklaeringOgRedegjoreInnsendingIngress
            snapshot={tilsyn.snapshot}
            innsendingFristDatoUtc={tilsyn.innsendingFristDatoUtc}
          />
          <p className="u--marginBottom1">
            Skriv i tekstboksen eller legg ved dokument.
          </p>
          <RichText
            error={error}
            message="Det må enten være tekst her eller lastet opp dokumenter for å kunne lagre med merknad."
            config={{ heightMin: 200 }}
            license={license}
            onBlur={() => setError(false)}
            onFocus={() => setError(false)}
            onChange={(change) =>
              setState({ kommentar: change, hasChange: true })
            }
            value={state.kommentar}
          />
          <InnsendingDokumenter
            publiseringId={tilsyn.publiseringId}
            innsending={erkleringOmRetting}
            error={error}
          />
          <Button onClick={storeErkleringOmRettingWithSendingIn}>
            Lagre uten å sende inn
          </Button>
          <Button onClick={sendInnErkleringOmRetting}>Lagre og send inn</Button>
        </>
      ) : null}
    </MainPanel>
  );
};
