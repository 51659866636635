import {
  usePageViewTracking,
  pageAccessDimension,
  Dimension,
} from "@utdanningsdirektoratet/hooks/usePageViewTracking";
import { AccessToTilsynType, PublisertTilsynResponse } from "../ApiClients";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router-dom";

const getReadableRole = (role: AccessToTilsynType | undefined) => {
  if (!AccessToTilsynType) {
    return undefined;
  }
  if (role == AccessToTilsynType.ReadObfuscatedOnly) {
    return "Lesetilgang sladdet rapport";
  }
  if (role == AccessToTilsynType.ReadUnObfuscated) {
    return "Lesetilgang full rapport";
  }
  if (role == AccessToTilsynType.ReadAndWriteUnObfuscated) {
    return "Lese og skrivetilgang";
  }
  return "Ukjent";
};

export const usePageViewTrackingWhenTilsynLoaded = (
  tilsyn: PublisertTilsynResponse | undefined,
) => {
  let pageViewAccess: string | undefined;
  if (tilsyn) {
    pageViewAccess = getReadableRole(tilsyn.tilgangType);
  }
  usePageViewTracking([pageAccessDimension(pageViewAccess)]);
};

export const usePageViewTrackingExceptForRoutes = (
  routesToIgnore: string[],
) => {
  const location = useLocation();
  let dimensions: Array<Dimension> = [];
  if (routesToIgnore.some((route) => matchPath(route, location.pathname))) {
    dimensions = [pageAccessDimension(undefined)];
  } else {
    dimensions = [pageAccessDimension("")];
  }
  usePageViewTracking(dimensions);
};
