import { isValid, format, parseISO } from "date-fns";
import { nb } from "date-fns/locale";

export const DATE_FORMAT = "d. MMMM yyyy";

export const DATE_FORMAT_PRECISE = "d. MMMM yyyy H:mm";

export const formatDate = (
  date: Date | string,
  formatString = DATE_FORMAT,
  locale = nb,
): string => {
  let target;
  if (typeof date === "string") target = parseISO(date);
  else target = date;

  if (!isValid(target)) {
    return "Not a valid date";
  }
  return format(target, formatString, { locale });
};

type SortOrder = "asc" | "dsc";

export const sortDate = (
  a: Date,
  b: Date,
  sortOrder: SortOrder = "asc",
): number => {
  if (sortOrder === "asc") {
    if (a > b) return 1;
    if (a === b) return 0;
    return -1;
  }
  if (a > b) return -1;
  return 1;
};
