import React from "react";
import {
  ConfigBuilder,
  RichTextView,
} from "@utdanningsdirektoratet/richtext/index";
import { Kontrollsporsmal } from "./Kontrollsporsmal";
import { Heading4 } from "../../components/Headings/Headings";
import {
  RegelverkOgVurderingPaleggSnapshotResponse,
  SnapshotResponse,
} from "../../tekster/Types/Snapshot";

type IProps = {
  palegg: RegelverkOgVurderingPaleggSnapshotResponse;
  withoutObfuscationBackground?: boolean;
  isForelopigRapport: boolean;
  snapshot: SnapshotResponse;
};

export const Palegg: React.FC<IProps> = ({
  palegg,
  withoutObfuscationBackground,
  isForelopigRapport,
  snapshot,
}) => {
  const { kontrollsporsmals } = palegg;

  const config = new ConfigBuilder().withImages().build();

  return (
    <>
      <Heading4>
        <i>{`${palegg.tittel}, jf. ${palegg.hjemmel}.`}</i>
      </Heading4>
      <RichTextView
        className="u--marginBottom1"
        config={config}
        html={palegg.rettsligGrunnlag ?? ""}
      />
      {kontrollsporsmals.map((kp, i) => (
        <Kontrollsporsmal
          key={`${kp.sporsmalTekst}-${i}`}
          kontrollsporsmal={kp}
          withoutObfuscationBackground={withoutObfuscationBackground}
          isForelopigRapport={isForelopigRapport}
          snapshot={snapshot}
        />
      ))}
    </>
  );
};
