/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PageTitle as PT,
  Heading1 as H1,
  Heading2 as H2,
  Heading3 as H3,
  Heading4 as H4,
} from "@utdanningsdirektoratet/styles/Headings";
import styled from "styled-components";

export const Title = styled(H1 as any)`
  margin: 2rem 0;
  font-size: 2rem;
`;

export const PageTitle = styled(PT as any)`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

export const Heading1 = styled(H1 as any)`
  margin-bottom: 1.5rem;
`;

export const Heading2 = styled(H2 as any)`
  margin-bottom: 1rem;
`;

export const Heading3 = styled(H3 as any)`
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
`;

export const Heading4 = styled(H4 as any)`
  margin-bottom: 1rem;
`;

export const Paragraph = styled.p`
  margin: 0.5rem 0;
`;
