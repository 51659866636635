import React from "react";
import { useContextState } from "StateProvider";
import { ApiException } from "ApiClients";
import { SET_ERROR } from "reducers";

const NoMatchingPathShowDefault404: React.FC = () => {
  const { dispatch } = useContextState();

  React.useEffect(() => {
    dispatch({
      type: SET_ERROR,
      payload: new ApiException("Not found", 404, "", {}, null),
    });
  }, [dispatch]);

  // Dispatch of setError will render a new page or redirect you, so it does not matter what we render here
  return null;
};

export default NoMatchingPathShowDefault404;
