import React from "react";
import { tilsynstemaCombiner } from "../../../utils/tilsynstemaCombiner";
import { SnapshotResponse } from "../../Types/Snapshot";

type IProps = {
  snapshot: SnapshotResponse;
};

export const InnsendingAvDokumentasjonOverordnetIngress: React.FC<IProps> = ({
  snapshot,
}) => {
  return (
    <p>
      Vi fører tilsyn med {snapshot.omTilsynet?.tilsynForesMedNavn}. Temaet for
      tilsynet er {tilsynstemaCombiner(snapshot, false)}.
    </p>
  );
};
