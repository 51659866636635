import React from "react";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { RedigerbarTekst } from "../../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
};

export const Privatskoleloven: React.FC<IProps> = ({ snapshot }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <RedigerbarTekst
        navn="ForelopigRapportReaksjonerIngress"
        snapshot={snapshot}
      >
        <p>
          Styret er skolen sitt øvste organ, jf. privatskolelova § 5-2. Vi gir
          derfor eventuelle pålegg til styret som har ansvar for at skolen
          rettar opp brot på regelverket.
        </p>
      </RedigerbarTekst>
    );
  }

  return (
    <RedigerbarTekst
      navn="ForelopigRapportReaksjonerIngress"
      snapshot={snapshot}
    >
      <p>
        Styret er skolens øverste organ, jf. privatskoleloven § 5-2. Vi gir
        derfor eventuelle pålegg til styret som har ansvaret for at skolen
        retter opp brudd på regelverket.
      </p>
    </RedigerbarTekst>
  );
};
