import {
  GetHistoricTilsynsrapportsClient,
  GetPublisertTilsynClient,
  HistoricTilsynsrapportsViewModel,
  PublisertTilsynInfoResponse,
  PublisertTilsynResponse,
} from "ApiClients";
import useSwrWithErrorHandling from "hooks/useSwrWithErrorHandling";
import { IResponseWithReset } from "@utdanningsdirektoratet/hooks";

const getPublisertTilsynClient = new GetPublisertTilsynClient();
const getHistoricTilsynsrapportClient = new GetHistoricTilsynsrapportsClient();

export const publiseringTilsynRefreshKey = (tilsynId: string): string =>
  `tilsyn/${tilsynId}`;
export const publiseringAllTilsynRefreshKey = (): string => "tilsyn";

export const allHistoricRapportsRefreshKey = (): string =>
  "historic-tilsynsrapports";

export const useGetPublisertTilsyn = (
  tilsynId: string,
): IResponseWithReset<PublisertTilsynResponse> => {
  return useSwrWithErrorHandling(publiseringTilsynRefreshKey(tilsynId), () =>
    getPublisertTilsynClient.getPublisertTilsyn(tilsynId),
  );
};

export const useGetAllPubliserteTilsyn = (): IResponseWithReset<
  PublisertTilsynInfoResponse[]
> => {
  return useSwrWithErrorHandling(publiseringAllTilsynRefreshKey(), () =>
    getPublisertTilsynClient.getAllPubliserteTilsyns(),
  );
};

export const useGetAllHistoricTilsynsrapports = (): IResponseWithReset<
  HistoricTilsynsrapportsViewModel[]
> => {
  return useSwrWithErrorHandling(allHistoricRapportsRefreshKey(), () =>
    getHistoricTilsynsrapportClient.getAll(),
  );
};
