import React from "react";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { Heading3 } from "../../../../components/Headings/Headings";

type IProps = {
  snapshot: SnapshotResponse;
  heading: string;
};

export const Privatskoleloven: React.FC<IProps> = ({ snapshot, heading }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <>
        <Heading3>{heading}</Heading3>
        <p>
          Vi varslar at de må rette opp følgande, jf. privatskolelova § 7-2 a
          første ledd:
        </p>
      </>
    );
  }

  return (
    <>
      <Heading3>{heading}</Heading3>
      <p>
        Vi varsler at dere må rette opp følgende, jf. privatskoleloven § 7-2 a
        første ledd:
      </p>
    </>
  );
};
