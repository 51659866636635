import React from "react";
import { SnapshotResponse } from "../../Types/Snapshot";
import { DATE_FORMAT, formatDate } from "../../../utils/date";
import { RedigerbarTekst } from "../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
};

export const Barnehageloven: React.FC<IProps> = ({ snapshot }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <RedigerbarTekst navn="ForelopigRapportInnledning" snapshot={snapshot}>
        <p>
          Vi fører tilsyn med bruk av offentlege tilskot og foreldrebetaling i
          private barnehagar som mottar kommunalt tilskot etter barnehagelova §
          19.
        </p>
        <p>
          I tilsyn kontrollerer vi om barnehagane oppfyller krava til bruken av
          offentlege tilskot og foreldrebetaling etter barnehagelova kapittel V
          med forskrift.
        </p>
        <p>
          Våre tilsyn er offentleg myndigheitsutøving, noko som inneber at
          tilsynet skal gjennomførast i samsvar med reglane i forvaltningsretten
          og offentleglova. I tilsynet behandlar vi personopplysningar. Les meir
          om vår behandling av personopplysningar på{" "}
          <a
            target="__blank"
            href="https://www.udir.no/regelverk-og-tilsyn/personvern-for-barnehage-og-skole/behandling-av-personopplysninger-i-tilsyn"
          >
            https://www.udir.no/regelverk-og-tilsyn/personvern-for-barnehage-og-skole/behandling-av-personopplysninger-i-tilsyn
          </a>
        </p>
        <p>
          Vi opna tilsyn med {snapshot.omTilsynet.tilsynForesMedNavn}{" "}
          {snapshot.omTilsynet.startetDatoUtc
            ? formatDate(
                snapshot.omTilsynet.startetDatoUtc.toString(),
                DATE_FORMAT,
              )
            : ""}
          . Vi har fått tilstrekkeleg informasjon til å gjennomføre tilsynet.
        </p>
      </RedigerbarTekst>
    );
  }
  return (
    <RedigerbarTekst navn="ForelopigRapportInnledning" snapshot={snapshot}>
      <p>
        Vi fører tilsyn med bruk av offentlige tilskudd og foreldrebetaling i
        private barnehager som mottar kommunalt tilskudd etter barnehageloven §
        19.
      </p>
      <p>
        I tilsyn kontrollerer vi om barnehagene oppfyller kravene til bruken av
        offentlige tilskudd og foreldrebetaling etter barnehageloven kapittel V
        med forskrift.
      </p>
      <p>
        Våre tilsyn er offentlig myndighetsutøvelse, noe som innebærer at
        tilsynet skal gjennomføres i samsvar med reglene i forvaltningsretten og
        offentlighetsloven. I tilsynet behandler vi personopplysninger. Les mer
        om vår behandling av personopplysninger på{" "}
        <a
          target="__blank"
          href="https://www.udir.no/regelverk-og-tilsyn/personvern-for-barnehage-og-skole/behandling-av-personopplysninger-i-tilsyn/"
        >
          https://www.udir.no/regelverk-og-tilsyn/personvern-for-barnehage-og-skole/behandling-av-personopplysninger-i-tilsyn/
        </a>
      </p>
      <p>
        Vi åpnet tilsyn med {snapshot.omTilsynet?.tilsynForesMedNavn}{" "}
        {snapshot.omTilsynet?.startetDatoUtc
          ? formatDate(
              snapshot.omTilsynet?.startetDatoUtc.toString(),
              DATE_FORMAT,
            )
          : ""}
        . Vi har fått tilstrekkelig informasjon til å gjennomføre tilsynet.
      </p>
    </RedigerbarTekst>
  );
};
