import * as React from "react";
import {
  SearchWithSuggestions,
  FilterType,
  SearchDefinitionProps,
} from "@utdanningsdirektoratet/searchwithsuggestions";
import {
  OversiktFilterClient,
  OversiktFiltersResponse,
  PublisertTilsynInfoResponse,
} from "ApiClients";
import { unique } from "SharedComponents/utils/arrayHelpers";
import useSwrWithErrorHandling from "hooks/useSwrWithErrorHandling";
import styled from "styled-components";

const Wrapper = styled.div`
  flex-grow: 1;
  min-width: 20rem;
`;

const createSearchDefinition = (
  globalFilters: OversiktFiltersResponse,
  data: PublisertTilsynInfoResponse[],
): SearchDefinitionProps<PublisertTilsynInfoResponse>[] => [
  {
    key: "tilsynsObjektNavn",
    title: "Skole eller barnehage",
    filterFunction: (filter: string, value: string) =>
      value.toLowerCase().includes(filter.toLowerCase()),
    maxResults: 8,
    options: unique(
      data.map((d) => d.tilsynsObjektNavn),
      (curr, other) => curr === other,
    ).map((tilsynsObjekt) => ({ id: tilsynsObjekt, value: tilsynsObjekt })),
  },
  {
    key: "kommune",
    title: "Kommune",
    filterFunction: (filter: string, value: string) =>
      value.toLowerCase().includes(filter.toLowerCase()),
    maxResults: 8,
    options: globalFilters.kommuner.map((filter) => ({
      id: filter.id,
      value: filter.name,
    })),
  },
  {
    key: "fylke",
    title: "Fylke",
    filterFunction: (filter: string, value: string) =>
      value.toLowerCase().includes(filter.toLowerCase()),
    maxResults: 8,
    options: globalFilters.fylker.map((filter) => ({
      id: filter.id,
      value: filter.name,
    })),
  },
];

const filtersClient = new OversiktFilterClient();

type SearchProps = {
  tilsyns?: PublisertTilsynInfoResponse[];
  filter: FilterType<PublisertTilsynInfoResponse>;
  setFilter: (newFilter: FilterType<PublisertTilsynInfoResponse>) => void;
};

export const Search: React.FC<SearchProps> = ({
  tilsyns,
  filter,
  setFilter,
}) => {
  const { data: filters } = useSwrWithErrorHandling("oversiktFilters", () =>
    filtersClient.getFilters(),
  );
  return filters && tilsyns ? (
    <Wrapper>
      <SearchWithSuggestions
        searchDefinition={createSearchDefinition(filters, tilsyns) ?? []}
        onFilterChange={setFilter}
        filter={filter}
        placeholderTitle="Søk på skole, barnehage, kommune eller fylke"
        escapeWithEnter
        showFilterRow
      />
    </Wrapper>
  ) : null;
};
