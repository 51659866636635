import React from "react";
import styled from "styled-components";
import { PaleggOmRettingKorreksjonspunktSnapshotResponse } from "../../../Types/Snapshot";

const Korreksjonspunkt = styled.div`
  display: flex;
`;

const IndentedSpan = styled.span`
  display: block;
  margin-left: 2rem;
  margin-right: 0.5rem;
`;

type ReaksjonKorreksjonspunktProps = {
  korreksjonspunkt: PaleggOmRettingKorreksjonspunktSnapshotResponse;
};

const ReaksjonKorreksjonspunkt: React.FC<ReaksjonKorreksjonspunktProps> = ({
  korreksjonspunkt,
}) => {
  return (
    <Korreksjonspunkt>
      <IndentedSpan>{korreksjonspunkt.orderLetter}.</IndentedSpan>
      <span>{korreksjonspunkt.korreksjonspunktTekst}</span>
    </Korreksjonspunkt>
  );
};

export default ReaksjonKorreksjonspunkt;
