export const CreateCommaSeparatedList = (texts: string[]) => {
  if (texts.length === 0) {
    return "";
  }
  return texts.length <= 1
    ? texts[0]
    : `${texts.slice(0, -1).join(", ")} og ${texts.slice(-1)}`;
};

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export const deCapitalize = (tekst: string) =>
  `${tekst.split("")[0].toLocaleLowerCase()}${tekst.split("").slice(1).join("")}`;
