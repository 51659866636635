import React from "react";
import styled from "styled-components";
import { RichText as RT } from "@utdanningsdirektoratet/richtext";
import { Heading3 } from "SharedComponents/components/Headings/Headings";
import {
  InnsendingResponse,
  PublisertTilsynResponse,
} from "../../../ApiClients";
import { TidligereKommentarer } from "./TidligereKommentarer";

type IProps = {
  license: string;
  onChange: (value: ((prevState: string) => string) | string) => void;
  value: string;
  innsendinger?: InnsendingResponse[];
  tilsyn: PublisertTilsynResponse;
};

const RichText = styled(RT)`
  margin-bottom: 2rem;
  min-height: 15rem;
`;

export const GenerellKommentar: React.FC<IProps> = ({
  license,
  onChange,
  value,
  innsendinger,
  tilsyn,
}) => {
  return (
    <>
      <TidligereKommentarer
        innsendinger={innsendinger}
        canSendIn={tilsyn.canSendInn}
      />
      {tilsyn.canSendInn && (
        <>
          <Heading3>
            <b>Generell kommentar</b>
          </Heading3>
          <p>
            Dere kan gi oss en generell kommentar til dokumentasjonen. Det er
            valgfritt.
          </p>
          <RichText
            label="Kommentar"
            config={{ heightMin: 200 }}
            license={license}
            onChange={onChange}
            value={value}
            icon="warning"
          />
        </>
      )}
    </>
  );
};
