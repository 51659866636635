import React from "react";
import {
  LovEnumIdentifier,
  OversiktFilterClient,
  PublisertTilsynInfoResponse,
} from "ApiClients";
import { PlaceholderBuilder } from "components/PlaceholderBuilder";
import styled from "styled-components";
import { OversiktCardList } from "../OversiktCardList";
import { Search } from "../Search";
import { useFilteredTilsyns } from "../useFilteredTilsyns";
import useSwrWithErrorHandling from "../../../hooks/useSwrWithErrorHandling";
import { TilsynstemaSearch } from "../TilsynstemaSearch";
import { scrollKeys, tilsynFilterKeys, tilsynstemaKeys } from "../sessionKeys";

const filtersClient = new OversiktFilterClient();

const SearchWrapper = styled.div`
  display: flex;
  column-gap: 2rem;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  margin-top: 2rem;
`;
export const OversiktBarnehager: React.FC = () => {
  const { tilsyns, filteredTilsyns, filter, setFilter } = useFilteredTilsyns(
    tilsynFilterKeys.barnehage,
  );
  const [barnehageTilsyns, setBarnehageTilsyns] = React.useState(
    new Array<PublisertTilsynInfoResponse>(),
  );
  const [tilsynTemaFilter, setTilsynTemaFilter] = React.useState<string>();
  const { data: filters } = useSwrWithErrorHandling("oversiktFilters", () =>
    filtersClient.getFilters(),
  );
  const tilsyntemas =
    filters?.barnehageTilsynstemas?.map((tema) => ({
      value: tema.id,
      text: tema.name,
    })) ?? [];

  React.useEffect(() => {
    if (!filteredTilsyns) {
      return;
    }
    const filtered = filteredTilsyns.filter(
      (t) => t.lovIdentifier === LovEnumIdentifier.Barnehageloven,
    );
    if (tilsynTemaFilter) {
      setBarnehageTilsyns(
        filtered.filter((t) => t.tilsynstemas.includes(tilsynTemaFilter)),
      );
    } else {
      setBarnehageTilsyns(filtered);
    }
  }, [filteredTilsyns, tilsynTemaFilter]);

  React.useEffect(() => {
    const savedTilsynTema = sessionStorage.getItem(tilsynstemaKeys.barnehage);

    if (savedTilsynTema) {
      setTilsynTemaFilter(savedTilsynTema);
    }
  }, []);

  if (!tilsyns) {
    return <PlaceholderBuilder />;
  }

  return (
    <>
      <SearchWrapper>
        <Search tilsyns={tilsyns} filter={filter} setFilter={setFilter} />
        <TilsynstemaSearch
          options={tilsyntemas}
          value={tilsynTemaFilter}
          onChange={setTilsynTemaFilter}
          sessionKey={tilsynstemaKeys.barnehage}
        />
      </SearchWrapper>
      <div className="u--marginTop2">
        <OversiktCardList
          tilsyns={barnehageTilsyns}
          scrollKey={scrollKeys.barnehage}
        />
      </div>
    </>
  );
};
