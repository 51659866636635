import React from "react";
import { generatePath } from "react-router-dom";
import routes from "routes";
import { PubliseringMalType, PublisertTilsynResponse } from "ApiClients";
import { LinkButton } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { getUrls } from "../../../../globalFunctions";

type IProps = {
  tilsyn: PublisertTilsynResponse;
};

const getButtonText: (tilsyn: PublisertTilsynResponse) => string = (tilsyn) => {
  if (tilsyn.publiseringMalType === PubliseringMalType.Tilsynsrapport) {
    return tilsyn.canSendInn
      ? "Logg inn for å gå til innsending"
      : "Logg inn for å se innsending";
  }
  return tilsyn.canSendInn
    ? "Logg inn for å sende inn uttalelse"
    : "Logg inn for å lese uttalelse";
};

const getButtonHref: (tilsyn: PublisertTilsynResponse) => string = (tilsyn) => {
  const targetRoute = generatePath(routes.rapport, {
    tilsynId: tilsyn.tilsynId,
  });
  return `${getUrls().loggInn}?returnUrl=${targetRoute}`;
};

export const ReadAccessObfuscatedNotLoggedInContent: React.FC<IProps> = ({
  tilsyn,
}) => {
  return (
    <LinkButton
      aria-label={getButtonText(tilsyn)}
      primary
      href={getButtonHref(tilsyn)}
    >
      {getButtonText(tilsyn)}
      <Icon type="wide" icon="arrow right long" className="u--marginLeft1" />
    </LinkButton>
  );
};
