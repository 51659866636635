import React from "react";
import { Barnehageloven } from "./Barnehageloven";
import { LovEnumIdentifier } from "../../../Types/Snapshot";
import { getText } from "../../../Overskrifts/Overskrifts";
import { Privatskoleloven } from "./Privatskoleloven";
import RichTextViewWithSladd from "../../../../components/RichTextWithSladd/RichTextViewWithSladd";
import { IReaksjonProps } from "../../Common/reaksjonInterfaces";
import { Folkehoyskoleloven } from "./Folkehoyskoleloven";

export const EndeligRapportHoldeTilbakeTilskudd: React.FC<IReaksjonProps> = ({
  snapshot,
  withoutObfuscationBackground,
}) => {
  const heading = getText(
    snapshot.omTilsynet?.malform ?? "Bokmål",
    (t) => t.reaksjonHoldeTilbakeTilskuddVedtak,
  );
  if (!snapshot.reaksjoner?.holdeTilbakeTilskudd?.isValgt) {
    return null;
  }
  const comment = snapshot.reaksjoner?.holdeTilbakeTilskudd?.kommentar;

  return (
    <>
      {snapshot.omTilsynet?.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven && (
        <Barnehageloven snapshot={snapshot} heading={heading} />
      )}
      {snapshot.omTilsynet?.lovEnumIdentifier ===
        LovEnumIdentifier.Folkehoyskoleloven && (
        <Folkehoyskoleloven snapshot={snapshot} heading={heading} />
      )}
      {snapshot.omTilsynet?.lovEnumIdentifier ===
        LovEnumIdentifier.Privatskoleloven && (
        <Privatskoleloven snapshot={snapshot} heading={heading} />
      )}
      {comment && (
        <RichTextViewWithSladd
          html={comment}
          withoutObfuscationBackground={withoutObfuscationBackground}
        />
      )}
    </>
  );
};
