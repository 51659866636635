import React from "react";
import { MainPanel } from "@utdanningsdirektoratet/layout";
import { PageTitle } from "SharedComponents/components/Headings/Headings";
import { generatePath, Link } from "react-router-dom";
import routes from "routes";
import styled from "styled-components";
import { Link as UrcLink } from "@utdanningsdirektoratet/link";
import { Radio, RadioGroup } from "@utdanningsdirektoratet/radio";
import { Button } from "@utdanningsdirektoratet/button/index";
import AnimateHeight from "react-animate-height";
import { RichText as RT } from "@utdanningsdirektoratet/richtext";
import { innsendingsRefreshKey } from "hooks/getters/publiseringInnsendelse";
import { useSurroundWithErrorHandling } from "utils/errorhandler";
import {
  InnsendingResponse,
  PublisertTilsynResponse,
  ResetUttalelseClient,
  SaveInnsendingClient,
  SendInnInnsendingClient,
} from "ApiClients";
import { getConfig } from "globalFunctions";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { mutate } from "swr";
import { hide, show } from "@utdanningsdirektoratet/slowaction";
import { ForelopigRapportInnsendingIngress } from "SharedComponents/tekster/InnsendingIngress/ForelopigRapport/ForelopigRapportInnsendingIngress";
import { getText } from "SharedComponents/tekster/Overskrifts/Overskrifts";
import { usePrompt } from "@utdanningsdirektoratet/prompt";
import { InnsendingDokumenter } from "../Innsendings/InnsendingDokumenter";
import { publiseringTilsynRefreshKey } from "../../../hooks/getters/tilsyn";

const RichText = styled(RT)`
  margin-bottom: 2rem;
  min-height: 15rem;
`;

const saveUttalelseUnderArbeidClient = new SaveInnsendingClient();
const resetUttalelseClient = new ResetUttalelseClient();
const sendInnClient = new SendInnInnsendingClient();

type IProps = {
  tilsyn: PublisertTilsynResponse;
  uttalelse?: InnsendingResponse;
};

export const SendInnUttalelse: React.FC<IProps> = ({ tilsyn, uttalelse }) => {
  const [hasUttalelse, setHasUttalelse] = React.useState<undefined | boolean>();
  const license = getConfig().froalaLicense;
  const [state, setState] = React.useState({
    kommentar: uttalelse?.kommentar ?? "",
    hasChange: false,
  });
  const [error, setError] = React.useState(false);

  usePrompt(
    state.hasChange,
    "Du har ulagrede endringer, er du sikker på at du vil lukke siden?",
    true,
  );

  React.useEffect(() => {
    if (uttalelse && hasUttalelse === undefined) {
      if (
        uttalelse.dokumentList.length > 0 ||
        uttalelse.kommentar ||
        state.kommentar
      ) {
        setHasUttalelse(true);
      } else {
        setHasUttalelse(false);
      }
    }
  }, [hasUttalelse, state.kommentar, uttalelse]);

  React.useEffect(() => {
    setError(false);
  }, [uttalelse]);

  React.useEffect(() => {
    if (
      uttalelse &&
      uttalelse.kommentar &&
      state.kommentar === "" &&
      !state.hasChange
    ) {
      setState({
        kommentar: uttalelse.kommentar,
        hasChange: false,
      });
    }
  }, [uttalelse, state.kommentar, state.hasChange]);

  const storeUttalelseWithSendingIn = useSurroundWithErrorHandling(async () => {
    if (hasUttalelse === undefined) {
      newNotification(
        "error",
        "Du må gjøre et valg for å kunne lagre uttalelse",
      );
      return;
    }
    if (hasUttalelse) {
      if (state.kommentar || (uttalelse && uttalelse.dokumentList.length > 0)) {
        await saveUttalelseUnderArbeidClient.save({
          publiseringId: tilsyn.publiseringId,
          kommentar: state.kommentar,
        });
        setState((prevState) => ({ ...prevState, hasChange: false }));
      } else {
        setError(true);
      }
    } else {
      await resetUttalelseClient.resetUttalelse(tilsyn.publiseringId);
      setState((prevState) => ({ ...prevState, hasChange: false }));
    }
    await show();
    await mutate(innsendingsRefreshKey(tilsyn.publiseringId));
    await mutate(publiseringTilsynRefreshKey(tilsyn.tilsynId));
    await hide();
    await newNotification(
      "success",
      "Uttalelsen er lagret, men ikke sendt inn",
    );
  });

  const sendInnUttalelse = useSurroundWithErrorHandling(async () => {
    if (hasUttalelse === undefined) {
      newNotification(
        "error",
        "Du må gjøre et valg for å kunne sende inn uttalelse",
      );
      return;
    }
    if (hasUttalelse) {
      if (state.kommentar || (uttalelse && uttalelse.dokumentList.length > 0)) {
        await sendInnClient.sendInn({
          publiseringId: tilsyn.publiseringId,
          kommentar: state.kommentar,
        });
      } else {
        setError(true);
        return;
      }
    } else {
      await sendInnClient.sendInn({
        publiseringId: tilsyn.publiseringId,
      });
    }
    setState((prevState) => ({ ...prevState, hasChange: false }));
    await show();
    await mutate(publiseringTilsynRefreshKey(tilsyn.tilsynId));
    await mutate(innsendingsRefreshKey(tilsyn.publiseringId));
    await hide();
    await newNotification("success", "Uttalelsen er sendt inn");
  });

  return (
    <MainPanel color="white">
      {tilsyn?.canSendInn ? (
        <>
          <div className="u--marginBottom2">
            <UrcLink iconLeft="angle left" text="Tilbake til rapporten">
              {(props) => (
                <Link
                  to={generatePath(routes.tilsyn, {
                    tilsynId: tilsyn.tilsynId,
                  })}
                  className={props.className}
                >
                  {props.children}
                </Link>
              )}
            </UrcLink>
          </div>
          <PageTitle className="u--marginBottom2">
            {getText(tilsyn.malform, (t) => t.uttalelseForelopigRapport)}
          </PageTitle>
          <ForelopigRapportInnsendingIngress
            snapshot={tilsyn.snapshot}
            innsendingFristDatoUtc={tilsyn.innsendingFristDatoUtc}
          />
          <RadioGroup
            className="u--block u--marginBottom2"
            label=""
            name="Uttalelse"
          >
            <Radio
              checked={hasUttalelse === false}
              onChange={() => {
                setHasUttalelse(false);
                setState((prevState) => ({ ...prevState, hasChange: true }));
              }}
              label="Vi har ingen merknader til foreløpig rapport"
            />
            <Radio
              checked={hasUttalelse === true}
              onChange={() => {
                setHasUttalelse(true);
                setState((prevState) => ({ ...prevState, hasChange: true }));
              }}
              label="Vi har merknader til foreløpig rapport"
            />
          </RadioGroup>
          {hasUttalelse && (
            <AnimateHeight
              duration={300}
              height={!hasUttalelse ? 0 : "auto"}
              aria-hidden={!hasUttalelse}
            >
              <p className="u--marginBottom1">
                Skriv i tekstboksen eller legg ved dokument.
              </p>
              <RichText
                error={error}
                label={getText(
                  tilsyn.malform,
                  (t) => t.uttalelseForelopigRapport,
                )}
                message="Det må enten være tekst her eller lastet opp dokumenter for å kunne lagre med merknad."
                config={{ heightMin: 200 }}
                license={license}
                onBlur={() => setError(false)}
                onFocus={() => setError(false)}
                onChange={(change) => {
                  setState({ kommentar: change, hasChange: true });
                }}
                value={state.kommentar}
              />
              <InnsendingDokumenter
                publiseringId={tilsyn.publiseringId}
                innsending={uttalelse}
                error={error}
              />
            </AnimateHeight>
          )}
          <Button onClick={storeUttalelseWithSendingIn}>
            Lagre uten å sende inn
          </Button>
          <Button primary onClick={sendInnUttalelse}>
            Lagre og send inn
          </Button>
        </>
      ) : null}
    </MainPanel>
  );
};
