import { Collapsible } from "@utdanningsdirektoratet/collapsible";
import { SnapshotResponse } from "ApiClients";
import { InnsendingAvDokumentasjonKlageadgang } from "SharedComponents/tekster/Klageadgang/InnsendingAvDokumentasjon/InnsendingAvDokumentasjonKlageadgang";
import { getText } from "SharedComponents/tekster/Overskrifts/Overskrifts";
import styled from "styled-components";

const CollapsibleStyled = styled(Collapsible)`
  border-radius: var(--borderradius1);
  header {
    border-radius: var(--borderradius1);
  }
`;

type IProps = {
  snapshot: SnapshotResponse;
};

export const RettTilAKlage: React.FC<IProps> = ({ snapshot }) => {
  return (
    <CollapsibleStyled
      title={getText(
        snapshot.omTilsynet?.malform ?? "Bokmål",
        (t) => t.rettTilAKlage,
      )}
      color="white"
      contentColor="white"
    >
      <InnsendingAvDokumentasjonKlageadgang snapshot={snapshot} />
    </CollapsibleStyled>
  );
};
