import { Icon } from "@utdanningsdirektoratet/icon";
import { Link } from "@utdanningsdirektoratet/link";
import Tooltip from "@utdanningsdirektoratet/tooltip/Tooltip";
import { AccessToTilsynType, PublisertTilsynResponse } from "ApiClients";
import { Box } from "components/Box";
import { getUrls } from "globalFunctions";
import * as React from "react";
import { generatePath } from "react-router-dom";
import routes from "routes";
import { getText } from "SharedComponents/tekster/Overskrifts/Overskrifts";
import styled from "styled-components";

const BoxStyled = styled(Box)`
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const IconStyled = styled(Icon)`
  overflow: initial;
`;

const getHeading: (tilsyn: PublisertTilsynResponse) => string = (tilsyn) => {
  return getText(
    tilsyn.snapshot.omTilsynet?.malform,
    (t) => t.gaTilTidligereInnsendtDokumentasjon,
  );
};

const getHref: (tilsyn: PublisertTilsynResponse) => string = (tilsyn) => {
  const targetRoute = generatePath(routes.innsending, {
    tilsynId: tilsyn.tilsynId,
  });
  if (tilsyn.tilgangType === AccessToTilsynType.ReadAndWriteUnObfuscated) {
    return targetRoute;
  }

  return `${getUrls().loggInn}?returnUrl=${targetRoute}`;
};

type IProps = {
  tilsyn: PublisertTilsynResponse;
};

export const LenkeTilTidligereInnsendinger: React.FC<IProps> = ({ tilsyn }) => {
  if (
    tilsyn.tilgangType !== AccessToTilsynType.ReadUnObfuscated &&
    tilsyn.tilgangType !== AccessToTilsynType.ReadAndWriteUnObfuscated
  ) {
    return <div />;
  }
  return (
    <BoxStyled $background="slate5">
      {tilsyn.tilgangType === AccessToTilsynType.ReadAndWriteUnObfuscated && (
        <>
          <Link href={getHref(tilsyn)}>{getHeading(tilsyn)}</Link>
          <IconStyled icon="arrow right" />
        </>
      )}
      {tilsyn.tilgangType === AccessToTilsynType.ReadUnObfuscated && (
        <>
          {getHeading(tilsyn)}
          <Tooltip message="Du mangler tilgang for å kunne lese dokumentasjonen">
            <IconStyled icon="lock" />
          </Tooltip>
        </>
      )}
    </BoxStyled>
  );
};
