import React from "react";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { Heading3 } from "../../../../components/Headings/Headings";
import { getText } from "../../../Overskrifts/Overskrifts";

type IProps = {
  snapshot: SnapshotResponse;
};

export const Folkehoyskoleloven: React.FC<IProps> = ({ snapshot }) => {
  const heading = getText(
    snapshot.omTilsynet?.malform ?? "Bokmål",
    (t) => t.reaksjonTrekkeTilbakeGodkjenningVedtak,
  );
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <>
        <Heading3>{heading}</Heading3>
        <p>
          <p>
            Vi vedtar at vi trekker tilbake skolen si godkjenning, jf.
            folkehøgskolelova § 3 andre ledd.
          </p>
        </p>
      </>
    );
  }
  return (
    <>
      <Heading3>{heading}</Heading3>
      <p>
        Vi vedtar at vi trekker tilbake skolens godkjenning, jf.
        folkehøyskoleloven § 3 andre ledd.
      </p>
    </>
  );
};
