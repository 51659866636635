import React from "react";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { RedigerbarTekst } from "../../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
};

export const Barnehageloven: React.FC<IProps> = ({ snapshot }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <RedigerbarTekst
        navn="EndeligRapportReaksjonerIngress"
        snapshot={snapshot}
      >
        <p>
          Barnehageeigar har ansvaret for at barnehagen blir driven i samsvar
          med regelverket, jf. barnehagelova § 7. Vi gir derfor eventuelle
          pålegg til barnehageeigar som har ansvar for at barnehagen rettar opp
          brot på regelverket.
        </p>
      </RedigerbarTekst>
    );
  }
  return (
    <RedigerbarTekst navn="EndeligRapportReaksjonerIngress" snapshot={snapshot}>
      <p>
        Barnehageeier har ansvaret for at barnehagen blir drevet i samsvar med
        regelverket, jf. barnehageloven § 7. Vi gir derfor eventuelle pålegg til
        barnehageeier som har ansvar for at barnehagen retter opp brudd på
        regelverket.
      </p>
    </RedigerbarTekst>
  );
};
