import React from "react";
import { SnapshotResponse } from "../../Types/Snapshot";
import { Paragraph } from "../../../components/Headings/Headings";

type IProps = {
  snapshot: SnapshotResponse;
};

export const InnsendingAvDokumentasjonKlageadgang: React.FC<IProps> = ({
  snapshot,
}) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <>
        <Paragraph>
          De kan klage på pålegget om å sende inn dokumentasjon, jf.
          forvaltningslova § 14.
        </Paragraph>
        <Paragraph>
          Dersom de klagar, må de gjere det innan tre dagar. Fristen gjeld frå
          melding om innsending av dokumentasjon har komme fram til dykk. De
          sender klagen til oss. Vi har høve til å gjere om avgjerda vår. Dersom
          vi ikkje er einige med dykk, sender vi klagen til
          Kunnskapsdepartementet som avgjer saka.
        </Paragraph>
        <Paragraph>
          Dersom vi vurderer at det er «påtrengjande nødvendig» å få
          dokumentasjonen raskt for å gjennomføre oppgåvene våre, kan vi krevje
          at de gir oss dokumentasjonen før ein eventuell klage er avgjord, jf.
          forvaltningslova 14 femte punktum.
        </Paragraph>
      </>
    );
  }

  return (
    <>
      <Paragraph>
        Dere kan klage på pålegget om å sende inn dokumentasjon, jf.
        forvaltningsloven § 14.
      </Paragraph>
      <Paragraph>
        Hvis dere klager, må dere gjøre det innen tre dager. Fristen gjelder fra
        melding om innsending av dokumentasjon har kommet frem til dere. Dere
        sender klagen til oss. Vi har mulighet til å omgjøre vår avgjørelse.
        Hvis vi ikke er enig med dere, sender vi klagen til
        Kunnskapsdepartementet som avgjør saken.
      </Paragraph>
      <Paragraph>
        Hvis vi vurderer at det er «påtrengende nødvendig» å få dokumentasjonen
        raskt for å gjennomføre våre oppgaver, kan vi kreve at dere gir oss
        dokumentasjonen før en eventuell klage er avgjort, jf. forvaltningsloven
        § 14 femte punktum.
      </Paragraph>
    </>
  );
};
