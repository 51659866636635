import React from "react";
import { useParams } from "react-router-dom";
import { PubliseringMalType } from "ApiClients";
import { useGetPublisertTilsyn } from "hooks/getters/tilsyn";
import { Loader } from "SharedComponents/components/Loader";
import { SendInnDokumentasjon } from "./Dokumentasjon/SendInnDokumentasjon";
import RapportInnsending from "./RapportInnsending";
import { usePageViewTrackingWhenTilsynLoaded } from "../../utils/analyticsUtils";

export const Innsendings: React.FC = () => {
  const { tilsynId } = useParams<"tilsynId">() as { tilsynId: string };
  const { data: tilsynPublisering } = useGetPublisertTilsyn(tilsynId);

  usePageViewTrackingWhenTilsynLoaded(tilsynPublisering);

  if (!tilsynPublisering) return <Loader />;

  return tilsynPublisering.publiseringMalType ===
    PubliseringMalType.InnsendingAvDokumentasjon ? (
    <SendInnDokumentasjon tilsyn={tilsynPublisering} />
  ) : (
    <RapportInnsending tilsyn={tilsynPublisering} />
  );
};
