import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import {
  ConfigBuilder,
  RichTextView,
} from "@utdanningsdirektoratet/richtext/index";

type IProps = {
  html: string;
  dokumentasjonskravId: number;
};
const storageString = (title: number) => `collapsible-isOpen-${title}`;

const getInitialState = (dokumentasjonskravId: number) => {
  const localStorageState = window.localStorage.getItem(
    storageString(dokumentasjonskravId),
  );
  return localStorageState ? JSON.parse(localStorageState) : false;
};

const Beskrivelse = styled.div<{ $isOpen: boolean }>`
  max-width: 43.5rem;
  overflow: hidden;

  ${(props) =>
    !props.$isOpen &&
    css`
      *:first-child {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      *:not(:first-child) {
        display: none;
      }
    `}
`;

const IconWithoutFlexShrink = styled(Icon)<{ $isOpen: boolean }>`
  flex-shrink: 0;
  transition: all linear 0.2s;
  margin-top: 0.25rem;

  ${(props) =>
    props.$isOpen &&
    css`
      transform: rotate(180deg);
    `}
  @media(max-width: 767px) {
    max-width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 1.5rem;
`;

const DokumentasjonskravBeskrivelse: React.FC<IProps> = ({
  html,
  dokumentasjonskravId,
}) => {
  const [isOpen, setIsOpen] = useState(getInitialState(dokumentasjonskravId));
  const config = new ConfigBuilder().withImages().build();

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
    window.localStorage.setItem(
      storageString(dokumentasjonskravId),
      JSON.stringify(!isOpen),
    );
  };

  return (
    <Container>
      <Beskrivelse $isOpen={isOpen}>
        <RichTextView config={config} html={html} />
      </Beskrivelse>
      <Button
        naked
        onClick={() => toggleIsOpen()}
        aria-label={isOpen ? "Lukk beskrivelse" : "Åpne beskrivelse"}
      >
        <IconWithoutFlexShrink icon="angle down" $isOpen={isOpen} />
      </Button>
    </Container>
  );
};
export default DokumentasjonskravBeskrivelse;
