import { css } from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";

export const SladdStyle = (withoutObfuscationBackground?: boolean) =>
  !withoutObfuscationBackground
    ? css`
        & s {
          color: white;
          background: ${colors.black};
          text-decoration: none;
        }
      `
    : css`
        & s {
          text-decoration: none;
        }
      `;
