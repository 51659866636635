import React from "react";
import { SnapshotResponse } from "../tekster/Types/Snapshot";
import { ExpandableBox } from "../components/ExpandableBox";
import { getText } from "../tekster/Overskrifts/Overskrifts";
import { Heading2 } from "../components/Headings/Headings";
import RapportChapter from "./RapportChapter";
import { hasBrudd } from "../utils/bruddUtils";
import { ForelopigRapportHvaSkjerNa } from "../tekster/HvaSkjerNa/ForelopigRapport/ForelopigRapportHvaSkjerNa";
import { EndeligRapportHvaSkjerNa } from "../tekster/HvaSkjerNa/EndeligRapport/EndeligRapportHvaSkjerNa";
import { IngenBrudd } from "../tekster/HvaSkjerNa/IngenBrudd/IngenBrudd";

type RapportOppfolgingProps = {
  snapshot: SnapshotResponse;
  isForelopigRapport: boolean;
  innsendingFristDatoUtc?: Date;
  withExpandable: boolean;
  withTitle: boolean;
  openByDefault?: boolean;
};

const Body: React.FC<{
  rapportHasNoBrudd: boolean;
  snapshot: SnapshotResponse;
  innsendingFristDatoUtc?: Date;
  isForelopigRapport: boolean;
}> = ({
  rapportHasNoBrudd,
  snapshot,
  innsendingFristDatoUtc,
  isForelopigRapport,
}) => {
  if (rapportHasNoBrudd) {
    return <IngenBrudd snapshot={snapshot} />;
  }
  if (isForelopigRapport) {
    return (
      <ForelopigRapportHvaSkjerNa
        snapshot={snapshot}
        innsendingFristDatoUtc={innsendingFristDatoUtc}
      />
    );
  }
  return (
    <EndeligRapportHvaSkjerNa
      snapshot={snapshot}
      innsendingFristDatoUtc={innsendingFristDatoUtc}
    />
  );
};

const RapportOppfolging: React.FC<RapportOppfolgingProps> = ({
  snapshot,
  isForelopigRapport,
  innsendingFristDatoUtc,
  withExpandable,
  withTitle,
  openByDefault = true,
}) => {
  const rapportHasNoBrudd = !hasBrudd(snapshot.brudd?.kontrollsporsmals ?? []);

  return withExpandable ? (
    <ExpandableBox
      id="rapport-oppfolgning"
      title={getText(snapshot.omTilsynet?.malform, (t) => t.oppfolging)}
      initialState={openByDefault ? "expanded" : "collapsed"}
    >
      <Body
        rapportHasNoBrudd={rapportHasNoBrudd}
        snapshot={snapshot}
        innsendingFristDatoUtc={innsendingFristDatoUtc}
        isForelopigRapport={isForelopigRapport}
      />
    </ExpandableBox>
  ) : (
    <RapportChapter>
      {withTitle && (
        <Heading2>
          {getText(snapshot.omTilsynet?.malform, (t) => t.oppfolging)}
        </Heading2>
      )}
      <Body
        rapportHasNoBrudd={rapportHasNoBrudd}
        snapshot={snapshot}
        innsendingFristDatoUtc={innsendingFristDatoUtc}
        isForelopigRapport={isForelopigRapport}
      />
    </RapportChapter>
  );
};

export default RapportOppfolging;
