import React from "react";
import styled from "styled-components";
import { ConfigBuilder, RichTextView } from "@utdanningsdirektoratet/richtext";
import { BeskrivelseAvTilsynsObjektetSnapshotResponse } from "../../tekster/Types/Snapshot";
import { Heading3 } from "../../components/Headings/Headings";

type Props = {
  beskrivelseAvTilsynsObjekt?: BeskrivelseAvTilsynsObjektetSnapshotResponse;
};

const BeskrivelseSection = styled.section`
  margin: 1rem 1.5rem 2.5rem 1.5rem;
`;

export const BeskrivelseAvTilsynsObjekt: React.FC<Props> = ({
  beskrivelseAvTilsynsObjekt,
}) => {
  if (!beskrivelseAvTilsynsObjekt) {
    return null;
  }

  const config = new ConfigBuilder().withImages().build();

  return (
    <BeskrivelseSection>
      {beskrivelseAvTilsynsObjekt.tittel && (
        <Heading3>{beskrivelseAvTilsynsObjekt.tittel}</Heading3>
      )}
      {beskrivelseAvTilsynsObjekt.beskrivelse && (
        <RichTextView
          config={config}
          html={beskrivelseAvTilsynsObjekt.beskrivelse}
        />
      )}
    </BeskrivelseSection>
  );
};
