import React from "react";
import { PublisertTilsynInfoResponse } from "ApiClients";
import { Heading2 } from "SharedComponents/components/Headings/Headings";
import throttle from "lodash.throttle";
import { OversiktCard } from "./OversiktCard";

type OversiktCardListProps = {
  tilsyns: PublisertTilsynInfoResponse[];
  header?: string;
  highlightCurrentUsersCards?: boolean;
  noTilsynsMessage?: React.ReactNode;
  showAnsvarligSaksbehandler?: boolean;
  scrollKey: string;
};

export const OversiktCardList: React.FC<OversiktCardListProps> = ({
  tilsyns,
  header,
  highlightCurrentUsersCards,
  noTilsynsMessage,
  showAnsvarligSaksbehandler,
  scrollKey,
}) => {
  const [scroll, setScroll] = React.useState<number>();

  const updateScroll = React.useCallback(
    (scrollTop: number) => {
      if (tilsyns.length > 0 && scrollTop !== scroll) {
        setScroll(scrollTop);
        sessionStorage.setItem(scrollKey, scrollTop.toString());
      }
    },
    [tilsyns.length, scroll, scrollKey],
  );

  React.useEffect(() => {
    const _onScroll = throttle(() => {
      updateScroll(window.scrollY);
    }, 100);

    window.addEventListener("scroll", _onScroll);
    return () => {
      window.removeEventListener("scroll", _onScroll);
    };
  }, [updateScroll]);

  React.useEffect(() => {
    const savedScroll = sessionStorage.getItem(scrollKey);
    if (savedScroll) {
      window.scrollTo(0, +savedScroll);
    }
  }, [scrollKey, tilsyns.length]);

  return (
    <>
      {header && <Heading2>{header}</Heading2>}
      {tilsyns.length === 0 && noTilsynsMessage ? noTilsynsMessage : null}
      {tilsyns.length === 0 && !noTilsynsMessage ? "Fant ingen tilsyn" : null}
      {tilsyns.map((tilsyn) => (
        <div key={`oversiktCard-${tilsyn.id}`} className="u--marginTop1">
          <OversiktCard
            tilsyn={tilsyn}
            color={
              highlightCurrentUsersCards && tilsyn.isForCurrentUser
                ? "green"
                : "lightazure"
            }
            showAnsvarligSaksbehandler={showAnsvarligSaksbehandler}
          />
        </div>
      ))}
    </>
  );
};
