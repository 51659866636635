import React from "react";
import { Barnehageloven } from "./Barnehageloven";
import RichTextViewWithSladd from "../../../../components/RichTextWithSladd/RichTextViewWithSladd";
import { IReaksjonProps } from "../../Common/reaksjonInterfaces";

export const ForelopigRapportPermanentStenging: React.FC<IReaksjonProps> = ({
  snapshot,
  withoutObfuscationBackground,
}) => {
  if (!snapshot.reaksjoner?.permanentStengingAvVirksomhet?.isValgt) {
    return null;
  }
  const comment = snapshot.reaksjoner?.permanentStengingAvVirksomhet?.kommentar;

  return (
    <>
      <Barnehageloven snapshot={snapshot} />
      {comment && (
        <RichTextViewWithSladd
          html={comment}
          withoutObfuscationBackground={withoutObfuscationBackground}
        />
      )}
      {snapshot.omTilsynet?.lovTittel !== "Barnehageloven" && (
        <b>Reaksjonen permanent stenging gjelder kun for barnehageloven</b>
      )}
    </>
  );
};
