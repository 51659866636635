import React from "react";
import { formatDate } from "@utdanningsdirektoratet/utils/date/index";
import { Vedlegg } from "@utdanningsdirektoratet/vedlegg/index";
import { RichTextView } from "@utdanningsdirektoratet/richtext/index";
import styled from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";
import { DATE_FORMAT } from "SharedComponents/utils/date";
import {
  InnsendingResponse,
  PubliseringDokumentasjonskravResponse,
} from "../../../ApiClients";
import { getUrls } from "../../../globalFunctions";

type Props = {
  krav: PubliseringDokumentasjonskravResponse;
  innsending: InnsendingResponse;
};

const Kommentar = styled.div`
  margin-top: 1rem;
  border-left: 2px solid ${colors.steelblue4};
  padding-left: 1rem;
  max-width: 46rem;
`;

const Heading = styled.p`
  font-weight: normal;
  color: ${colors.activeblue};
`;

const Container = styled.div`
  margin-bottom: 2rem;
`;

export const DokumentasjonskravInnsending: React.FC<Props> = ({
  krav,
  innsending,
}) => {
  const urls = getUrls();
  if (
    innsending.dokumentasjonskravDokumentList.filter(
      (d) =>
        d.dokumentasjonskravExternalId === krav.dokumentasjonskravExternalId,
    ).length === 0 &&
    innsending.dokumentasjonskravSvarList.filter(
      (s) => s.dokumentasjonskravId === krav.dokumentasjonskravId,
    ).length === 0
  ) {
    return null;
  }

  const heading = `Sendt inn ${formatDate(new Date(innsending.innsendtDateUtc!), DATE_FORMAT)}`;

  return (
    <Container>
      <Heading className="u--marginBottom1">{heading}</Heading>
      <div>
        {innsending.dokumentasjonskravDokumentList
          .filter(
            (d) =>
              d.dokumentasjonskravExternalId ===
              krav.dokumentasjonskravExternalId,
          )
          .map((d) => (
            <div key={d.externalId}>
              <Vedlegg
                key={`tildigeredokument-${d.externalId}`}
                title={d.filnavn}
                href={`${urls.downloadDokument}/${d.externalId}`}
              />
            </div>
          ))}
      </div>
      <div>
        {innsending.dokumentasjonskravSvarList
          .filter((d) => d.dokumentasjonskravId === krav.dokumentasjonskravId)
          .map((d) => (
            <div key={d.externalId}>
              {d.svartIkkeAktuelt && (
                <div className="u--marginTop1">
                  Dere mener at dokumentasjonskravet er irrelevant
                </div>
              )}
              <Kommentar className="u--marginBottom1">
                <RichTextView html={d.kommentar!} />
              </Kommentar>
            </div>
          ))}
      </div>
    </Container>
  );
};
