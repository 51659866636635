import React from "react";
import { MenuPanel } from "@utdanningsdirektoratet/layout";
import { getCurrentUser, getUrls } from "globalFunctions";
import { Dropdown, DropdownItem } from "@utdanningsdirektoratet/dropdown";
import { LinkButton } from "@utdanningsdirektoratet/button";

const Header: React.FC = () => {
  const user = getCurrentUser();

  const dropdownLinks = [
    <DropdownItem>
      {({ className }) => (
        <a href={getUrls().loggUt} className={className}>
          Logg ut
        </a>
      )}
    </DropdownItem>,
  ];

  return (
    <MenuPanel external title="Tilsyn">
      {user ? (
        <Dropdown lists={[dropdownLinks]}>{user.fullName}</Dropdown>
      ) : (
        <LinkButton href={getUrls().loggInn}>Logg inn</LinkButton>
      )}
    </MenuPanel>
  );
};

export default Header;
