import React from "react";
import { Heading3 } from "../../../../components/Headings/Headings";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { SnapshotResponse } from "../../../Types/Snapshot";

type IProps = {
  snapshot: SnapshotResponse;
  heading: string;
};

export const Privatskoleloven: React.FC<IProps> = ({ snapshot, heading }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <>
        <Heading3>{heading}</Heading3>
        <p>
          Vi varslar at vi vurderer å fatte vedtak om at de skal betale tilbake
          kr{" "}
          {formatCurrency(snapshot.reaksjoner?.kravOmTilbakebetaling?.totalSum)}
          , jf. privatskolelova § 7-2 a andre ledd. Dersom vi får nye
          opplysningar som har betydning for utmålinga, kan vi endre beløpet i
          vedtaket.
        </p>
      </>
    );
  }
  return (
    <>
      <Heading3>{heading}</Heading3>
      <p>
        Vi varsler at vi vurderer å fatte vedtak om at dere skal tilbakebetale
        kr{" "}
        {formatCurrency(snapshot.reaksjoner?.kravOmTilbakebetaling?.totalSum)},
        jf. privatskoleloven § 7-2 a andre ledd. Dersom vi får nye opplysninger
        som har betydning for utmålingen, kan vi endre beløpet i vedtaket.
      </p>
    </>
  );
};
