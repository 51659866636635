import { AccessToTilsynType } from "../ApiClients";

export const accessLevelLowerThan = (
  userAccessLevel: AccessToTilsynType,
  isLowerThanAccessLevel: AccessToTilsynType,
): boolean => {
  switch (isLowerThanAccessLevel) {
    case AccessToTilsynType.ReadAndWriteUnObfuscated:
      return userAccessLevel !== AccessToTilsynType.ReadAndWriteUnObfuscated;

    case AccessToTilsynType.ReadUnObfuscated:
      return (
        userAccessLevel === AccessToTilsynType.ReadObfuscatedOnly ||
        userAccessLevel === AccessToTilsynType.Unknown
      );

    case AccessToTilsynType.ReadObfuscatedOnly:
      return userAccessLevel === AccessToTilsynType.Unknown;

    default:
      return false;
  }
};
