import React from "react";
import { Placeholder } from "@utdanningsdirektoratet/placeholder/index";

export const PlaceholderBuilder: React.FC = () => {
  return (
    <>
      <Placeholder height={2} width={35} />
      <Placeholder height={2} width={45} />
      <Placeholder width={32} />
    </>
  );
};
