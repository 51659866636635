import React from "react";
import { newNotification } from "@utdanningsdirektoratet/notifications/index";
import { useContextState } from "StateProvider";
import { ActionTypeProps, SET_ERROR } from "reducers";
import { ApiException } from "ApiClients";

export const handleError = (
  error: Error | unknown,
  dispatch: React.Dispatch<ActionTypeProps>,
): void => {
  if (ApiException.isApiException(error)) {
    const apiException = error as ApiException;
    if (apiException.status > 400) {
      dispatch({ type: SET_ERROR, payload: apiException });
    } else {
      const { title, errorMessages } = JSON.parse(apiException.response);
      newNotification(
        "error",
        title,
        errorMessages?.reduce((acc: string, message: string) => {
          if (acc === "") {
            return `${message}`;
          }
          return `${acc}, ${message}`;
        }, ""),
      );
    }
  } else if (error instanceof Error) {
    const apiException = new ApiException(error.message, 401, "", {}, null);
    dispatch({ type: SET_ERROR, payload: apiException });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSurroundWithErrorHandling = <
  K,
  T extends (...args: any[]) => Promise<K>,
>(
  func: T,
): T => {
  const { dispatch } = useContextState();

  return (async (...args) => {
    try {
      return await func(...args);
    } catch (e) {
      handleError(e, dispatch);
      throw e;
    }
  }) as T;
};
