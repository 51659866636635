import React, { useContext } from "react";
import {
  ConfigBuilder,
  RichTextView,
} from "@utdanningsdirektoratet/richtext/index";
import { RedigerbarTekstContext } from "./RedigerbarTekstContext";
import { SnapshotResponse } from "../tekster/Types/Snapshot";

export type RedigerbarTekstProps = {
  navn: string;
  snapshot: SnapshotResponse;
  children: React.ReactNode;
  noFormatting?: boolean;
  wrapper?: React.ExoticComponent<{
    children?: React.ReactNode;
  }>;
};

export const RedigerbarTekst: React.FC<RedigerbarTekstProps> = ({
  navn,
  snapshot,
  children,
  noFormatting = false,
  wrapper: Wrapper = React.Fragment,
}) => {
  const renderRedigerbarTekst = useContext(RedigerbarTekstContext);

  const config = new ConfigBuilder().withImages().build();

  if (renderRedigerbarTekst) {
    return renderRedigerbarTekst(navn, children, noFormatting, Wrapper);
  }

  const redigertTekst = snapshot.redigertTeksts?.find((t) => t.navn === navn);

  if (redigertTekst?.tekst === "") {
    return null;
  }

  return (
    <Wrapper>
      {redigertTekst ? (
        <RichTextView
          config={config}
          html={redigertTekst.tekst}
          withoutFroalaClass
        />
      ) : (
        children
      )}
    </Wrapper>
  );
};
