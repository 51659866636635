import React from "react";
import {
  SnapshotResponse,
  TilsynsRapportComputedMetadata,
} from "../tekster/Types/Snapshot";
import { EndeligRapportInnledning } from "../tekster/Innledning/EndeligRapport/EndeligRapportInnledning";
import { ForelopigRapportInnledning } from "../tekster/Innledning/ForelopigRapport/ForelopigRapportInnledning";
import { ExpandableBox } from "../components/ExpandableBox";
import { getText } from "../tekster/Overskrifts/Overskrifts";

type IProps = {
  snapshot: SnapshotResponse;
  isForelopigRapport: boolean;
  tilsynsRapportMetadata?: TilsynsRapportComputedMetadata;
  openByDefault?: boolean;
};
const RapportInnledning: React.FC<IProps> = ({
  snapshot,
  isForelopigRapport,
  tilsynsRapportMetadata,
  openByDefault = true,
}) => {
  return (
    <ExpandableBox
      id="innledning-content"
      title={getText(snapshot.omTilsynet?.malform, (t) => t.innledning)}
      initialState={openByDefault ? "expanded" : "collapsed"}
    >
      {isForelopigRapport === true ? (
        <ForelopigRapportInnledning snapshot={snapshot} />
      ) : (
        <EndeligRapportInnledning
          snapshot={snapshot}
          tilsynsRapportMetadata={tilsynsRapportMetadata}
        />
      )}
    </ExpandableBox>
  );
};

export default RapportInnledning;
