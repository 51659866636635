import React from "react";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { Heading3 } from "../../../../components/Headings/Headings";
import { formatCurrency } from "../../../../utils/formatCurrency";

type IProps = {
  snapshot: SnapshotResponse;
  heading: string;
};

export const Barnehageloven: React.FC<IProps> = ({ snapshot, heading }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <>
        <Heading3>{heading}</Heading3>
        <p>
          Vi varslar at vi vurderer å fatte vedtak om at barnehagen skal
          tilbakebetale kr{" "}
          {formatCurrency(snapshot.reaksjoner?.kravOmTilbakebetaling?.totalSum)}{" "}
          til kommunen, jf. barnehagelova § 56. Dersom vi får nye opplysningar
          som har betydning for utmålinga, kan vi endre beløpet i vedtaket.
        </p>
      </>
    );
  }
  return (
    <>
      <Heading3>{heading}</Heading3>
      <p>
        Vi varsler at vi vurderer å fatte vedtak om at barnehagen skal
        tilbakebetale kr{" "}
        {formatCurrency(snapshot.reaksjoner?.kravOmTilbakebetaling?.totalSum)}{" "}
        til kommunen, jf. barnehageloven § 56. Dersom vi får nye opplysninger
        som har betydning for utmålingen, kan vi endre beløpet i vedtaket.
      </p>
    </>
  );
};
