import colors from "@utdanningsdirektoratet/styles/colors";
import * as React from "react";
import styled from "styled-components";
import { Icon } from "@utdanningsdirektoratet/icon";
import { AccessToTilsynType } from "../ApiClients";

const Container = styled.div`
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: ${colors.eggshell4};
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: var(--borderradius1);
`;

const StyledH3 = styled.h3`
  font-size: 1rem;
`;

type TitleHeaderBoxProps = {
  tilgangType: AccessToTilsynType;
};

const getTilgangText: (tilgangType: AccessToTilsynType) => string = (
  tilgangType,
) => {
  if (tilgangType === AccessToTilsynType.ReadUnObfuscated) {
    return "For å sende inn dokumentasjon i tilsynet, trenger du Lese- og skrivetilgang i Altinn. Daglig leder eller de i organisasjonen med administratorrolle i Altinn kan tildele tilganger.";
  }
  return "For å lese usladdede rapporter eller sende inn dokumentasjon og uttalelser i tilsynet, trenger du tilgang i Altinn. Daglig leder eller de i organisasjonen med administratorrolle i Altinn kan tildele tilganger.";
};

export const TilgangMessage: React.FC<TitleHeaderBoxProps> = ({
  tilgangType,
}) => {
  if (
    tilgangType === AccessToTilsynType.ReadAndWriteUnObfuscated ||
    tilgangType === AccessToTilsynType.Unknown
  ) {
    return null;
  }
  return (
    <Container>
      <StyledH3 className="u--marginTop0-5 u--marginBottom0-5">
        <Icon icon="circle info" className="u--marginRight1" />
        Nye tilganger i Altinn
      </StyledH3>
      <div className="u--paddingLeft2">{getTilgangText(tilgangType)}</div>
    </Container>
  );
};
