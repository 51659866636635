import { SnapshotResponse } from "../tekster/Types/Snapshot";
import { deCapitalize } from "./stringUtils";

const firstWordIsUpperCased = (text: string) =>
  text.split(" ")[0] === text.split(" ")[0].toUpperCase();

export const tilsynstemaOgHjemmelCombiner = (
  snapshot: SnapshotResponse,
): string => {
  const tilsynstemaOgHjemmelText = snapshot.regelverkOgVurdering
    ? snapshot.regelverkOgVurdering?.tilsynstemas?.map(
        (t) =>
          `${t.tittel && (firstWordIsUpperCased(t.tittel) ? t.tittel : deCapitalize(t.tittel))}, jf. ${t.hjemmel}`,
      )
    : [];

  if (tilsynstemaOgHjemmelText.length === 0) {
    return "";
  }

  return tilsynstemaOgHjemmelText.length <= 1
    ? tilsynstemaOgHjemmelText[0]
    : `${tilsynstemaOgHjemmelText?.slice(0, -1).join(", ")} og ${tilsynstemaOgHjemmelText?.slice(-1)}`;
};
