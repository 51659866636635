import React from "react";
import styled from "styled-components";
import ReaksjonKorreksjonspunkt from "./ReaksjonKorreksjonspunkt";
import { PaleggOmRettingPaleggSnapshotResponse } from "../../../Types/Snapshot";

const Palegg = styled.p`
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
`;

type ReaksjonPaleggProps = {
  palegg: PaleggOmRettingPaleggSnapshotResponse;
  malform: string | undefined;
};

const ReaksjonPalegg: React.FC<ReaksjonPaleggProps> = ({ palegg, malform }) => {
  const korreksjonspunkts = palegg.korreksjonspunkts.map((k, i) => (
    <ReaksjonKorreksjonspunkt
      korreksjonspunkt={k}
      key={`reaksjonKorreksjonspunkt-${i}`}
    />
  ));
  const textBeforeKorreksjonspunkts =
    malform === "Nynorsk" ? "Dette inneber at:" : "Dette innebærer at:";

  return (
    <Wrapper>
      <Palegg>
        {palegg.tittel}, jf. {palegg.hjemmel}.{" "}
        {korreksjonspunkts.length > 0 && textBeforeKorreksjonspunkts}
      </Palegg>
      {korreksjonspunkts}
    </Wrapper>
  );
};

export default ReaksjonPalegg;
