import React from "react";
import styled from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";
import { getText } from "../../tekster/Overskrifts/Overskrifts";
import {
  RegelverkOgVurderingKontrollsporsmalSnapshotResponse,
  SnapshotResponse,
} from "../../tekster/Types/Snapshot";
import RichTextViewWithSladd from "../../components/RichTextWithSladd/RichTextViewWithSladd";

type IProps = {
  kontrollsporsmal?: RegelverkOgVurderingKontrollsporsmalSnapshotResponse;
  withoutObfuscationBackground?: boolean;
  isForelopigRapport: boolean;
  snapshot: SnapshotResponse;
};

const GreenBox = styled.div`
  border: ${colors.green5} 2px solid;
  margin-bottom: 3rem;
`;

const KontrollsporsmalHeadingContainer = styled.div`
  display: flex;
  background: ${colors.green4};
  justify-content: space-between;
`;

const SporsmalText = styled.div`
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  background: ${colors.green4};
  font-weight: 500;
`;

const BegrunnelseHeader = styled(SporsmalText)`
  border-top: ${colors.green5} 2px solid;
  padding: 1rem 1.5rem;
`;

const SporsmalSvar = styled.div`
  border-left: ${colors.green5} 2px solid;
  padding: 1.5rem 1rem 1rem 1rem;
  background: ${colors.green4};
  min-width: 23%;
`;

const BoldDiv = styled.div`
  font-weight: 500;
`;

const RettsligGrunnlag = styled(RichTextViewWithSladd)`
  padding: 0 1.5rem 1rem 1.5rem;
`;

const RichTextViewBox = styled.div`
  padding: 1.5rem;
`;

const NoBreak = styled.div`
  break-inside: avoid-page;
`;

const RettsligGrunnlagLabel = styled.div`
  font-size: 14px;
  color: #006039;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
`;

export const Kontrollsporsmal: React.FC<IProps> = ({
  kontrollsporsmal,
  withoutObfuscationBackground,
  isForelopigRapport,
  snapshot,
}) => {
  if (!kontrollsporsmal) return null;
  return (
    <GreenBox>
      <NoBreak>
        <KontrollsporsmalHeadingContainer>
          <SporsmalText>{kontrollsporsmal.sporsmalTekst} </SporsmalText>

          <SporsmalSvar>
            {isForelopigRapport
              ? getText(
                  snapshot?.omTilsynet?.malform,
                  (t) => t.forelopigKonklusjon,
                )
              : "Vår konklusjon"}
            : <BoldDiv>{kontrollsporsmal.svarDescription}</BoldDiv>
          </SporsmalSvar>
        </KontrollsporsmalHeadingContainer>
        {kontrollsporsmal.rettsligGrunnlag && (
          <>
            <RettsligGrunnlagLabel>Rettslig grunnlag</RettsligGrunnlagLabel>
            <RettsligGrunnlag html={kontrollsporsmal.rettsligGrunnlag} />
          </>
        )}

        <BegrunnelseHeader>Vår begrunnelse</BegrunnelseHeader>
      </NoBreak>
      <RichTextViewBox>
        <RichTextViewWithSladd
          className="u--marginBottom1"
          html={kontrollsporsmal.observasjonOgVurdering ?? ""}
          withoutObfuscationBackground={withoutObfuscationBackground}
        />
      </RichTextViewBox>
    </GreenBox>
  );
};
