import React from "react";
import styled from "styled-components";
import { RichTextView } from "@utdanningsdirektoratet/richtext";
import { formatDate } from "SharedComponents/utils/date";
import { Heading3 } from "SharedComponents/components/Headings/Headings";
import { InnsendingResponse } from "../../../ApiClients";
import { Spacer } from "../../../components/Spacer";
import { getTidligereKommentarer } from "./getTidligereKommentarer";

type IProps = {
  innsendinger?: InnsendingResponse[];
  canSendIn: boolean;
};

const TidligereKommentarerContainer = styled.div`
  padding-bottom: 20px;
`;

export const TidligereKommentarer: React.FC<IProps> = ({
  innsendinger,
  canSendIn,
}) => {
  const tidligereKommentarer = React.useMemo(
    () => getTidligereKommentarer(innsendinger ?? []),
    [innsendinger],
  );

  const areTidligereKommentarer =
    tidligereKommentarer && tidligereKommentarer.length > 0;

  if (!areTidligereKommentarer) return null;
  return (
    <TidligereKommentarerContainer>
      {tidligereKommentarer?.map((innsending) => {
        return (
          <div key={innsending.id}>
            <Heading3>
              Generell kommentar den {formatDate(innsending.innsendtDateUtc!)}
            </Heading3>
            <RichTextView html={innsending.kommentar!} />
          </div>
        );
      })}
      {canSendIn && <Spacer className="u--marginTop2" color="slate5" />}
    </TidligereKommentarerContainer>
  );
};
