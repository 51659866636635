import { handleError } from "utils/errorhandler";
import {
  IResponseWithReset,
  useSwrWithErrorHandlingAndCallback,
} from "@utdanningsdirektoratet/hooks";
import { useContextState } from "StateProvider";

const useSwrWithErrorHandling = <T>(
  key: string | null,
  data: () => Promise<T>,
): IResponseWithReset<T> => {
  const { dispatch } = useContextState();
  return useSwrWithErrorHandlingAndCallback(
    key ?? undefined,
    data,
    (error: Error) => handleError(error, dispatch),
  );
};

export default useSwrWithErrorHandling;
