import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes";
import { MainPanel, ContentPanel } from "@utdanningsdirektoratet/layout";
import { Icon } from "@utdanningsdirektoratet/icon";
import colors from "@utdanningsdirektoratet/styles/colors";
import styled from "styled-components";
import { Heading1 } from "SharedComponents/components/Headings/Headings";
import OversiktMine from "./OversiktMine";
import { Tabs } from "./Tabs";
import { OversiktBarnehager } from "./OversiktPublic/OversiktBarnehager";
import { OversiktSkoler } from "./OversiktPublic/OversiktSkoler";

const ContentPanelStyled = styled(ContentPanel)`
  max-width: 50rem;
`;

const Header = styled.div`
  padding: 2rem;
  background: ${colors.green4};
`;

const TitleAndIngress = styled.div`
  max-width: 50rem;
  margin: 0 auto;
`;

const TilsynsRapporterAnchor = styled.a`
  color: ${colors.activeblue};
`;

const Oversikt: React.FC = () => {
  return (
    <MainPanel color="white">
      <Header>
        <TitleAndIngress>
          <Heading1>Tilsynsrapporter</Heading1>
          <p>
            Vi fører tilsyn med private skoler, og økonomiske tilsyn med private
            barnehager. På denne siden kan du følge tilsynene fra start til
            slutt. Tilsynsrapportene ligger tilgjengelig selv om tilsynet er
            avsluttet.
          </p>
          <p>
            Finner du ikke en tilsynsrapport med en privat skole på denne siden?{" "}
            <TilsynsRapporterAnchor
              href={routes.historic}
              target="_blank"
              rel="noreferrer"
            >
              Gå hit for tilsyn som er startet før 2022{" "}
              <Icon
                type="small"
                icon="north east"
                color="darkblue"
                placement="before"
              />
            </TilsynsRapporterAnchor>
          </p>
          <p>
            Fra og med 1. juli 2024 publiseres ikke foreløpige rapporter på
            denne siden.{" "}
            <TilsynsRapporterAnchor
              href="https://einnsyn.no/sok?f=e0225b24-f6a4-43a7-9019-b589716f7fe7"
              target="_blank"
              rel="noreferrer"
            >
              Be om innsyn på eInnsyn.no{" "}
              <Icon
                type="small"
                icon="north east"
                color="darkblue"
                placement="before"
              />
            </TilsynsRapporterAnchor>
          </p>
        </TitleAndIngress>
      </Header>
      <ContentPanelStyled>
        <Tabs />
        <div className="u--marginTop1">
          <Routes>
            <Route
              path=""
              element={<Navigate replace to={routes.skoleTilsyn} />}
            />
            <Route path="mine" element={<OversiktMine />} />
            <Route path="skole" element={<OversiktSkoler />} />
            <Route path="barnehage" element={<OversiktBarnehager />} />
          </Routes>
        </div>
      </ContentPanelStyled>
    </MainPanel>
  );
};
export default Oversikt;
