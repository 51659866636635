import { PageTitle } from "@utdanningsdirektoratet/styles";
import React from "react";
import { SnapshotResponse } from "./tekster/Types/Snapshot";

type TilsynTittelProps = {
  snapshot: SnapshotResponse;
  className?: string;
};

export const TilsynTittel: React.FC<TilsynTittelProps> = ({
  snapshot,
  className,
}) => {
  return (
    <PageTitle className={className}>
      Tilsyn med {snapshot.omTilsynet?.tilsynForesMedNavn}
    </PageTitle>
  );
};
