import React from "react";
import { Heading2 } from "SharedComponents/components/Headings/Headings";
import DokumentasjonskravBeskrivelse from "SharedComponents/Dokumentasjonskrav/DokumentasjonskravBeskrivelse";
import { Box } from "components/Box";
import {
  InnsendingResponse,
  PubliseringDokumentasjonskravResponse,
} from "../../../ApiClients";
import { DokumentasjonskravInnsending } from "./DokumentasjonskravInnsending";
import { Spacer } from "../../../components/Spacer";

type Props = {
  dokumentasjonskravs?: PubliseringDokumentasjonskravResponse[];
  innsendteInnsendinger?: InnsendingResponse[];
};

export const TidligereInnsendteDokumentasjonskravs: React.FC<Props> = ({
  innsendteInnsendinger,
  dokumentasjonskravs,
}) => {
  const kravs = React.useMemo(
    () => dokumentasjonskravs?.filter((krav) => krav.isLocked === true),
    [dokumentasjonskravs],
  );

  if (!kravs || kravs.length === 0) return null;

  return (
    <div>
      {kravs &&
        kravs.map((k) => (
          <Box
            $background="white"
            key={k.dokumentasjonskravExternalId}
            className="u--marginBottom2"
          >
            <Heading2 className="u--marginBottom1">{k.tittel}</Heading2>
            <DokumentasjonskravBeskrivelse
              html={k.beskrivelse}
              dokumentasjonskravId={k.dokumentasjonskravId}
            />
            <Spacer color="slate5" />
            {innsendteInnsendinger &&
              innsendteInnsendinger.map((innsending) => (
                <DokumentasjonskravInnsending
                  key={innsending.id}
                  krav={k}
                  innsending={innsending}
                />
              ))}
          </Box>
        ))}
    </div>
  );
};
