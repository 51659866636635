import React from "react";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { DATE_FORMAT, formatDate } from "../../../../utils/date";
import { Heading3 } from "../../../../components/Headings/Headings";
import { RedigerbarTekst } from "../../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
  innsendingFristDatoUtc?: Date;
  heading?: string;
  highlightFrist?: boolean;
};

export const DereKanGiTilbakemelding: React.FC<IProps> = ({
  snapshot,
  innsendingFristDatoUtc,
  heading,
  highlightFrist,
}) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    const fristText = `Fristen for å sende inn uttale er ${
      innsendingFristDatoUtc
        ? formatDate(innsendingFristDatoUtc.toString(), DATE_FORMAT)
        : "ikkje satt"
    }.`;

    return (
      <>
        {heading && <Heading3>{heading}</Heading3>}
        <RedigerbarTekst
          navn="HvaSkjerNaDereKanGiTilbakemelding"
          snapshot={snapshot}
        >
          <p>
            De har rett til å uttale dykk før vi fattar vedtak, jf.
            forvaltningslova § 16. De kan uttale dykk om alt i rapporten, både
            vår beskriving av fakta, vår forståing av lova, våre vurderingar og
            våre reaksjonar. Dersom de meiner rapporten inneheld feil, bør de
            grunngi kvifor. De sender inn uttalen her, på tilsyn.udir.no.
          </p>
          <p>
            På bakgrunn av uttale frå dykk, vil vi vurdere om det er grunnlag
            for å endre innhaldet i rapporten. Dersom vi held fast på vår
            konklusjon om at de ikkje følgjer regelverket på alle områder, vil
            vi fatte vedtak i saka.
          </p>
          <p>
            De har rett til innsyn i saksdokumenta, jf. forvaltningslova § 18.
          </p>
        </RedigerbarTekst>
        <p>{highlightFrist ? <strong>{fristText}</strong> : fristText}</p>
      </>
    );
  }
  const fristText = `Fristen for å sende inn uttalelse er ${
    innsendingFristDatoUtc
      ? formatDate(innsendingFristDatoUtc.toString(), DATE_FORMAT)
      : "ikke satt"
  }.`;

  return (
    <>
      {heading && <Heading3>{heading}</Heading3>}
      <RedigerbarTekst
        navn="HvaSkjerNaDereKanGiTilbakemelding"
        snapshot={snapshot}
      >
        <p>
          Dere har rett til å uttale dere før vi fatter vedtak, jf.
          forvaltningsloven § 16. Dere kan uttale dere om alt i rapporten, både
          vår beskrivelse av fakta, vår forståelse av loven, våre vurderinger og
          våre reaksjoner. Hvis dere mener rapporten inneholder feil, bør dere
          begrunne hvorfor. Dere sender inn uttalelsen her, på tilsyn.udir.no.
        </p>
        <p>
          På bakgrunn av uttalelsen fra dere, vil vi vurdere om det er grunnlag
          for å endre innholdet i rapporten. Hvis vi opprettholder vår
          konklusjon om at dere ikke følger regelverket på alle områder, fatter
          vi vedtak i saken.
        </p>
        <p>
          Dere har rett til innsyn i sakens dokumenter, jf. forvaltningsloven §
          18.
        </p>
      </RedigerbarTekst>
      <p>{highlightFrist ? <strong>{fristText}</strong> : fristText}</p>
    </>
  );
};
