import React from "react";
import { DereKanGiTilbakemelding } from "../../HvaSkjerNa/ForelopigRapport/DereKanGiTilbakemelding/DereKanGiTilbakemelding";
import { SnapshotResponse } from "../../Types/Snapshot";

type IProps = {
  snapshot: SnapshotResponse;
  innsendingFristDatoUtc?: Date;
};

export const ForelopigRapportInnsendingIngress: React.FC<IProps> = ({
  snapshot,
  innsendingFristDatoUtc,
}) => {
  return (
    <DereKanGiTilbakemelding
      snapshot={snapshot}
      innsendingFristDatoUtc={innsendingFristDatoUtc}
      highlightFrist
    />
  );
};
