import styled from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";

const ParagraphSmall = styled.p`
  color: ${colors.darkbrown};
  font-size: 0.875rem;
  margin: 0;
  margin-top: 1rem;
`;

export default ParagraphSmall;
