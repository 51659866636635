/* eslint-disable comma-dangle */

export default {
  home: "/",
  personvern: "/personvern",
  tilsyn: "/tilsyn/:tilsynId",
  rapport: "/rapport/:tilsynId",
  innsending: "/tilsyn/:tilsynId/innsending",
  oversikt: "/oversikt",
  mineTilsyn: "/oversikt/mine",
  skoleTilsyn: "/oversikt/skole",
  barnehageTilsyn: "/oversikt/barnehage",
  notfound: "/tilsyn/notfound",
  historic: "/tidligererapport",
};
