import React from "react";
import { Barnehageloven } from "./Barnehageloven";
import RichTextViewWithSladd from "../../../../components/RichTextWithSladd/RichTextViewWithSladd";
import { IReaksjonProps } from "../../Common/reaksjonInterfaces";

export const ForelopigRapportTidsbegrensetStenging: React.FC<
  IReaksjonProps
> = ({ snapshot, withoutObfuscationBackground }) => {
  if (!snapshot.reaksjoner?.tidsbegrensetStengingAvVirksomhet?.isValgt) {
    return null;
  }
  const comment =
    snapshot.reaksjoner?.tidsbegrensetStengingAvVirksomhet?.kommentar;

  return (
    <>
      <Barnehageloven snapshot={snapshot} />
      {comment && (
        <RichTextViewWithSladd
          html={comment}
          withoutObfuscationBackground={withoutObfuscationBackground}
        />
      )}
      {snapshot.omTilsynet?.lovTittel !== "Barnehageloven" && (
        <b>Reaksjonen tidsbegrenset stenging gjelder kun for barnehageloven.</b>
      )}
    </>
  );
};
