import React from "react";
import styled, { css } from "styled-components";
import { onKeyDown } from "@utdanningsdirektoratet/utils/uu/index";
import colors from "@utdanningsdirektoratet/styles/colors";
import AnimateHeight from "react-animate-height";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Heading2 } from "./Headings/Headings";

type IProps = {
  id: string;
  title: string;
  initialState?: "collapsed" | "expanded";
  noContentMargin?: boolean;
  children?: React.ReactNode;
};

const GreenBox = styled.div<{ $active: boolean }>`
  padding: 1.5rem;
  background: ${colors.green4};
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 1rem;
  &:hover {
    background: ${colors.green5};
    cursor: pointer;
  }
  ${(props) =>
    props.$active &&
    css`
      background: ${colors.green5};
    `}

  @media print {
    break-inside: avoid-page;
  }
`;

const ArrowIcon = styled(Icon)<{ $active: boolean }>`
  transition: linear 0.15s;
  ${(props) =>
    props.$active &&
    css`
      transform: rotateX(180deg);
    `}
  @media print {
    display: none;
  }
`;

const Center = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
`;

const Content = styled.div<{ $noContentMargin: boolean | undefined }>`
  ${(props) =>
    !props.$noContentMargin &&
    css`
      margin: 0 1.5rem 2rem 1.5rem;
    `}
`;

const Title = styled(Heading2)`
  margin: 0;
`;

export const ExpandableBox: React.FC<IProps> = ({
  id,
  title,
  initialState = "collapsed",
  noContentMargin,
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(
    initialState === "collapsed",
  );

  return (
    <>
      <GreenBox
        onClick={() => setIsCollapsed((prevstate) => !prevstate)}
        onKeyDown={onKeyDown(() => setIsCollapsed((prevstate) => !prevstate))}
        role="button"
        tabIndex={0}
        aria-controls={id}
        aria-expanded={!isCollapsed}
        $active={!isCollapsed}
      >
        <Title>{title}</Title>
        <Center>
          <ArrowIcon icon="angle up" $active={isCollapsed} />
        </Center>
      </GreenBox>

      <AnimateHeight
        duration={500}
        height={isCollapsed ? 0 : "auto"}
        id={id}
        aria-hidden={isCollapsed}
      >
        <Content $noContentMargin={noContentMargin}>{children}</Content>
      </AnimateHeight>
    </>
  );
};
