import React from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.less";
import * as Sentry from "@sentry/react";
import { getConfig } from "globalFunctions";
import App from "./App";
import StateProvider from "./StateProvider";
import reducer, { initialState } from "./reducers";

Sentry.init({
  dsn: getConfig().sentry,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0,
  release: `timandre@${process.env.COMMIT_HASH}`,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const container = document.getElementById("app");
const root = createRoot(container!);

const router = sentryCreateBrowserRouter([
  {
    path: "*",
    element: <App />,
  },
]);

root.render(
  <Sentry.ErrorBoundary>
    <StateProvider reducer={reducer} initState={initialState}>
      <RouterProvider router={router} />
    </StateProvider>
  </Sentry.ErrorBoundary>,
);
