import React from "react";
import { useGetAllPubliserteTilsyn } from "hooks/getters/tilsyn";
import { PlaceholderBuilder } from "components/PlaceholderBuilder";
import { AccessToTilsynType, TilsynsStatus } from "ApiClients";
import { getCurrentUser, getUrls } from "globalFunctions";
import { InpageMessage } from "@utdanningsdirektoratet/inpagemessage";
import { Paragraph } from "SharedComponents/components/Headings/Headings";
import { LinkButton } from "@utdanningsdirektoratet/button";
import styled from "styled-components";
import { OversiktCardList } from "./OversiktCardList";
import { TilgangMessage } from "../../components/TilgangMessage";
import { scrollKeys } from "./sessionKeys";

const StyledLinkButton = styled(LinkButton)`
  color: white !important;
  text-decoration: none !important;
`;

const OversiktMine: React.FC = () => {
  const { data: tilsyns } = useGetAllPubliserteTilsyn();

  if (!tilsyns) {
    return <PlaceholderBuilder />;
  }

  const mineTilsyns = tilsyns?.filter((t) => t.isForCurrentUser);

  if (!getCurrentUser()) {
    return (
      <>
        <InpageMessage
          className="u--marginTop2"
          type="info"
          color="blue"
          message={
            <>
              <div>Logg inn for å se dine tilsyn</div>
              <div className="u--marginTop1">
                Vi har faset ut innlogging med UBAS. Bruk Feide eller ID-porten
                når du logger inn.
              </div>
              <div className="u--marginTop1-5">
                <StyledLinkButton
                  href={`${getUrls().loggInn}?returnUrl=${window.location.pathname}`}
                  primary
                >
                  Logg inn
                </StyledLinkButton>
              </div>
            </>
          }
        />
        <TilgangMessage tilgangType={AccessToTilsynType.ReadObfuscatedOnly} />
      </>
    );
  }

  const noApneTilsynsMessage = (
    <p>Du har ikke tilgang til noen åpne tilsyn nå.</p>
  );

  const apne = mineTilsyns.filter(
    (t) => t.status !== TilsynsStatus.TilsynAvsluttet,
  );
  const avsluttede = mineTilsyns.filter(
    (t) => t.status === TilsynsStatus.TilsynAvsluttet,
  );

  return (
    <>
      <Paragraph>
        Her kan friskoler, andre private skoler og private barnehager se sine
        tilsyn. Her finner også barnehagemyndigheter og statsforvaltere tilsyn
        innenfor sine geografiske områder.
      </Paragraph>
      <OversiktCardList
        tilsyns={apne}
        header="Åpne tilsyn"
        highlightCurrentUsersCards
        noTilsynsMessage={noApneTilsynsMessage}
        showAnsvarligSaksbehandler
        scrollKey={scrollKeys.minOversikt}
      />
      {avsluttede.length > 0 ? (
        <OversiktCardList
          tilsyns={avsluttede}
          header="Avsluttede tilsyn"
          highlightCurrentUsersCards
          scrollKey={scrollKeys.minOversikt}
        />
      ) : null}
      <TilgangMessage tilgangType={AccessToTilsynType.ReadObfuscatedOnly} />
    </>
  );
};
export default OversiktMine;
