import React from "react";
import {
  ConfigBuilder,
  RichText as RT,
  RichTextView,
} from "@utdanningsdirektoratet/richtext";
import { FileInput } from "@utdanningsdirektoratet/fileinput";
import { Checkbox } from "@utdanningsdirektoratet/checkbox";
import styled from "styled-components";
import { Vedlegg } from "@utdanningsdirektoratet/vedlegg";
import { hide, show } from "@utdanningsdirektoratet/slowaction";
import { mutate } from "swr";
import { Heading2 } from "SharedComponents/components/Headings/Headings";
import { Box } from "components/Box";
import { Spacer } from "components/Spacer";
import { getConfig, getUrls } from "globalFunctions";
import {
  DeleteDokumentasjonkravDokumentClient,
  InnsendingResponse,
  PubliseringDokumentasjonskravResponse,
  PublisertTilsynResponse,
  UploadDokumentasjonkravVedleggClient,
} from "ApiClients";
import { innsendingsRefreshKey } from "hooks/getters/publiseringInnsendelse";
import { handleError } from "utils/errorhandler";
import { useContextState } from "StateProvider";
import { DokumentasjonskravInnsending } from "./DokumentasjonskravInnsending";

type Props = {
  tilsyn: PublisertTilsynResponse;
  krav: PubliseringDokumentasjonskravResponse;
  innsending?: InnsendingResponse;
  innsendteInnsendinger?: InnsendingResponse[];
  kommentar: string;
  updateKommentar: (kommentar: string) => void;
  svartIkkeAktuelt: boolean;
  updateSvartIkkeAktuelt: (svartIkkeAktuelt: boolean) => void;
  showError: boolean;
};

const RichText = styled(RT)`
  margin-bottom: 2rem;
  min-height: 10rem;
  max-width: 46rem;
`;

const StyledRichTextView = styled(RichTextView)`
  p {
    white-space: normal !important;
  }
`;

const FileInputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const BoldText = styled.p`
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0;
`;

export const validateDokumentasjonskrav = (
  svartIkkeAktuelt: boolean,
  kommentar: string,
  dokumentasjonskravId: string,
  innsending?: InnsendingResponse,
): boolean => {
  if (
    (svartIkkeAktuelt && kommentar === "") ||
    (!svartIkkeAktuelt &&
      (!innsending ||
        innsending.dokumentasjonskravDokumentList.filter(
          (dk) => dk.dokumentasjonskravExternalId === dokumentasjonskravId,
        ).length === 0))
  ) {
    return false;
  }
  return true;
};

const uploadDokumentasjonskravVedleggClient =
  new UploadDokumentasjonkravVedleggClient();
const deleteDokumentasjonskravDokumentClient =
  new DeleteDokumentasjonkravDokumentClient();

export const Dokumentasjonskrav: React.FC<Props> = ({
  tilsyn,
  krav,
  innsending,
  innsendteInnsendinger,
  updateKommentar,
  kommentar,
  updateSvartIkkeAktuelt,
  svartIkkeAktuelt,
  showError,
}) => {
  const license = getConfig().froalaLicense;
  const { dispatch } = useContextState();
  const urls = getUrls();

  const onUpload = async (files: Array<File>) => {
    try {
      const filesToUpload = files.map((file) => ({
        fileName: file.name,
        data: file,
      }));
      await show();
      await uploadDokumentasjonskravVedleggClient.uploadVedleggsToDokumentasjonskrav(
        filesToUpload,
        tilsyn.organisasjonsNummer,
        tilsyn.publiseringId,
        tilsyn.tilsynId,
        krav.dokumentasjonskravExternalId,
      );
      await mutate(innsendingsRefreshKey(tilsyn.publiseringId));
    } catch (e) {
      handleError(e, dispatch);
    } finally {
      await hide();
    }
  };

  const onDelete = async (dokumentId: string) => {
    try {
      await show();
      await deleteDokumentasjonskravDokumentClient.deleteDokument(
        tilsyn.tilsynId,
        dokumentId,
      );
      await mutate(innsendingsRefreshKey(tilsyn.publiseringId));
    } catch (e) {
      handleError(e, dispatch);
    } finally {
      await hide();
    }
  };

  const config = new ConfigBuilder().withStickyToolbar().withImages().build();

  return (
    <Box $background="white" className="u--marginBottom2">
      <Heading2 className="u--marginBottom1">{krav.tittel}</Heading2>
      <StyledRichTextView config={config} html={krav.beskrivelse} />
      <Spacer color="slate5" />
      {innsending &&
        innsending.dokumentasjonskravDokumentList.filter(
          (d) =>
            d.dokumentasjonskravExternalId ===
            krav.dokumentasjonskravExternalId,
        ).length > 0 && (
          <div className="u--marginBottom1">
            {innsending.dokumentasjonskravDokumentList
              .filter(
                (d) =>
                  d.dokumentasjonskravExternalId ===
                  krav.dokumentasjonskravExternalId,
              )
              .map((d) => (
                <div key={d.externalId}>
                  <Vedlegg
                    key={`tildigeredokument-${d.externalId}`}
                    title={d.filnavn}
                    href={`${urls.downloadDokument}/${d.externalId}`}
                    onDelete={async () => {
                      await onDelete(d.externalId);
                    }}
                  />
                </div>
              ))}
          </div>
        )}
      <FileInputWrapper>
        <FileInput
          label="LEGG TIL DOKUMENTASJON"
          multiple
          onChange={onUpload}
          error={
            showError &&
            !svartIkkeAktuelt &&
            !validateDokumentasjonskrav(
              svartIkkeAktuelt,
              kommentar,
              krav.dokumentasjonskravExternalId,
              innsending,
            )
          }
          message="Det må være lastet opp dokumenter her eller være skrevet en redegjørelse for å kunne sende inn."
          accept="
            text/plain,
            text/xml,
            application/pdf,
            image/jpeg,
            text/richtext,
            application/msword,
            application/mspowerpoint,
            application/vnd.ms-excel,
            image/svg+xml,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.openxmlformats-officedocument.wordprocessingml.template,
            application/vnd.openxmlformats-officedocument.presentationml.presentation,
            application/vnd.openxmlformats-officedocument.presentationml.template,
            application/vnd.openxmlformats-officedocument.presentationml.slideshow,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.openxmlformats-officedocument.spreadsheetml.template,
            application/vnd.oasis.opendocument.text,
            application/vnd.oasis.opendocument.spreadsheet"
        />
      </FileInputWrapper>

      <div>Mener dere at dokumentasjonskravet er irrelevant?</div>
      <Checkbox
        label="Vi mener at dokumentasjonskravet er irrelevant"
        checked={svartIkkeAktuelt}
        onChange={() => updateSvartIkkeAktuelt(!svartIkkeAktuelt)}
      />
      <BoldText>Legg til kommentar</BoldText>
      <div>
        {svartIkkeAktuelt
          ? "Gjør rede for hvorfor dere mener at dokumentasjonskravet er irrelevant."
          : "Dersom dere har kommentarer til dokumentasjonen kan dere beskrive det her."}
      </div>
      <RichText
        error={
          showError &&
          svartIkkeAktuelt &&
          !validateDokumentasjonskrav(
            svartIkkeAktuelt,
            kommentar,
            krav.dokumentasjonskravExternalId,
            innsending,
          )
        }
        message="Det må være skrevet en redegjørelse her eller lastet opp dokumenter for å kunne sende inn."
        config={{ heightMin: 100 }}
        license={license}
        onChange={updateKommentar}
        value={kommentar}
        icon="warning"
      />

      {innsendteInnsendinger && innsendteInnsendinger.length > 0 && (
        <div className="u--marginBottom2">
          <div>
            {innsendteInnsendinger &&
              innsendteInnsendinger.map((innsendtInnsending) => (
                <DokumentasjonskravInnsending
                  key={innsendtInnsending.id}
                  krav={krav}
                  innsending={innsendtInnsending}
                />
              ))}
          </div>
        </div>
      )}
    </Box>
  );
};
