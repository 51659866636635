import React from "react";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { Heading3 } from "../../../../components/Headings/Headings";

type IProps = {
  snapshot: SnapshotResponse;
  heading: string;
};

export const Privatskoleloven: React.FC<IProps> = ({ snapshot, heading }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <>
        <Heading3>{heading}</Heading3>
        <p>
          Vi varsler at vi vil halde tilbake tilskot, jf. privatskolelova § 7-2
          a andre ledd.
        </p>
      </>
    );
  }
  return (
    <>
      <Heading3>{heading}</Heading3>
      <p>
        Vi varsler at vi vil holde tilbake tilskudd, jf. privatskoleloven § 7-2
        a andre ledd.
      </p>
    </>
  );
};
