export interface SnapshotResponse {
  omTilsynet?: OmTilsynetSnapshotResponse | undefined;
  opplysningAvSaken?: OpplysningAvSakenSnapshotResponse | undefined;
  regelverkOgVurdering?: RegelverkOgVurderingSnapshotResponse | undefined;
  brudd?: BruddSnapshotResponse | undefined;
  reaksjoner?: ReaksjonerSnapshotResponse | undefined;
  beskrivelseAvTilsynsObjektet?:
    | BeskrivelseAvTilsynsObjektetSnapshotResponse
    | undefined;
  redigertTeksts?: RedigertTekstSnapshotResponse[] | undefined;
}

export interface OmTilsynetSnapshotResponse {
  organisasjonsNummer?: string | undefined;
  tilsynsrettssubjektNavn?: string | undefined;
  tilsynsrettssubjektKommuneNr?: string | undefined;
  tilsynsrettssubjektKommune?: string | undefined;
  tilsynsrettssubjektFylke?: string | undefined;
  saksnummer?: string | undefined;
  arkivServiceSakId?: string | undefined;
  startetDatoUtc?: Date | undefined;
  avsluttetDatoUtc?: Date | undefined;
  malform?: string | undefined;
  lovTittel?: string | undefined;
  lovEnumIdentifier?: LovEnumIdentifier | undefined;
  underenhetOrgNr?: string | undefined;
  underenhetNavn?: string | undefined;
  tilsynForesMedNavn?: string | undefined;
}

export interface OpplysningAvSakenSnapshotResponse {
  dokumentasjonskravs: OpplysningAvSakenDokumentasjonskravSnapshotResponse[];
}

export interface OpplysningAvSakenDokumentasjonskravSnapshotResponse {
  tittel?: string | undefined;
}

export interface RegelverkOgVurderingSnapshotResponse {
  tilsynstemas: RegelverkOgVurderingTilsynstemaSnapshotResponse[];
}

export interface RegelverkOgVurderingTilsynstemaSnapshotResponse {
  tittel?: string | undefined;
  hjemmel?: string | undefined;
  paleggs: RegelverkOgVurderingPaleggSnapshotResponse[];
}

export interface RegelverkOgVurderingPaleggSnapshotResponse {
  tittel?: string | undefined;
  hjemmel?: string | undefined;
  rettsligGrunnlag?: string | undefined;
  kontrollsporsmals: RegelverkOgVurderingKontrollsporsmalSnapshotResponse[];
}

export interface RegelverkOgVurderingKontrollsporsmalSnapshotResponse {
  sporsmalTekst?: string | undefined;
  observasjonOgVurdering?: string | undefined;
  rettsligGrunnlag?: string | undefined;
  svar: KontrollsporsmalSvarType;
  svarDescription: string;
  korreksjonspunktTekst?: string | undefined;
}

export enum KontrollsporsmalSvarType {
  IkkeVurdert = "IkkeVurdert",
  Irrelevant = "Irrelevant",
  Ja = "Ja",
  Nei = "Nei",
}

export enum LovEnumIdentifier {
  Ugyldig = "Ugyldig",
  Kap6A = "Kap6A",
  Opplaeringsloven212 = "Opplaeringsloven212",
  Friskoleloven = "Friskoleloven",
  Folkehoyskoleloven = "Folkehoyskoleloven",
  Barnehageloven = "Barnehageloven",
  Privatskoleloven = "Privatskoleloven",
}

export interface BruddSnapshotResponse {
  kontrollsporsmals: BruddKontrollsporsmalSnapshotResponse[];
}

export interface BruddKontrollsporsmalSnapshotResponse {
  id: number;
  sporsmalTekst?: string | undefined;
  rettsligGrunnlag?: string | undefined;
  svar: KontrollsporsmalSvarType;
  externalId: string;
}

export interface ReaksjonerSnapshotResponse {
  paleggOmRetting?: PaleggOmRettingSnapshotResponse | undefined;
  kravOmTilbakebetaling?: KravOmTilbakebetalingSnapshotResponse | undefined;
  holdeTilbakeTilskudd?: HoldeTilbakeTilskuddSnapshotResponse | undefined;
  permanentStengingAvVirksomhet?:
    | PermanentStengingAvVirksomhetSnapshotResponse
    | undefined;
  tidsbegrensetStengingAvVirksomhet?:
    | TidsbegrensetStengingAvVirksomhetSnapshotResponse
    | undefined;
  trekkeTilbakeGodkjenning?:
    | TrekkeTilbakeGodkjenningSnapshotResponse
    | undefined;
}

export interface PaleggOmRettingSnapshotResponse {
  isValgt: boolean;
  kreverInnsending: boolean;
  tilsynstemas: PaleggOmRettingTilsynstemaSnapshotResponse[];
}

export interface PaleggOmRettingTilsynstemaSnapshotResponse {
  tittel?: string | undefined;
  hjemmel?: string | undefined;
  paleggs: PaleggOmRettingPaleggSnapshotResponse[];
}

export interface PaleggOmRettingPaleggSnapshotResponse {
  tittel?: string | undefined;
  hjemmel?: string | undefined;
  order: number;
  korreksjonspunkts: PaleggOmRettingKorreksjonspunktSnapshotResponse[];
}

export interface PaleggOmRettingKorreksjonspunktSnapshotResponse {
  orderLetter: string;
  korreksjonspunktTekst?: string | undefined;
}

export interface KravOmTilbakebetalingSnapshotResponse {
  isValgt: boolean;
  totalSum: number;
  kommentar?: string | undefined;
  tilsynstemas: KravOmTilbakebetalingTilsynstemaSnapshotResponse[];
}

export interface KravOmTilbakebetalingTilsynstemaSnapshotResponse {
  id: number;
  tittel?: string | undefined;
  tilbakebetalingkravs: TilbakebetalingkravSnapshotResponse[];
}

export interface TilbakebetalingkravSnapshotResponse {
  id: number;
  beskrivelse?: string | undefined;
  belop?: number | undefined;
  tilsynstemaId: number;
  lastModifiedUtc: Date;
  sistEndretAv: string;
  externalId: string;
}

export interface HoldeTilbakeTilskuddSnapshotResponse {
  isValgt: boolean;
  kommentar?: string | undefined;
}

export interface PermanentStengingAvVirksomhetSnapshotResponse {
  isValgt: boolean;
  kommentar?: string | undefined;
}

export interface TidsbegrensetStengingAvVirksomhetSnapshotResponse {
  isValgt: boolean;
  kommentar?: string | undefined;
}

export interface TrekkeTilbakeGodkjenningSnapshotResponse {
  isValgt: boolean;
  kommentar?: string | undefined;
}

export interface BeskrivelseAvTilsynsObjektetSnapshotResponse {
  id: number;
  lastModifiedUtc: Date;
  sistEndretAv: string;
  tittel: string;
  beskrivelse: string;
}

export interface RedigertTekstSnapshotResponse {
  id: number;
  navn: string;
  tekst: string;
}

export enum RapportType {
  Ukjent = "Ukjent",
  Forelopig = "Forelopig",
  Endelig = "Endelig",
}

export interface TilsynsRapportComputedMetadata {
  forelopigRapportSendtTilTilsynsobjektDatoUtc?: Date | undefined;
  latestForelopigRapportFristDateUtc?: Date | undefined;
  latestInnsendingInnsendtDateUtc?: Date | undefined;
  latestInnsendigHadMerknad?: boolean | undefined;
}

export enum TilsynsStatus {
  IkkeStartet = "IkkeStartet",
  TilsynApent = "TilsynApent",
  DokumentasjonskravPublisert = "DokumentasjonskravPublisert",
  DokumentasjonMottatt = "DokumentasjonMottatt",
  DokumentasjonFristUtlopt = "DokumentasjonFristUtlopt",
  ForelopigTilsynsrapportKlarTilGodkjenning = "ForelopigTilsynsrapportKlarTilGodkjenning",
  ForelopigTilsynsrapportIkkeGodkjent = "ForelopigTilsynsrapportIkkeGodkjent",
  ForelopigTilsynsrapportGodkjent = "ForelopigTilsynsrapportGodkjent",
  ForelopigTilsynsrapportPublisert = "ForelopigTilsynsrapportPublisert",
  TilbakemeldingForelopigRapportMottatt = "TilbakemeldingForelopigRapportMottatt",
  TilbakemeldingForelopigRapportFristUtlopt = "TilbakemeldingForelopigRapportFristUtlopt",
  TilsynsrapportKlarTilGodkjenning = "TilsynsrapportKlarTilGodkjenning",
  TilsynsrapportIkkeGodkjent = "TilsynsrapportIkkeGodkjent",
  TilsynsrapportGodkjent = "TilsynsrapportGodkjent",
  TilsynsrapportPublisert = "TilsynsrapportPublisert",
  ErklaringOgRedegjorelseMottatt = "ErklaringOgRedegjorelseMottatt",
  RettefristUtlopt = "RettefristUtlopt",
  KlageMottatt = "KlageMottatt",
  KlageOversendtKd = "KlageOversendtKd",
  KlageAvgjort = "KlageAvgjort",
  TilsynAvsluttet = "TilsynAvsluttet",
}
