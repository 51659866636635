import React from "react";
import RapportBrudd from "SharedComponents/Rapport/RapportBrudd";
import { Box } from "components/Box";
import { PublisertTilsynResponse } from "../../../ApiClients";
import { LenkeTilRapport } from "../components/LenkeTilRapport/LenkeTilRapport";

type IProps = {
  tilsyn: PublisertTilsynResponse;
  isForelopigRapport: boolean;
};

export const WriteAccessTilsynsrapport: React.FC<IProps> = ({
  tilsyn,
  isForelopigRapport,
}) => {
  return (
    <>
      <Box $background="white" className="u--marginBottom2">
        <RapportBrudd
          snapshot={tilsyn.snapshot}
          withExpandable={false}
          isForelopigRapport={isForelopigRapport}
        />
      </Box>
      <LenkeTilRapport tilsyn={tilsyn} />
    </>
  );
};
