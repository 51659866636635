import React from "react";
import styled from "styled-components";
import {
  InnsendingResponse,
  InnsendingStatus,
  PubliseringMalType,
} from "ApiClients";
import { Heading2 } from "SharedComponents/components/Headings/Headings";
import { Innsending } from "./Innsending";

const Container = styled.div`
  margin: 5rem 0;
`;

type IProps = {
  previousInnsendings: InnsendingResponse[];
  publiseringMalType: PubliseringMalType;
  publiseringId: string;
};

const PreviousInnsendingsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const PreviousInnsendings: React.FC<IProps> = ({
  previousInnsendings,
  publiseringMalType,
  publiseringId,
}) => {
  if (previousInnsendings.length === 0) return null;

  const previousInnsendingsComponents = previousInnsendings
    .filter((innsending) => innsending.status === InnsendingStatus.Innsendt)
    .map((innsending) => {
      return (
        <Innsending
          innsending={innsending}
          publiseringMalType={publiseringMalType}
          publiseringId={publiseringId}
          key={`innsendelse-${innsending.id}`}
        />
      );
    });

  return (
    <Container>
      <Heading2>Tidligere innsendinger</Heading2>
      <PreviousInnsendingsContainer>
        {previousInnsendingsComponents}
      </PreviousInnsendingsContainer>
    </Container>
  );
};
