import React from "react";
import { capitalize } from "../../../../utils/stringUtils";
import { LovEnumIdentifier, SnapshotResponse } from "../../../Types/Snapshot";
import { Heading2 } from "../../../../components/Headings/Headings";
import { DATE_FORMAT, formatDate } from "../../../../utils/date";
import { getText } from "../../../Overskrifts/Overskrifts";
import { RedigerbarTekst } from "../../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
  innsendingFristDatoUtc?: Date;
  highlightFrist?: boolean;
};

export const ErklaeringOmRetting: React.FC<IProps> = ({
  snapshot,
  innsendingFristDatoUtc,
  highlightFrist,
}) => {
  if (!snapshot.reaksjoner?.paleggOmRetting?.isValgt) {
    return null;
  }

  const LovNotPrivatSkoleOrFolkehoyskole =
    snapshot.omTilsynet?.lovEnumIdentifier !==
      LovEnumIdentifier.Privatskoleloven &&
    snapshot.omTilsynet?.lovEnumIdentifier !==
      LovEnumIdentifier.Folkehoyskoleloven;

  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    const whoShouldDeclare =
      snapshot.omTilsynet?.lovEnumIdentifier ===
      LovEnumIdentifier.Barnehageloven
        ? "barnehageigar"
        : "styret";
    const fristText = `Fristen er ${innsendingFristDatoUtc ? formatDate(innsendingFristDatoUtc.toString(), DATE_FORMAT) : "ikkje satt"}.`;
    return (
      <>
        {LovNotPrivatSkoleOrFolkehoyskole && (
          <RedigerbarTekst
            navn="EndeligRapportHvaSkjerNaInnledning"
            snapshot={snapshot}
          >
            <p>
              {snapshot.reaksjoner.paleggOmRetting.kreverInnsending
                ? "De skal med ein gong sette i verk tiltak for å rette brot på regelverket. Vi vil ikkje avslutte tilsynet før de har retta."
                : "De skal sette i verk tiltak for å rette brot på regelverket."}
            </p>
          </RedigerbarTekst>
        )}
        {snapshot.reaksjoner.paleggOmRetting.kreverInnsending && (
          <>
            <RedigerbarTekst
              navn="HvaSkjerNaErklaeringOmRettingOverskrift"
              snapshot={snapshot}
              wrapper={Heading2}
              noFormatting
            >
              {getText(
                snapshot.omTilsynet?.malform,
                (t) => t.erklaeringOgRedegjorelse,
              )}
            </RedigerbarTekst>
            <RedigerbarTekst
              navn="HvaSkjerNaErklaeringOmRetting"
              snapshot={snapshot}
            >
              {LovNotPrivatSkoleOrFolkehoyskole ? (
                <p>
                  {capitalize(whoShouldDeclare)} skal erklære at rettinga er
                  gjennomført og gjere greie for korleis de har retta.
                  Innsending av erklæring og utgreiinga gjer de her, på
                  tilsyn.udir.no.
                </p>
              ) : (
                <>
                  <p>
                    De skal iverksette tiltak for å rette brot på regelverket
                    med det same. Vi vil ikkje avslutte tilsynet før de har
                    retta. De skal erklære at rettinga er gjennomført og greie
                    ut om korleis de har retta. De bør gjere greie for
                  </p>
                  <ul>
                    <li>
                      kva tiltak de har sett i verk eller planlegg å setje i
                      verk for å rette regelverksbrotet
                    </li>
                    <li>
                      korleis de planlegg å følgje med på at tiltaka blir sett i
                      verk og regelverket blir følgt
                    </li>
                    <li>
                      kva for interne fristar de har sett for å sikre framdrift
                      og gjennomføring
                    </li>
                  </ul>
                </>
              )}
            </RedigerbarTekst>
            <p>{highlightFrist ? <strong>{fristText}</strong> : fristText}</p>
          </>
        )}
      </>
    );
  }

  const whoShouldDeclare =
    snapshot.omTilsynet?.lovEnumIdentifier === LovEnumIdentifier.Barnehageloven
      ? "barnehageeier"
      : "styret";
  const fristText = `Fristen er ${innsendingFristDatoUtc ? formatDate(innsendingFristDatoUtc.toString(), DATE_FORMAT) : "ikke satt"}.`;
  const tilbakebetalingsFristText = `Fristen for å betale tilbake, redegjøre og dokumentere tilbakebetalingen er ${
    innsendingFristDatoUtc
      ? formatDate(innsendingFristDatoUtc.toString(), DATE_FORMAT)
      : "ikke satt"
  }.`;

  return (
    <>
      {LovNotPrivatSkoleOrFolkehoyskole && (
        <RedigerbarTekst
          navn="EndeligRapportHvaSkjerNaInnledning"
          snapshot={snapshot}
        >
          <p>
            {snapshot.reaksjoner.paleggOmRetting.kreverInnsending
              ? "Dere skal iverksette tiltak for å rette brudd på regelverket umiddelbart. Vi vil ikke avslutte tilsynet før dere har rettet."
              : "Dere skal iverksette tiltak for å rette brudd på regelverket."}
          </p>
        </RedigerbarTekst>
      )}
      {snapshot.reaksjoner.paleggOmRetting.kreverInnsending && (
        <>
          <RedigerbarTekst
            navn="HvaSkjerNaErklaeringOmRettingOverskrift"
            snapshot={snapshot}
            wrapper={Heading2}
            noFormatting
          >
            {getText(
              snapshot.omTilsynet?.malform,
              (t) => t.erklaeringOgRedegjorelse,
            )}
          </RedigerbarTekst>
          <RedigerbarTekst
            navn="HvaSkjerNaErklaeringOmRetting"
            snapshot={snapshot}
          >
            {LovNotPrivatSkoleOrFolkehoyskole ? (
              <p>
                {capitalize(whoShouldDeclare)} skal erklære at rettingen er
                gjennomført og redegjøre for hvordan dere har rettet. Innsending
                av erklæringen og redegjørelsen gjør dere her, på
                tilsyn.udir.no.
              </p>
            ) : (
              <>
                <p>
                  Dere skal iverksette tiltak for å rette brudd på regelverket
                  umiddelbart. Vi vil ikke avslutte tilsynet før dere har
                  rettet. Dere skal erklære at rettingen er gjennomført og
                  redegjøre for hvordan dere har rettet. Dere bør redegjøre for
                </p>
                <ul>
                  <li>
                    hvilke tiltak dere har satt i verk eller planlegger å sette
                    i verk for å rette regelverksbruddet
                  </li>
                  <li>
                    hvordan dere planlegger å følge med på at tiltakene blir
                    iverksatt og regelverket blir fulgt
                  </li>
                  <li>
                    hvilke interne frister dere har satt for å sikre fremdrift
                    og gjennomføring
                  </li>
                </ul>
              </>
            )}
          </RedigerbarTekst>
          <p>{highlightFrist ? <strong>{fristText}</strong> : fristText}</p>
        </>
      )}
      {snapshot.omTilsynet?.lovEnumIdentifier ===
        LovEnumIdentifier.Privatskoleloven &&
        snapshot.reaksjoner.kravOmTilbakebetaling?.isValgt && (
          <>
            <RedigerbarTekst
              navn="HvaSkjerNaInnbetalingOverskrift"
              snapshot={snapshot}
              wrapper={Heading2}
              noFormatting
            >
              Redegjørelse og dokumentasjon på innbetaling av
              tilbakebetalingskrav
            </RedigerbarTekst>
            <RedigerbarTekst navn="HvaSkjerNaInnbetaling" snapshot={snapshot}>
              <p>
                Når tilbakebetalingskravet er betalt, skal styret redegjøre for
                og dokumentere hvordan tilbakebetalingskravet er dekket.
                Innsending av redegjørelsen og dokumentasjonen gjør dere her, på
                tilsyn.udir.no.
              </p>
            </RedigerbarTekst>
            <p>
              {highlightFrist ? (
                <strong>{tilbakebetalingsFristText}</strong>
              ) : (
                tilbakebetalingsFristText
              )}
            </p>
          </>
        )}
    </>
  );
};
