import React from "react";
import { ErklaeringOmRetting } from "../../HvaSkjerNa/EndeligRapport/ErklaeringOmRetting/ErklaeringOmRetting";
import { SnapshotResponse } from "../../Types/Snapshot";

type IProps = {
  snapshot: SnapshotResponse;
  innsendingFristDatoUtc?: Date;
};

export const ErklaeringOgRedegjoreInnsendingIngress: React.FC<IProps> = ({
  snapshot,
  innsendingFristDatoUtc,
}) => {
  return (
    <ErklaeringOmRetting
      snapshot={snapshot}
      innsendingFristDatoUtc={innsendingFristDatoUtc}
      highlightFrist
    />
  );
};
