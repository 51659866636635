import React from "react";
import styled from "styled-components";
import { Heading2 } from "@utdanningsdirektoratet/styles/Headings";
import { DATE_FORMAT, formatDate } from "SharedComponents/utils/date";
import { Icon } from "@utdanningsdirektoratet/icon";
import { HistoricTilsynsrapportsViewModel } from "../../ApiClients";
import { getUrls } from "../../globalFunctions";

type HistoricRapportCardProps = {
  rapport: HistoricTilsynsrapportsViewModel;
};

const Card = styled.div`
  background-color: var(--green5);
  padding: 2rem;
  border-radius: var(--borderradius1);
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  color: var(--green7);
`;

const LinkWrapper = styled.div`
  font-weight: bold;
  margin-top: 1rem;
`;

const Heading2Styled = styled(Heading2)`
  margin-bottom: 0.5rem;
`;

export const HistoricRapportCard: React.FC<HistoricRapportCardProps> = ({
  rapport,
}) => {
  return (
    <Card>
      <Row>
        <div>{rapport.fylke}</div>
        <div>
          Avsluttet:{" "}
          {rapport.tilsynAvsluttetDato ? (
            formatDate(rapport.tilsynAvsluttetDato, DATE_FORMAT)
          ) : (
            <i>ikke satt</i>
          )}
        </div>
      </Row>
      <Heading2Styled>{rapport.aktornavn}</Heading2Styled>
      <div>
        <b>Tilsynstema: </b>
        {rapport.tilsynstema}
      </div>
      {rapport.blobNavn && (
        <LinkWrapper>
          <Icon type="small" icon="text document" placement="before" />
          <a
            href={`${getUrls().downloadRapport}/tidligere/${rapport.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {rapport.filnavn}
          </a>
        </LinkWrapper>
      )}
    </Card>
  );
};
