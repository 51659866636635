import { SnapshotResponse } from "../tekster/Types/Snapshot";
import { CreateCommaSeparatedList } from "./stringUtils";

export const reaksjonerCombiner = (snapshot: SnapshotResponse): string => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    const texts = [];
    if (snapshot.reaksjoner?.kravOmTilbakebetaling?.isValgt) {
      texts.push("at de må betale tilbake tilskot");
    }
    if (snapshot.reaksjoner?.trekkeTilbakeGodkjenning?.isValgt) {
      texts.push("at vi vil trekke tilbake godkjenninga");
    }
    if (snapshot.reaksjoner?.paleggOmRetting?.isValgt) {
      texts.push("at de må rette forholda");
    }
    if (snapshot.reaksjoner?.tidsbegrensetStengingAvVirksomhet?.isValgt) {
      texts.push("at barnehagen må stengast i ein tidsavgrensa periode");
    }
    if (snapshot.reaksjoner?.permanentStengingAvVirksomhet?.isValgt) {
      texts.push("at barnehagen må stengast permanent");
    }
    if (snapshot.reaksjoner?.holdeTilbakeTilskudd?.isValgt) {
      texts.push("at vi vil halde tilbake tilskot");
    }

    return CreateCommaSeparatedList(texts);
  }

  const texts = [];
  if (snapshot.reaksjoner?.kravOmTilbakebetaling?.isValgt) {
    texts.push("at dere må betale tilbake tilskudd");
  }
  if (snapshot.reaksjoner?.trekkeTilbakeGodkjenning?.isValgt) {
    texts.push("at vi vil trekke tilbake godkjenningen");
  }
  if (snapshot.reaksjoner?.paleggOmRetting?.isValgt) {
    texts.push("at dere må rette forholdene");
  }
  if (snapshot.reaksjoner?.tidsbegrensetStengingAvVirksomhet?.isValgt) {
    texts.push("at barnehagen må stenges i en tidsbegrenset periode");
  }
  if (snapshot.reaksjoner?.permanentStengingAvVirksomhet?.isValgt) {
    texts.push("at barnehagen må stenges permanent");
  }
  if (snapshot.reaksjoner?.holdeTilbakeTilskudd?.isValgt) {
    texts.push("at vi vil holde tilbake tilskudd");
  }

  return CreateCommaSeparatedList(texts);
};
