import React from "react";
import { SnapshotResponse } from "../../Types/Snapshot";
import { reaksjonerCombiner } from "../../../utils/reaksjonerCombiner";
import { RapportIngressInnledning } from "../Common/RapportIngressInnledning";
import { RedigerbarTekst } from "../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
};

export const ForelopigRapportOverordnetIngress: React.FC<IProps> = ({
  snapshot,
}) => {
  const reaksjoner = reaksjonerCombiner(snapshot);

  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <RedigerbarTekst
        navn="ForelopigRapportOverordnetIngress"
        snapshot={snapshot}
      >
        <p>
          <RapportIngressInnledning snapshot={snapshot} isForelopigRapport /> Vi
          har avdekka mogleg brot på regelverket og varslar {reaksjoner}. Dette
          er eit varsel om vedtak, jf. forvaltningslova § 16. De har rett til å
          uttale dykk før vi fattar eit eventuelt vedtak.
        </p>
      </RedigerbarTekst>
    );
  }

  return (
    <RedigerbarTekst
      navn="ForelopigRapportOverordnetIngress"
      snapshot={snapshot}
    >
      <p>
        <RapportIngressInnledning snapshot={snapshot} isForelopigRapport /> Vi
        har avdekket mulig brudd på regelverket og varsler {reaksjoner}. Dette
        er et varsel om vedtak, jf. forvaltningsloven § 16. Dere har rett til å
        uttale dere før et eventuelt vedtak.
      </p>
    </RedigerbarTekst>
  );
};
