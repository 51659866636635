import React from "react";
import styled from "styled-components";
import { MainPanel as MP } from "@utdanningsdirektoratet/layout";
import { useGetInnsendings } from "../../hooks/getters/publiseringInnsendelse";
import {
  InnsendingStatus,
  PubliseringMalType,
  PublisertTilsynResponse,
} from "../../ApiClients";
import { GjeldendeInnsendings } from "./Innsendings/GjeldeneInnsendings";
import { SendInnUttalelse } from "./Uttalelse/SendInnUttalelse";
import { SendInnErkleringOmRetting } from "./ErklæringOmRetting/SendInnErkleringOmRetting";
import { PreviousInnsendings } from "./Innsendings/PreviousInnsendings";

const MainPanel = styled(MP)`
  max-width: 50rem;
  margin: 0 auto;
  min-height: 100vh;

  padding-bottom: 4rem;
  @media (max-width: 979px) {
    padding-bottom: 2rem;
  }
`;

type IProps = {
  tilsyn: PublisertTilsynResponse;
};

const RapportInnsending: React.FC<IProps> = ({ tilsyn }) => {
  const { data: innsendings } = useGetInnsendings(tilsyn.publiseringId);
  const innsending = (innsendings ?? []).find(
    (i) => i.status === InnsendingStatus.UnderArbeid,
  );
  const innsendteInnsendinger = innsendings
    ?.filter((i) => i.status === InnsendingStatus.Innsendt)
    .filter((i) => i.innsendtDateUtc)
    .sort(
      (a, b) =>
        new Date(b.innsendtDateUtc!).getTime() -
        new Date(a.innsendtDateUtc!).getTime(),
    );
  const tidligereInnsendteInnsendinger = !tilsyn.canSendInn
    ? innsendteInnsendinger?.slice(1)
    : innsendteInnsendinger;

  const gjeldeneInnsending = () => {
    if (!tilsyn.canSendInn && innsendteInnsendinger) {
      return innsendteInnsendinger.length > 0 ? (
        <GjeldendeInnsendings
          innsending={innsendteInnsendinger[0]}
          publiseringMalType={tilsyn.publiseringMalType}
          publiseringId={tilsyn.publiseringId}
        />
      ) : null;
    }
    switch (tilsyn.publiseringMalType) {
      case PubliseringMalType.ForelopigTilsynsrapport:
        return <SendInnUttalelse uttalelse={innsending} tilsyn={tilsyn} />;
      case PubliseringMalType.Tilsynsrapport:
        return (
          <SendInnErkleringOmRetting
            tilsyn={tilsyn}
            erkleringOmRetting={innsending}
          />
        );
      default:
        return null;
    }
  };
  return (
    <MainPanel color="white">
      {gjeldeneInnsending()}
      <PreviousInnsendings
        previousInnsendings={tidligereInnsendteInnsendinger ?? []}
        publiseringMalType={tilsyn.publiseringMalType}
        publiseringId={tilsyn.publiseringId}
      />
    </MainPanel>
  );
};
export default RapportInnsending;
