import React from "react";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { RedigerbarTekst } from "../../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
};

export const Privatskoleloven: React.FC<IProps> = ({ snapshot }) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <RedigerbarTekst
        navn="EndeligRapportReaksjonerIngress"
        snapshot={snapshot}
      >
        <p>
          Vi har konstatert at de ikkje oppfyller regelverket på alle områda.
          Styret har den øvste leiinga av skolen og skal sjå til at skolen blir
          driven i samsvar med regelverket, jf. privatskolelova § 5-2.
        </p>
      </RedigerbarTekst>
    );
  }

  return (
    <RedigerbarTekst navn="EndeligRapportReaksjonerIngress" snapshot={snapshot}>
      <p>
        Vi har konstatert at dere ikke oppfyller regelverket på alle områder.
        Styret har den øverste ledelsen av skolen, og skal se til at skolen blir
        drevet i samsvar med regelverket, jf. privatskoleloven § 5-2.
      </p>
    </RedigerbarTekst>
  );
};
