import React from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import { useGetPublisertTilsyn } from "hooks/getters/tilsyn";
import { Link as UrcLink } from "@utdanningsdirektoratet/link/index";
import {
  Heading2,
  PageTitle,
} from "SharedComponents/components/Headings/Headings";
import styled from "styled-components";
import routes from "routes";
import { InnsendingResponse, PubliseringMalType } from "ApiClients";
import { InpageMessage } from "@utdanningsdirektoratet/inpagemessage";
import { Innsending } from "./Innsending";

const Container = styled.div`
  margin: 2rem 0;
`;

type IProps = {
  innsending: InnsendingResponse;
  publiseringMalType: PubliseringMalType;
  publiseringId: string;
};

const SendtInn = styled(InpageMessage)`
  margin: 1rem 0;
`;

export const GjeldendeInnsendings: React.FC<IProps> = ({
  innsending,
  publiseringMalType,
  publiseringId,
}) => {
  const { tilsynId } = useParams<"tilsynId">() as { tilsynId: string };
  const { data: tilsynPublisering } = useGetPublisertTilsyn(tilsynId);

  return (
    <>
      <div className="u--marginTop1 u--marginBottom2">
        <UrcLink iconLeft="angle left" text="Tilbake til rapporten">
          {(props) => (
            <Link
              to={generatePath(routes.tilsyn, {
                tilsynId: tilsynPublisering?.tilsynId,
              })}
              className={props.className}
            >
              {props.children}
            </Link>
          )}
        </UrcLink>
      </div>
      <Container>
        <PageTitle className="u--marginTop1">Innsendinger </PageTitle>
        <Heading2 className="u--marginTop1">Siste innsending</Heading2>
        <SendtInn
          type="success"
          message="Uttalelsen er sendt inn."
          icon="circle check"
        />
        <Innsending
          innsending={innsending}
          publiseringId={publiseringId}
          publiseringMalType={publiseringMalType}
        />
      </Container>
    </>
  );
};
