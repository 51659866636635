import React from "react";
import { generatePath } from "react-router-dom";
import routes from "routes";
import { PubliseringMalType, PublisertTilsynResponse } from "ApiClients";
import { LinkButton } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";

type IProps = {
  tilsyn: PublisertTilsynResponse;
};

const getButtonText: (tilsyn: PublisertTilsynResponse) => string = (tilsyn) => {
  if (tilsyn.publiseringMalType === PubliseringMalType.Tilsynsrapport) {
    return tilsyn.canSendInn ? "Gå til innsending" : "Se innsending";
  }
  return tilsyn.canSendInn ? "Send inn uttalelse" : "Les uttalelse";
};

const getButtonHref: (tilsyn: PublisertTilsynResponse) => string = (tilsyn) => {
  return generatePath(routes.innsending, { tilsynId: tilsyn.tilsynId });
};

export const WriteAccessContent: React.FC<IProps> = ({ tilsyn }) => {
  return (
    <LinkButton
      aria-label={getButtonText(tilsyn)}
      primary
      href={getButtonHref(tilsyn)}
    >
      {getButtonText(tilsyn)}
      <Icon type="wide" icon="arrow right long" className="u--marginLeft1" />
    </LinkButton>
  );
};
