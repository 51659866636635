/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import styled from "styled-components";
import { Card } from "@utdanningsdirektoratet/card";
import {
  TilsynsStatus,
  PubliseringMalType,
  PublisertTilsynInfoResponse,
} from "ApiClients";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { generatePath, Link } from "react-router-dom";
import routes from "routes";
import { DATE_FORMAT } from "SharedComponents/utils/date";

const RightPaddedSpan = styled.span`
  padding-right: 2rem;
`;

type OversiktCardProps = {
  tilsyn: PublisertTilsynInfoResponse;
  color: "lightazure" | "green";
  showAnsvarligSaksbehandler?: boolean;
};

const getFristText: (tilsyn: PublisertTilsynInfoResponse) => string = (
  tilsyn,
) => {
  if (
    tilsyn.status === TilsynsStatus.TilsynAvsluttet ||
    tilsyn.fristHasExpired
  ) {
    return "";
  }

  const fristString = formatDate(tilsyn.frist, DATE_FORMAT);
  switch (tilsyn.latestPubliseringMalType) {
    case PubliseringMalType.InnsendingAvDokumentasjon:
      return `  —  Frist ${fristString}`;
    case PubliseringMalType.ForelopigTilsynsrapport:
      return `  —  Frist for å sende inn uttalelse: ${fristString}`;
    case PubliseringMalType.Tilsynsrapport:
      return tilsyn.canSendIn
        ? `  —  Frist for innsending er ${fristString}`
        : "";
    default:
      return "";
  }
};

const getStatusText: (
  tilsyn: PublisertTilsynInfoResponse,
) => React.ReactNode = (tilsyn) => {
  if (tilsyn.status === TilsynsStatus.TilsynAvsluttet) {
    return (
      <>
        <RightPaddedSpan>
          <strong>Åpnet: </strong>{" "}
          {formatDate(tilsyn.tilsynStartedDatoUtc!, DATE_FORMAT)}
        </RightPaddedSpan>
        <span>
          <strong>Avsluttet: </strong>{" "}
          {formatDate(tilsyn.tilsynAvsluttetDatoUtc!, DATE_FORMAT)}
        </span>
      </>
    );
  }

  switch (tilsyn.latestPubliseringMalType) {
    case PubliseringMalType.InnsendingAvDokumentasjon:
      return <strong>Innsending av dokumentasjon</strong>;
    case PubliseringMalType.ForelopigTilsynsrapport:
      return <strong>Foreløpig tilsynsrapport</strong>;
    case PubliseringMalType.Tilsynsrapport:
      return tilsyn.hasKravOmTilbakebetaling || tilsyn.hasPaleggOmRetting ? (
        <strong>Tilsynsrapport</strong>
      ) : (
        <RightPaddedSpan>
          <strong>Åpnet: </strong>{" "}
          {formatDate(tilsyn.tilsynStartedDatoUtc!, DATE_FORMAT)}
        </RightPaddedSpan>
      );
    default:
      return null;
  }
};

export const OversiktCard: React.FC<OversiktCardProps> = ({
  tilsyn,
  color,
  showAnsvarligSaksbehandler,
}) => {
  return (
    <Card
      color={color}
      title={tilsyn.navn}
      status={
        <span>
          {getStatusText(tilsyn)}
          {getFristText(tilsyn)}
        </span>
      }
      rightLabel={
        showAnsvarligSaksbehandler
          ? `Ansvarlig saksbehandler: ${tilsyn.ansvarligSaksbehandler}`
          : undefined
      }
    >
      {(props) => (
        <Link
          {...props}
          to={generatePath(routes.tilsyn, { tilsynId: tilsyn.id })}
        />
      )}
    </Card>
  );
};
