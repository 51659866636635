import styled from "styled-components";
import colors from "@utdanningsdirektoratet/styles/colors";

export const Spacer = styled.hr<{ color?: keyof typeof colors }>`
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: none;
  border-bottom: 2px solid
    ${(props) => (props.color ? colors[props.color] : colors.green5)};
`;
