import React from "react";
import { LovEnumIdentifier } from "../../../Types/Snapshot";
import { Privatskoleloven } from "./Privatskoleloven";
import RichTextViewWithSladd from "../../../../components/RichTextWithSladd/RichTextViewWithSladd";
import { IReaksjonProps } from "../../Common/reaksjonInterfaces";
import { Folkehoyskoleloven } from "./Folkehoyskoleloven";

export const ForelopigRapportTrekkeTilbakeGodkjenning: React.FC<
  IReaksjonProps
> = ({ snapshot, withoutObfuscationBackground }) => {
  if (!snapshot.reaksjoner?.trekkeTilbakeGodkjenning?.isValgt) {
    return null;
  }
  const comment = snapshot.reaksjoner?.trekkeTilbakeGodkjenning?.kommentar;

  return (
    <>
      {snapshot.omTilsynet?.lovEnumIdentifier ===
      LovEnumIdentifier.Folkehoyskoleloven ? (
        <Folkehoyskoleloven snapshot={snapshot} />
      ) : (
        <Privatskoleloven snapshot={snapshot} />
      )}
      {comment && (
        <RichTextViewWithSladd
          html={comment}
          withoutObfuscationBackground={withoutObfuscationBackground}
        />
      )}
      {snapshot.omTilsynet?.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven && (
        <b>
          Reaksjonen trekke tilbake godkjenning gjelder ikke for barnehageloven.
        </b>
      )}
    </>
  );
};
