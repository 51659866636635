import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Tabs as UrcTabs, Tab } from "@utdanningsdirektoratet/tabs";
import routes from "routes";

export const Tabs: React.FC = () => {
  const location = useLocation();
  return (
    <UrcTabs outline>
      <Tab
        id="skole"
        label="Skole"
        active={location.pathname === routes.skoleTilsyn}
      >
        {(props) => <Link to={routes.skoleTilsyn} {...props} />}
      </Tab>
      <Tab
        id="barnehage"
        label="Barnehage"
        active={location.pathname === routes.barnehageTilsyn}
      >
        {(props) => <Link to={routes.barnehageTilsyn} {...props} />}
      </Tab>
      <Tab
        id="mine"
        label="Mine tilsyn"
        active={location.pathname === routes.mineTilsyn}
      >
        {(props) => <Link to={routes.mineTilsyn} {...props} />}
      </Tab>
    </UrcTabs>
  );
};
