import { IResponseWithReset } from "@utdanningsdirektoratet/hooks";
import useSwrWithErrorHandling from "../useSwrWithErrorHandling";
import {
  GetPubliseringDokumentasjonskravClient,
  PubliseringDokumentasjonskravResponse,
} from "../../ApiClients";

const getPubliseringDokumentasjonskravClient =
  new GetPubliseringDokumentasjonskravClient();

export const publiseringDokumentasjonskravRefreshKey = (
  id?: number | string,
): string | null => (id ? `publiseringDokumentasjonskrav/${id}` : null);

export const useGetPubliseringDokumentasjonskravs = (
  publiseringExternalId?: string,
): IResponseWithReset<PubliseringDokumentasjonskravResponse[]> => {
  return useSwrWithErrorHandling(
    publiseringDokumentasjonskravRefreshKey(publiseringExternalId),
    () =>
      getPubliseringDokumentasjonskravClient.getAllForPublisering(
        publiseringExternalId!,
      ),
  );
};
