import { LovEnumIdentifier, PublisertTilsynResponse } from "ApiClients";
import React from "react";
import { Heading2 } from "SharedComponents/components/Headings/Headings";
import { formatDate } from "SharedComponents/utils/date";
import styled from "styled-components";

const Normal = styled.span`
  font-weight: normal;
`;

type IProps = {
  tilsyn: PublisertTilsynResponse;
  isInnsendingSide: boolean;
};

export const InnsendingAvDokumentasjonInfoBoxTexts: React.FC<IProps> = ({
  tilsyn,
  isInnsendingSide,
}) => {
  const heading = "Innsending av dokumentasjon";

  if (
    isInnsendingSide &&
    !tilsyn.canSendInn &&
    !tilsyn.publiseringFristUtloptUtenInnsending
  ) {
    return <Heading2>Innsendt dokumentasjon</Heading2>;
  }

  if (tilsyn.snapshot.omTilsynet?.malform === "Nynorsk") {
    const hjemmel = (() => {
      switch (tilsyn.snapshot.omTilsynet?.lovEnumIdentifier) {
        case LovEnumIdentifier.Barnehageloven:
          return "barnehagelova § 56 sjuande ledd";
        case LovEnumIdentifier.Privatskoleloven:
          return "privatskolelova § 7-2";
        case LovEnumIdentifier.Folkehoyskoleloven:
          return "forskrift til folkehøgskolelova § 13";
        default:
          return "";
      }
    })();
    return (
      <>
        <Heading2>
          {heading}
          <Normal>
            {tilsyn.innsendingFristDatoUtc &&
              ` - Frist: ${formatDate(tilsyn.innsendingFristDatoUtc)}`}
          </Normal>
        </Heading2>
        <p>
          De har ei plikt til å sende inn dokumentasjon i tilsynet, jf.{" "}
          {hjemmel}.
        </p>
        {tilsyn.canSendInn && isInnsendingSide && (
          <p>
            <strong>
              Vi ber dykk sende inn dokumentasjon som beskrive nedanfor.
            </strong>
          </p>
        )}
      </>
    );
  }
  const hjemmel = (() => {
    switch (tilsyn.snapshot.omTilsynet?.lovEnumIdentifier) {
      case LovEnumIdentifier.Barnehageloven:
        return "barnehageloven § 56 syvende ledd";
      case LovEnumIdentifier.Privatskoleloven:
        return "privatskoleloven § 7-2";
      case LovEnumIdentifier.Folkehoyskoleloven:
        return "forskrift til folkehøyskoleloven § 13";
      default:
        return "";
    }
  })();
  return (
    <>
      <Heading2>
        {heading}
        <Normal>
          {tilsyn.innsendingFristDatoUtc &&
            ` - Frist: ${formatDate(tilsyn.innsendingFristDatoUtc)}`}
        </Normal>
      </Heading2>
      <p>
        Dere har en plikt til å sende inn dokumentasjon i tilsynet, jf.{" "}
        {hjemmel}.
      </p>
      {tilsyn.canSendInn && isInnsendingSide && (
        <p>
          <strong>
            Vi ber dere sende inn dokumentasjon som beskrevet nedenfor.
          </strong>
        </p>
      )}
    </>
  );
};
