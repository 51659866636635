import React from "react";
import { SnapshotResponse } from "../Types/Snapshot";
import { tilsynstemaOgHjemmelCombiner } from "../../utils/tilsynstemaOgHjemmelCombiner";
import { hasBrudd } from "../../utils/bruddUtils";
import { RedigerbarTekst } from "../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
  isForelopigRapport: boolean;
};

export const BruddPaRegelverket: React.FC<IProps> = ({
  snapshot,
  isForelopigRapport,
}) => {
  const tilsynstemaOgHjemmelTekstCombined =
    tilsynstemaOgHjemmelCombiner(snapshot);
  const rapportHasIngenBrudd = !hasBrudd(
    snapshot.brudd?.kontrollsporsmals ?? [],
  );

  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <RedigerbarTekst
        navn={
          isForelopigRapport
            ? "ForelopigRapportBruddPaRegelverket"
            : "EndeligRapportBruddPaRegelverket"
        }
        snapshot={snapshot}
      >
        <p>
          Vi har kontrollert {tilsynstemaOgHjemmelTekstCombined}. Vi har{" "}
          {rapportHasIngenBrudd && "ikkje "}avdekka{" "}
          {isForelopigRapport && !rapportHasIngenBrudd && "mogleg "}brot på
          regelverket.
        </p>
      </RedigerbarTekst>
    );
  }
  return (
    <RedigerbarTekst
      navn={
        isForelopigRapport
          ? "ForelopigRapportBruddPaRegelverket"
          : "EndeligRapportBruddPaRegelverket"
      }
      snapshot={snapshot}
    >
      <p>
        Vi har kontrollert {tilsynstemaOgHjemmelTekstCombined}. Vi har{" "}
        {rapportHasIngenBrudd && "ikke "}avdekket{" "}
        {isForelopigRapport && !rapportHasIngenBrudd && "mulig "}brudd på
        regelverket.
      </p>
    </RedigerbarTekst>
  );
};
