import React from "react";
import { DATE_FORMAT, formatDate } from "SharedComponents/utils/date";
import {
  AccessToTilsynType,
  PubliseringMalType,
  PublisertTilsynResponse,
} from "ApiClients";
import { hasBrudd } from "SharedComponents/utils/bruddUtils";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Heading3 } from "SharedComponents/components/Headings/Headings";
import styled from "styled-components";
import { getCurrentUser } from "../../../../globalFunctions";
import { Bold } from "../components/Bold";
import { Box } from "../../../../components/Box";
import { ReadAccessObfuscatedNotLoggedInContent } from "./ReadAccessObfuscatedNotLoggedInContent";
import { ReadAccessUnobfuscatedContent } from "./ReadAccessUnobfuscatedContent";
import { ReadAccessObfuscatedLoggedInContent } from "./ReadAccessObfuscatedLoggedInContent";
import { WriteAccessContent } from "./WriteAccessContent";

type IProps = {
  tilsyn: PublisertTilsynResponse;
};

const Normal = styled.span`
  font-weight: normal;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const IconStyled = styled(Icon)`
  flex-shrink: 0;
`;

const getBoxColor = (publiseringFristUtloptUtenInnsending: boolean) => {
  return publiseringFristUtloptUtenInnsending ? "peach5" : "slate5";
};

const getHeading: (tilsyn: PublisertTilsynResponse) => string = (tilsyn) => {
  switch (tilsyn.publiseringMalType) {
    case PubliseringMalType.ForelopigTilsynsrapport:
      return "Uttalelse på foreløpig rapport";
    case PubliseringMalType.Tilsynsrapport:
      return "Innsending";
    default:
      return "";
  }
};

const getContent: (tilsyn: PublisertTilsynResponse) => JSX.Element = (
  tilsyn,
) => {
  switch (tilsyn.tilgangType) {
    case AccessToTilsynType.ReadAndWriteUnObfuscated:
      return <WriteAccessContent tilsyn={tilsyn} />;
    case AccessToTilsynType.ReadUnObfuscated:
      return <ReadAccessUnobfuscatedContent tilsyn={tilsyn} />;
    default:
      return getCurrentUser() === null ? (
        <ReadAccessObfuscatedNotLoggedInContent tilsyn={tilsyn} />
      ) : (
        <ReadAccessObfuscatedLoggedInContent />
      );
  }
};

const isTilsynsrapportUtenBruddOgReaksjon: (
  tilsyn: PublisertTilsynResponse,
) => boolean = (tilsyn) => {
  const rapportHasNoBrudd = !hasBrudd(
    tilsyn.snapshot.brudd?.kontrollsporsmals ?? [],
  );
  return (
    tilsyn.publiseringMalType === PubliseringMalType.Tilsynsrapport &&
    (rapportHasNoBrudd ||
      (!tilsyn.snapshot?.reaksjoner?.paleggOmRetting?.isValgt &&
        !tilsyn.snapshot?.reaksjoner?.kravOmTilbakebetaling?.isValgt))
  );
};

const isForelopigTilsynsrapportMedUtloptFristUtenInnsending: (
  tilsyn: PublisertTilsynResponse,
) => boolean = (tilsyn) => {
  return (
    tilsyn.publiseringMalType === PubliseringMalType.ForelopigTilsynsrapport &&
    tilsyn.publiseringFristUtloptUtenInnsending
  );
};

export const LenkeTilSendInnInnsending: React.FC<IProps> = ({ tilsyn }) => {
  if (
    isTilsynsrapportUtenBruddOgReaksjon(tilsyn) ||
    isForelopigTilsynsrapportMedUtloptFristUtenInnsending(tilsyn)
  ) {
    return null;
  }

  return (
    <Box
      $background={getBoxColor(tilsyn.publiseringFristUtloptUtenInnsending)}
      className="u--marginBottom2"
    >
      <Heading3>
        {getHeading(tilsyn)}
        <Normal>
          {tilsyn.innsendingFristDatoUtc &&
            ` - Frist: ${formatDate(tilsyn.innsendingFristDatoUtc)}`}
        </Normal>
      </Heading3>
      {tilsyn.publiseringFristUtloptUtenInnsending ? (
        <Container>
          <IconStyled icon="circle cross" />
          <Bold>
            Frist utløp{" "}
            {formatDate(tilsyn.innsendingFristDatoUtc!, DATE_FORMAT)}, og det er
            ikke lenger mulig å sende inn dokumentasjon.
          </Bold>
        </Container>
      ) : (
        getContent(tilsyn)
      )}
    </Box>
  );
};
