import React from "react";
import { Heading3 } from "../../../../components/Headings/Headings";
import { getText } from "../../../Overskrifts/Overskrifts";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { RedigerbarTekst } from "../../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
};

export const DereHarRettTilAKlage: React.FC<IProps> = ({ snapshot }) => {
  const heading = getText(snapshot.omTilsynet?.malform, (t) => t.klageAdgang);
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <>
        <Heading3>{heading}</Heading3>
        <RedigerbarTekst
          navn="HvaSkjerNaDereHarRettTilAKlage"
          snapshot={snapshot}
        >
          <p>
            Tilsynsrapporten er eit enkeltvedtak etter forvaltningslova § 2
            første ledd bokstav b. De kan klage på enkeltvedtaket.
          </p>

          <p>
            Dersom de klagar, må de gjere det innan tre veker. Fristen gjeld frå
            beskjed om vedtaket har komme fram til dykk, jf. forvaltningslova §§
            28 og 29. De sender klagen til oss. Vi har høve til å gjere om
            vedtaket. Dersom vi ikkje er einige med dykk, sender vi klagen til
            Kunnskapsdepartementet som avgjer saka.
          </p>

          <p>
            I forvaltningslova § 32 kan de sjå korleis de skal utforme klagen.
          </p>

          <p>
            De kan be om at vi ikkje set i verk vedtaket før klagefristen er
            ute, eller klagen er endeleg avgjord av departementet, jf.
            forvaltningslova § 42.
          </p>
          <p>
            De er part i saka og har rett til innsyn i saksdokumenta, jf.
            forvaltningslova § 18.
          </p>
        </RedigerbarTekst>
      </>
    );
  }

  return (
    <>
      <Heading3>{heading}</Heading3>
      <RedigerbarTekst
        navn="HvaSkjerNaDereHarRettTilAKlage"
        snapshot={snapshot}
      >
        <p>
          Tilsynsrapporten er et enkeltvedtak etter forvaltningsloven § 2 første
          ledd bokstav b. Dere kan klage på enkeltvedtaket.
        </p>

        <p>
          Hvis dere klager, må dere gjøre det innen tre uker. Fristen gjelder
          fra melding om vedtaket har kommet frem til dere, jf.
          forvaltningsloven §§ 28 og 29. Dere sender klagen til oss. Vi har
          muligheten til å omgjøre vedtaket. Hvis vi ikke er enig med dere,
          sender vi klagen til Kunnskapsdepartementet som avgjør saken.
        </p>

        <p>
          I forvaltningsloven § 32 kan dere se hvordan dere skal utforme klagen.
        </p>

        <p>
          Dere kan be om at vi ikke setter i verk vedtaket før klagefristen er
          ute, eller klagen er endelig avgjort av departementet, jf.
          forvaltningsloven § 42.
        </p>
        <p>
          Dere er part i saken og har rett til innsyn i sakens dokumenter, jf.
          forvaltningsloven § 18.
        </p>
      </RedigerbarTekst>
    </>
  );
};
