// eslint-disable-next-line import/no-cycle
import {
  AntiForgeryTokenInfo,
  UserViewModel,
  PreloadedConfigModel,
  PreloadedViewModel,
  Urls,
} from "./ApiClients";

declare global {
  interface Window {
    initialState: PreloadedViewModel;
  }
}

export const getAntiForgeryTokenInfo = (): AntiForgeryTokenInfo =>
  window.initialState.antiForgeryTokenInfo;
export const getCurrentUser = (): UserViewModel | undefined =>
  window.initialState.currentUser;
export const getConfig = (): PreloadedConfigModel => window.initialState.config;
export const getUrls = (): Urls => window.initialState.urls;
